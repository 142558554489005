import firebase from "@/firebase/config"
// import MtpEntranceLanding from "../views/MtpEntranceLanding.vue"
// import MtpEntranceSubjectsStudy from "../views/MtpEntranceSubjectsStudy.vue"
// import MtpEntranceSubtopicsStudy from "../views/MtpEntranceSubtopicsStudy.vue"
// import MtpEntranceStudy from "../views/MtpEntranceStudy.vue"
import MtpEntranceSubjects from "../views/MtpEntranceSubjects.vue"
import MtpEntranceSubtopics from "../views/MtpEntranceSubtopics.vue"
import MtpEntrancePractice from "../views/MtpEntrancePractice.vue"
import MtpEntranceExam from "../views/MtpEntranceExam.vue"
import MtpEntranceTips from "../views/MtpEntranceTips.vue"
import MtpEntranceMandexStudy from "../views/MtpEntranceMandexStudy.vue"
// import MtpEntranceSurvey from "../views/MtpEntranceSurvey.vue"

import { mtpExpirationGuard } from "@/utils/helper"  /// create this!
import store from '@/store';

async function authGuard(to, from, next) {

    /**
     * Is the user authenticated?
     */
    const isAuthenticated = firebase.auth().currentUser;
    
    if (!isAuthenticated) {
        next({ path: '/login', query: { unauthorized: 'true' } })
    } else {


        console.warn(`A flagged user is accessing the MTP 2 (new mtp) reviewer.`);
        
        let authUserClaims = await fetchUserClaims();

        // check if user purchased the mtp
        if (authUserClaims.mtp) {

            // check for expiration / subscription
            let nextRoutePath = await mtpExpirationGuard(authUserClaims, store.getters.user.info)
            console.log('Going to:',nextRoutePath) // if nextRoutePath is null then just proceed to the current path route.
            next(nextRoutePath);

        }else{

            console.error(`You don't have access to MTP Entrance, rerouting to /reviewers`)
            next({ path: '/reviewers' })
        }

    }


}

async function fetchUserClaims() {
    return new Promise(async (resolve, reject) => {
        firebase.auth().currentUser.getIdTokenResult(true)
            .then(result => {
                resolve(result.claims)
            })
            .catch(error => {
                console.error("Error getting user claims", error);
                reject(error)
            })
    })
}


export const mtpEntranceRoutes = [
    {
        path: '/mtp-entrance',
        redirect: '/mtp-entrance/subjects',
    },
    {
        path: '/mtp-entrance/subjects',
        name: 'MTP Entrance Subjects',
        component: MtpEntranceSubjects,
        beforeEnter: authGuard
    },
    {
        path: '/mtp-entrance/subtopics',
        name: 'MTP Entrance Subtopics',
        component: MtpEntranceSubtopics,
        beforeEnter: authGuard
    },
    {
        path: '/mtp-entrance/practice',
        name: 'MTP Entrance Practice',
        component: MtpEntrancePractice,
        beforeEnter: authGuard
    },
    {
        path: '/mtp-entrance/exam',
        name: 'MTP Entrance Exam',
        component: MtpEntranceExam,
        beforeEnter: authGuard
    },
    {
        path: '/mtp-entrance/tips',
        name: 'MTP Entrance Tips',
        component: MtpEntranceTips,
        beforeEnter: authGuard
    },
    {
        path: '/mtp-entrance/mandex/study',
        name: 'MTP Entrance MANDEX Study',
        component: MtpEntranceMandexStudy,
        beforeEnter: authGuard
    },
]