<template>
  
  <!-- Announcement Banner -->
  <div class="pointer-events-auto overflow-hidden rounded-lg bg-gradient-to-r from-indigo-800 to-indigo-500 shadow-lg ring-1 ring-black ring-opacity-5 mb-4 w-full animate-glow">
    <div class="p-4">
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6 text-white">
            <path fill-rule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-2 w-0 flex-1 pt-0.5 items-center lg:flex lg:space-x-1">
          <p class="text-sm font-bold text-white">ADVISORY:</p>
          <p class="text-sm text-indigo-100">CAAP Knowledge Reviewer Major Updates (Dec 2024).</p>
          <a href="#" @click="openAdvisoryModal" class="hover:opacity-75 text-sm font-semibold text-white">Learn More →</a>
        </div>
      </div>
    </div>
  </div>


  <div class="subjects">

    <!-- Breadcrumbs -->
    <nav class="flex px-2 lg:px-12 justify-between items-center" aria-label="Breadcrumb">
      <div class="flex items-center">
        <router-link to="/caap" class="ml-2 text-sm font-medium text-warm-gray-500 hover:text-warm-gray-400" aria-current="page">
          <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
        </router-link>
      </div>
      <div class="text-sm font-medium text-warm-gray-500">
        <span class="hidden md:block">Showing all Subjects under CAAP for {{$route.params.course.toUpperCase()}}</span>
        <span class="block md:hidden">{{$route.params.course.toUpperCase()}} Subjects</span>
      </div>
      <button @click="openAnnounceModal" class="flex justify-center items-center gap-1 bg-warm-gray-200 dark:bg-warm-gray-700 text-xs py-1 px-2 rounded border border-yellow-600 text-yellow-600">
        <LightBulbIcon class="w-4 h-4"/> <span class="">Tips</span>
      </button>
      <!-- FAQ Menu -->
      <!-- <Menu as="div" class="ml-3 relative inline-block text-left">
        <MenuButton class="p-1 rounded-full flex items-center text-warm-gray-500 hover:text-warm-gray-600 focus:outline-none focus:ring-2 focus:ring-yellow-600">
          <span class="sr-only">Open options</span>
          <QuestionMarkCircleIcon class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="z-50 origin-top-right absolute right-0 mt-3 w-72 -mr-2 rounded-md shadow-lg dark:bg-true-gray-700 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <button type="button" :class="[ active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                  <span>What am I seeing in this page?</span>
                </button>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <button :class="[active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                  <span>What is a Practice/Mock Exam?</span>
                </button>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <button :class="[active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                  <span>What are Achievements?</span>
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu> -->
    </nav>

    <!-- Main Content -->
    <main class="max-w-full mx-auto py-6 px-1 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8 space-y-4">

      <!-- Display waiting screen if app is loading -->
      <div v-if="isLoading" class="space-y-4">
        <div class="h-64 sm:h-50 md:h-44 relative rounded-lg overflow-hidden dark:bg-true-gray-800 bg-warm-gray-200 animate-pulse shadow-sm"></div>
        <div class="h-64 sm:h-50 md:h-44 relative rounded-lg overflow-hidden dark:bg-true-gray-800 bg-warm-gray-200 animate-pulse shadow-sm"></div>
        <div class="h-64 sm:h-50 md:h-44 relative rounded-lg overflow-hidden dark:bg-true-gray-800 bg-warm-gray-200 animate-pulse shadow-sm"></div>
        <div class="h-64 sm:h-50 md:h-44 relative rounded-lg overflow-hidden dark:bg-true-gray-800 bg-warm-gray-200 animate-pulse shadow-sm"></div>
      </div>
      <div v-else v-for="subject in subjects" :key="subject.id" class="relative rounded-lg overflow-hidden border border-warm-gray-300 dark:border-true-gray-700 dark:bg-true-gray-800 bg-white shadow-sm hover:border-warm-gray-400 dark:hover:border-true-gray-500 dark:focus-within:ring-true-gray-800 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-yellow-500">
        <div class="grid grid-cols-1 gap-4 md:grid-cols-4 p-6">
          <div class="flex-1 min-w-0 col-span-1 md:col-span-3">
            <!-- Subject Data -->
            <div class="focus:outline-none text-left">
              <div class="flex dark:text-warm-gray-400 text-warm-gray-800">
                <component :is="subject.icon" class="w-6 h-6" />
                <h1 class="ml-2 text-lg font-medium dark:text-warm-gray-400 text-warm-gray-800">
                    {{subject.name}}
                </h1>
              </div>
              <p class="text-sm text-warm-gray-500 mt-2 max-w-sm">
                {{subject.description}}
              </p>
            </div>
          </div>
          <div class="col-span-1">
            <div class="flex flex-inline flex-row-reverse md:flex-row justify-between md:justify-start md:float-right md:space-x-4">
              <div class="flex flex-inline space-x-2">
                <!-- Milestones -->
                <div v-if="this.checkMilestone(subject.id, 'passer')" class="bg-blue-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                  <PaperClipIcon class="text-blue-500 w-6 h-6" />
                </div>
                <div v-else class="dark:bg-true-gray-700 bg-warm-gray-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                  <PaperClipIcon class="dark:text-true-gray-600 text-warm-gray-300 w-6 h-6" />
                </div>
                <div v-if="this.checkMilestone(subject.id, 'speedrunner')" class="bg-purple-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                  <LightningBoltIcon class="text-purple-500 w-6 h-6" />
                </div>
                <div v-else class="dark:bg-true-gray-700 bg-warm-gray-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                  <LightningBoltIcon class="dark:text-true-gray-600 text-warm-gray-300 w-6 h-6" />
                </div>
                <div v-if="this.checkMilestone(subject.id, 'master')" class="bg-yellow-300 flex-grow-0 rounded-full w-12 h-12 p-3">
                  <FireIcon class="text-yellow-600 w-6 h-6" />
                </div>
                <div v-else class="dark:bg-true-gray-700 bg-warm-gray-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                  <FireIcon class="dark:text-true-gray-600 text-warm-gray-300 w-6 h-6" />
                </div>
              </div>
              <!-- Exam Data -->
              <div class="focus:outline-none text-left">
                <div class="flex text-md items-center">
                  <ClockIcon class="w-5 h-5 dark:text-warm-gray-400 text-warm-gray-800" />
                  <p class="ml-2 font-medium dark:text-warm-gray-400 text-warm-gray-800">{{ this.getTime(subject.id) }}</p>
                </div>
                <div class="flex dark:text-warm-gray-400 text-warm-gray-800 text-md items-center ">
                  <ChartSquareBarIcon class="w-5 h-5 dark:text-warm-gray-400 text-warm-gray-800" />
                  <p class="ml-2 font-medium dark:text-warm-gray-400 text-warm-gray-800">{{ this.getScore(subject.id) }}%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-if="subject.id == 'human-performance'" class="px-6 pb-6">
          <div class="rounded-md bg-yellow-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <ExclamationIcon class="h-5 w-5 text-yellow-500" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-bold text-yellow-800">ATTENTION: </h3>
                <div class="mt-2 text-sm text-yellow-700">
                  <p>This subject is currently under ongoing update. We received reports of new topics being added to the actual CAAP Exam, and our team is working nonstop to update this part of the reviewer ASAP. We highly recommend our users to take other subjects while we deal with this issue. Thank you for your understanding.</p>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- Action Buttons -->
        <div class="dark:bg-true-gray-700 dark:bg-opacity-25 bg-gray-50 flex-1 min-w-0 px-6 py-3">
          <div class="text-sm flex justify-between">
            <router-link :to="`/caap/course/${$route.params.course}/${subject.id}`" class="font-medium dark:text-yellow-400 dark:hover:text-yellow-300 text-yellow-600 hover:text-yellow-700">
              Begin Practice
            </router-link>
            <button @click="updateSelected(subject.id)" class="font-medium dark:text-indigo-400 dark:hover:text-indigo-300 text-indigo-700 hover:text-indigo-900">
              Start Mock Exam
            </button>
          </div>
        </div>
      </div>
    </main>

    <!--  Popup: Subject -->
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
            </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div class="inline-block w-full max-w-3xl p-8 my-8 overflow-hidden text-left align-middle transition-all transform dark:bg-true-gray-800 bg-white shadow-xl rounded-2xl">
              <div class="flex text-warm-gray-800 dark:text-true-gray-200 justify-center">
                <component :is="(this.selected) ? this.subjects.find(e => e.id === this.selected).icon : null" class="w-6 h-6" />
                <DialogTitle as="h3" class="ml-1 text-lg font-medium text-warm-gray-800 dark:text-true-gray-200">
                  {{(this.selected) ? this.subjects.find(e => e.id === this.selected).name : null}}
                </DialogTitle>
                </div>
                <div class="flex dark:text-true-gray-400 text-warm-gray-800 justify-center">
                  <span class="rounded-full bg-purple-500 text-purple-50 text-xs ml-1 mt-1 font-normal py-0.5 px-1.5">
                    {{ this.subjects.find(e => e.id === this.selected).exam.items.reduce( (a, b) => a + b, 0)}} Items
                  </span>
                </div>
                <button type="button" @click="closeModal" class="absolute top-0 right-0 mr-4 mt-4">
                  <svg class="w-6 h-6 dark:text-true-gray-400 text-warm-gray-400 hover:text-warm-gray-500 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                </button>
                <div class="mt-2">

                  <!-- More Info -->
                  <p class="text-sm font-bold dark:text-true-gray-400 text-warm-gray-700 text-left mt-6">
                    Before you begin, please take note:
                  </p>
                  <p class="text-sm dark:text-true-gray-400 text-warm-gray-700 text-left mt-4">
                    <ol class="list-decimal ml-4 space-y-2 mt-2">
                      <li class="pl-1.5">Passing score is {{this.subjects.find(e => e.id === this.selected).exam.limits.passer}}% and the time limit is {{this.subjects.find(e => e.id === this.selected).exam.limits.time/60}} minutes.</li>
                      <li class="pl-1.5">This Mock Exam will end once time limit has been reached.</li>
                      <li class="pl-1.5">You have 4 skips. Skipped items will be looped at the last part.</li>
                      <li class="pl-1.5">Click ‘Next’ to confirm your answer.</li>
                      <li class="pl-1.5">Click ‘Quit’ icon to cancel the exam. All results will be voided.</li>
                    </ol>
                  </p>
                  <p class="text-sm dark:text-true-gray-400 text-warm-gray-700 text-left mt-8">
                    Reach the goals to unlock these milestones:
                  </p>
                    
                  <!-- Milestone Tip Section -->
                  <div class="rounded-md dark:bg-true-gray-700 dark:bg-opacity-25 bg-warm-gray-100 overflow-hidden text-sm  text-left mt-2 w-full">
                    <div class="px-4 py-5 sm:p-5 sm:space-x-4 grid grid-cols-1 sm:grid-cols-3 text-center items-start">
                      <div class="mt-4">
                        <div class="mx-auto bg-blue-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                          <PaperClipIcon class="text-blue-500 w-6 h-6" />
                        </div>
                        <h3 class="dark:text-true-gray-300 text-warm-gray-800 text-base font-bold my-1">
                          Passer
                        </h3>
                        <div class="mt-2 w-40 mx-auto bg-yellow-300 text-yellow-900 font-bold rounded-full text-xs p-1">
                          +{{  (this.subjects.find(e => e.id === this.selected).exam.points.passer) ? this.subjects.find(e => e.id === this.selected).exam.points.passer : '' }} Skillpoints
                        </div>
                        <p class="mt-2 mb-4 dark:text-true-gray-400 text-warm-gray-600 text-sm">
                          Pass the exam within the time limit.
                        </p>
                      </div>
                      <div class="mt-4">
                        <div class="mx-auto bg-purple-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                          <LightningBoltIcon class="text-purple-500 w-6 h-6" />
                        </div>
                        <h3 class="dark:text-true-gray-300 text-warm-gray-800 text-base font-bold my-1">
                          Speedrunner
                        </h3>
                        <div class="mt-2 w-40 mx-auto bg-yellow-400 text-yellow-900 font-bold rounded-full text-xs p-1">
                          +{{  (this.subjects.find(e => e.id === this.selected).exam.points.speedrunner) ? this.subjects.find(e => e.id === this.selected).exam.points.speedrunner : '' }} Skillpoints
                        </div>
                        <p class="mt-2 mb-4 dark:text-true-gray-400 text-warm-gray-600 text-sm">
                          Pass the exam before it reaches {{(Math.floor(this.subjects.find(e => e.id === this.selected).exam.limits.speedrunner/60 * 100) / 100) }} minutes.
                        </p>
                      </div>
                      <div class="mt-4">
                          <div class="mx-auto bg-yellow-300 flex-grow-0 rounded-full w-12 h-12 p-3">
                            <FireIcon class="text-yellow-500 w-6 h-6" />
                          </div>
                          <h3 class="dark:text-true-gray-300 text-warm-gray-800 text-base font-bold my-1">
                            Master
                          </h3>
                          <div class="mt-2 w-40 mx-auto bg-yellow-500 text-yellow-900 font-bold rounded-full text-xs p-1">
                            +{{  (this.subjects.find(e => e.id === this.selected).exam.points.master) ? this.subjects.find(e => e.id === this.selected).exam.points.master : '' }} Skillpoints
                          </div>
                          <p class="mt-2 mb-4 dark:text-true-gray-400 text-warm-gray-600 text-sm">
                            Score {{this.subjects.find(e => e.id === this.selected).exam.limits.master}}% or more before it reaches {{(Math.floor(this.subjects.find(e => e.id === this.selected).exam.limits.speedrunner/60 * 100) / 100) }} minutes.
                          </p>
                      </div>
                    </div>
                  </div>
                    
                  <!-- Action Buttons -->
                  <div class="mt-8 sm:mt-6 w-full">
                    <button @click="this.$router.push({ path: `/caap/course/${$route.params.course}/${this.selected}/mock` })" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                      Begin Mock Exam
                    </button>
                  </div>
                </div>
              </div>
          </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>


    <!-- Announcement Modal -->
    <TransitionRoot appear :show="showAnnouncement" as="template">
      <Dialog as="div" @close="closeAnnounceModal">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
            </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div class="inline-block w-full max-w-3xl p-8 my-8 overflow-hidden text-left align-middle transition-all transform dark:bg-true-gray-800 bg-white shadow-xl rounded-2xl">
              <div class="flex text-warm-gray-800 dark:text-true-gray-200 justify-center">
                
                <DialogTitle as="h3" class="ml-1 text-lg font-medium text-warm-gray-800 dark:text-true-gray-200">
                  Important things to remember when using our CAAP Reviewer:
                </DialogTitle>
                </div>
                
                <button type="button" @click="closeAnnounceModal" class="absolute top-0 right-0 mr-4 mt-4">
                  <svg class="w-6 h-6 dark:text-true-gray-400 text-warm-gray-400 hover:text-warm-gray-500 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                </button>
                <div class="mt-2">

                  <h2 class="text-sm font-bold dark:text-true-gray-300 text-warm-gray-800 text-left mt-6">A. Subjects to Study/Exam First</h2>
                  <p class="text-sm dark:text-true-gray-400 text-warm-gray-700 text-left mt-6">
                    Based on the latest data that we’ve collected from users who had outstanding success rates from their recent exams, here’s a sequence of subjects that you can follow in your review.
                  </p>
                  <p class="text-sm dark:text-true-gray-400 text-warm-gray-700 text-left mt-6">
                    <span>For AMT:</span>
                    <ul class="list-disc pl-5 mt-2">
                      <li>Human Performance</li>
                      <li>Nat/Sci</li>
                      <li>Air Law</li>
                      <li>Powerplant Rating</li>
                      <li>Airframe Rating</li>
                      <li>Aircraft Maintenance</li>
                      <li>Aircraft Engineering</li>
                    </ul>
                  </p>
                  <p class="text-sm dark:text-true-gray-400 text-warm-gray-700 text-left mt-6">
                    <span>For AVT:</span>
                    <ul class="list-disc pl-5 mt-2">
                      <li>Human Performance</li>
                      <li>Nat/Sci</li>
                      <li>Air Law</li>
                      <li>Aircraft Maintenance</li>
                      <li>Aircraft Engineering</li>
                      <li>Avionics Rating</li>
                    </ul>
                  </p>

                  <p class="text-sm dark:text-true-gray-400 text-warm-gray-700 text-left mt-6">
                    Please take note that these are only suggestions to help you maximize our reviewers. You can always choose other sequences that will serve you best.
                  </p>
                  
                  <h2 class="text-sm font-bold dark:text-true-gray-300 text-warm-gray-800 text-left mt-6">B. Studying the Subjects: Air Law and Human Performance</h2>

                  <p class="text-sm dark:text-true-gray-400 text-warm-gray-700 text-left mt-6">
                    These subjects are considered as the most difficult subjects by many exam takers because of their numerous question sets compared to other subjects. That’s why it’s important to remember the following when studying for your upcoming Air Law and Human Performance exam.
                  </p>

                  <p class="text-sm dark:text-true-gray-400 text-warm-gray-700 text-left mt-4">
                    <ol class="pl-5 mt-2 list-decimal space-y-4">
                      <li>
                        <strong>Watch out for paraphrased questions.</strong> Some questions in these exams will not look the same as the one in our reviewer. Sometimes, they are shortened, and are composed of different words to describe just the same thought.
                      </li>
                      <li>
                        <strong>Questions and choices swaps.</strong> There are times that these exams use the contents of the question as choices, and choices in the form of a question. Meaning, you may find a question in our reviewer that is being reversed and instead used as one of the choices in the actual exam.
                      </li>
                      <li>
                        <strong>Understand the content.</strong> To avoid getting confused with all these paraphrasing and swapping, make sure you understand the contents of the item rather than just memorizing it.
                      </li>
                    </ol>
                  </p>
                </div>
              </div>
          </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Advisory Modal -->
    <TransitionRoot appear :show="showAdvisory" as="template">
      <Dialog as="div" @close="closeAdvisoryModal">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
            </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div class="inline-block w-full max-w-3xl p-8 my-8 overflow-hidden text-left align-middle transition-all transform dark:bg-true-gray-800 bg-white shadow-xl rounded-2xl">
              <div class="flex text-warm-gray-800 dark:text-true-gray-200 justify-center">
                
                <DialogTitle as="h3" class="ml-1 text-xl font-bold text-warm-gray-800 dark:text-true-gray-200">
                  CAAP Knowledge Reviewer Major Updates (Dec 2024) 
                </DialogTitle>
                </div>
                
                <button type="button" @click="closeAdvisoryModal" class="absolute top-0 right-0 mr-4 mt-4">
                  <svg class="w-6 h-6 dark:text-true-gray-400 text-warm-gray-400 hover:text-warm-gray-500 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                </button>
                <div class="mt-8 text-warm-gray-500 dark:text-true-gray-400 leading-relaxed">

                    <p class=" font-semibold text-true-gray-600 dark:text-true-gray-300">
                      Hi there!
                    </p>
                    <br>
                    <p class="mt-2 ">
                      If you're reading our advisories for the first time, we want you to know that we continually update our reviewers to keep them fresh and relevant for everyone. However, for minor updates like adding or removing a few review items, we choose not to send announcements to avoid overloading you with messages.
                    </p>
                    <br>
                    <p class="mt-2 ">
                      Rest assured, for significant updates, we'll always keep you informed. On that note, our research team has gathered new data that may impact the CAAP Knowledge exams. In response, we are making the following major changes:
                    </p>
                    <br>
                    <h3 class="mt-4 text-lg font-semibold text-true-gray-600 dark:text-true-gray-300">CAAP Knowledge Reviewer (Major Update #15)</h3>
                    <br>
                    <ol class="mt-2 list-decimal list-inside ">
                      <li>
                        <strong class="text-true-gray-600 dark:text-true-gray-300">Subtopic: [Docs & Forms] and [Engine Lubrication]</strong>
                        <ul class="mt-1 list-disc ml-5 pl-5">
                          <li>
                            Removed from subject Aircraft Maintenance to improve overall reviewer accuracy.
                          </li>
                        </ul>
                      </li>
                      <li class="mt-3">
                        <strong class="text-true-gray-600 dark:text-true-gray-300">Subtopic: [Landing Gears]</strong>
                        <ul class="mt-1 list-disc ml-5 pl-5">
                          <li>
                            Added to subject Aircraft Maintenance to improve overall reviewer accuracy.
                          </li>
                        </ul>
                      </li>
                    </ol>
                    <br>
                    <p class="mt-4 ">
                      Kindly take note of these updates for your reference. Don't worry, we will always monitor sudden changes in the coverage of your exams to make sure you can have the latest and most updated reviewer at all times.
                    </p>
                    
                    <p class="mt-8 ">
                      Thank you,
                    </p>
                    <p class=" font-bold text-true-gray-600 dark:text-true-gray-300 mt-4">Skilltech Research Team</p>

                 
                </div>
              </div>
          </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

  </div>
</template>

<script>
// Import firebase dependencies
import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";
const db = firebase.firestore();

// Import UI dependencies
import { ref } from "vue";
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle, Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { PaperClipIcon, LightningBoltIcon, FireIcon, ClockIcon, ChartSquareBarIcon, ExclamationIcon } from "@heroicons/vue/outline";
import { ChevronDoubleLeftIcon, QuestionMarkCircleIcon, LightBulbIcon } from "@heroicons/vue/solid";

// Import other dependencies
import moment from "moment";

import { userService } from "@/utils/user"
import { reviewerService } from "@/utils/reviewer"
import { expirationGuard } from "@/utils/helper"

export default {
  components: {
    ChevronDoubleLeftIcon, QuestionMarkCircleIcon, PaperClipIcon, LightningBoltIcon, FireIcon, ClockIcon, ChartSquareBarIcon, ExclamationIcon,
    TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle,
    Menu, MenuButton, MenuItem, MenuItems, LightBulbIcon
  },
  setup() {
    const isOpen = ref(false);
    const showAnnouncement = ref(false);
    const showAdvisory = ref(false);
    return {
      isOpen,
      closeModal() { isOpen.value = false; },
      openModal() { isOpen.value = true; },
      showAnnouncement,
      showAdvisory,
      closeAnnounceModal() { showAnnouncement.value = false; },
      openAnnounceModal() { showAnnouncement.value = true; },
      closeAdvisoryModal() { showAdvisory.value = false; },
      openAdvisoryModal() { showAdvisory.value = true; },
    };
  },
  data() {
    return {
      isLoading: true,
      subjects: null,
      user: null,
      moment: moment,
      milestones: [],
    };
  },

  methods: {

    /**
     * Function to update the selected subject to be displayed in the popup.
     */
    updateSelected(subject_id) {
      this.selected = subject_id;
      this.openModal();
    },

    /**
     * Check if a user milestone exists for a certain subject
     */
    checkMilestone (subject_id, milestone) {
      if (this.user) {
        let subject = this.user.subjects.filter((e) => e.id == subject_id)[0];
        return subject && subject.milestones.includes(milestone) ? true : false;
      }
    },

    /**
     * Get the user's best time for a certain subject
     */
    getTime (subject_id) {
      if (this.user) {
        let subject = this.user.subjects.filter((e) => e.id == subject_id)[0];
        return subject ? this.formatTime(subject.time) : this.formatTime(0);
      }
    },

    /**
     * Get the user's best score for a certain subject
     */
    getScore (subject_id) {
      if (this.user) {
        let subject = this.user.subjects.filter((e) => e.id == subject_id)[0];
        return subject ? subject.score : 0;
      }
    },

    /**
     * Format's a timestamp for UI display
     * @NOTE: Utility function.
     * @TODO: Convert into reusable library
     */
    formatTime(time) {
      let momentedTime = this.moment("2015-01-01").startOf("day").seconds(time);
      return this.moment(momentedTime).format("HH:mm:ss");
    },

  },
  
  async created(){
    this.isLoading = true

    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });

    /* CLAIMS - Guard Clause */
    let claims = await userService.fetchUserClaims(firebase.auth())
    if(!claims.isOnboarded) this.$router.push({ path: '/onboarding' })  // reroute non-onboarded users
    if(!claims.caap) this.$router.push({ path: '/reviewers' })  // reroute non-caap members
   
    var reviewer_id = this.$route.path.split('/').filter(String)[0] // caap
    var course_id = this.$route.params.course // or filter

    /* USER */
    this.user = await userService.fetchUserData(firebase.firestore(), firebase.auth().currentUser.uid);
    if(!this.user.license || !this.user.license.exam_date) this.$router.push({ path: '/onboarding-caap' });
    expirationGuard(this.user, this.$router); // Check if access already expired. Added November 17, 2023

    /* Update Subjects inside the reviewer based on its filter/course */
    this.subjects = await reviewerService.fetchSubjectsByCourse(db, reviewer_id, course_id)
    
    // console.log("Adding overflow-hidden")
    document.body.classList.add("overflow-hidden");

    this.isLoading = false
  }
};
</script>

<style scoped>
@keyframes glow {
  0%, 100% {
    box-shadow: 0 0 2px rgba(99, 102, 241, 0.8);
  }
  50% {
    box-shadow: 0 0 30px rgba(99, 102, 241, 1);
  }
}
.animate-glow {
  animation: glow 2s ease-in-out infinite;
}
</style>
