<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            If you're preparing for an aviation-related exam, it's essential to have a solid grasp of different topics
            like Structural Materials & Assemblies. Here are some quick key tips to help you master these topics
            effectively.
        </p>

        <h3>💡 Structural Materials & Assemblies: Building Blocks of Aircraft Strength</h3>
        <section>
            <p>
                This topic focuses on materials and techniques used in building and maintaining aircraft structures.
                From aluminum alloys to composite materials, it's crucial to understand the properties and purposes of
                each.
            </p>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Visualize the Components:</strong> Familiarize yourself with materials like aluminum alloys,
                    titanium, and composites. Study why each is chosen for specific parts of the aircraft. For example,
                    composite materials are favored for their lightweight and high-strength properties.
                </li>
                <li>
                    <strong>Learn the Fastening Techniques:</strong> Be clear on why solid shank rivets are the most
                    common in aircraft assembly or how clecos temporarily hold components together. Remember, the little
                    details matter when it comes to aircraft safety and integrity.
                </li>
                <li>
                    <strong>Practice with Sample Inspections:</strong> Structural inspections such as dye penetrant
                    testing and ultrasonic testing are crucial to detecting defects. Go through case studies or videos
                    showing these tests in action to understand how inspectors identify potential risks.
                </li>
            </ul>
        </section>

        <h3>💡 Commonly Used Materials in Aircraft Assemblies</h3>
        <section>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Aluminum Alloys:</strong> Lightweight, corrosion-resistant, widely used for fuselage and
                    wing structures.
                </li>
                <li>
                    <strong>Titanium:</strong> High strength-to-weight ratio and excellent heat resistance, ideal for
                    engine components.
                </li>
                <li>
                    <strong>Composite Materials:</strong> Lightweight and strong, used in wings, fuselage panels, and
                    control surfaces.
                </li>
                <li>
                    <strong>High-Strength Steel:</strong> Used in high-stress areas such as landing gear and fasteners.
                </li>
                <li>
                    <strong>Carbon Fiber:</strong> Lightweight and corrosion-resistant, used in various aircraft
                    structures.
                </li>
                <li>
                    <strong>Inconel:</strong> High-temperature-resistant alloy, commonly found in engine nacelles.
                </li>
            </ul>
        </section>

        <p class="mt-6">
            While it might seem overwhelming at first, a well-rounded preparation strategy is your key to success. Break
            each topic into digestible sections, focus on understanding rather than memorization, and supplement your
            learning with practice questions.
        </p>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ul {
    @apply space-y-1.5;
}

p {
    @apply text-sm;
}
</style>
