<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            When dealing with questions where relationships between figures must be identified and completed, here’s a
            step-by-step approach to simplify your process:
        </p>

        <h3>💡 1. Compare the First Pair of Figures</h3>
        <p class="p-4">
            <img src="../../assets/images/pattern-relationship/image-1.png" alt="" />
        </p>
        <section>
            <p>
                <strong>What to Do:</strong> Start by analyzing the relationship between the top two figures. Look for
                transformations such as:
            </p>
            <ul class="list-disc pl-4">
                <li>Rotation</li>
                <li>Reflection</li>
                <li>Addition or removal of elements</li>
                <li>Changes in position or color</li>
            </ul>
            <p>
                <strong>Why It Helps:</strong> The relationship between the first pair usually establishes the pattern
                to apply to the second pair.
            </p>
            <p>
                <strong>Tip:</strong> In the example provided, the elements remain the same in number but are rearranged.
                This hints at a positional shift, like rotation or reflection.
            </p>
        </section>

        <h3>💡 2. Apply the Pattern to the Second Pair</h3>
        <p class="p-4">
            <img src="../../assets/images/pattern-relationship/image-2.png" alt="" />
        </p>
        <section>
            <p>
                <strong>What to Do:</strong> Once you identify the transformation in the first pair, apply the same
                logic to the figure on the bottom left to predict the missing figure.
            </p>
            <p>
                <strong>How to Apply:</strong> If the transformation involves reflection, reflect the lower figure. If
                it was rotation, rotate it by the same degree or direction.
            </p>
            <p>
                <strong>Tip:</strong> In the example, the transformation involves a 90° rotation in a counterclockwise
                direction. This leads to the correct answer, which is choice (C).
            </p>
        </section>

        <h3>💡 3. Eliminate Incorrect Options</h3>
        <section>
            <p>
                <strong>What to Do:</strong> Examine each answer choice to see which matches the identified
                transformation. Rule out options that don’t preserve key attributes like:
            </p>
            <ul class="list-disc pl-4">
                <li>The number of elements.</li>
                <li>The relative positions of shapes.</li>
                <li>The color or shading of elements.</li>
            </ul>
            <p>
                <strong>Tip:</strong> In the example, choices that don’t maintain the exact count and arrangement of
                shaded/empty squares can be eliminated immediately.
            </p>
        </section>

        <p class="mt-6">
            By following this structured approach, you can confidently and efficiently solve abstract reasoning problems
            like these!
        </p>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ul {
    @apply space-y-1.5;
}

p {
    @apply text-sm;
}
img {
    @apply mx-auto my-2
}
</style>
