<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            Matrix reasoning questions require you to identify patterns or relationships between rows and columns of
            figures to find the missing piece. Follow these steps:
        </p>

        <h3>💡 1. Analyze the First Row</h3>
        <p class="p-4">
            <img src="../../assets/images/extended-series/image-1.png" alt="" />
        </p>
        <section>
            <p>
                <strong>What to Do:</strong> Start by looking for a consistent relationship between the figures in the
                first row. Examine features such as:
            </p>
            <ul class="list-disc pl-4">
                <li>Number of elements</li>
                <li>Color changes</li>
                <li>Position shifts</li>
            </ul>
            <p>
                <strong>Why It Helps:</strong> Patterns in the first row often establish the rule that applies
                throughout the matrix.
            </p>
            <p>
                <strong>Tip:</strong> In the first row, each figure adds one yellow square – first to the upper-left
                corner, then to the upper-right corner, without changing any other elements in the original figure
                (empty space).
            </p>
        </section>

        <h3>💡 2. Verify the Rule in the Second Row</h3>
        <p class="p-4">
            <img src="../../assets/images/extended-series/image-2.png" alt="" />
        </p>
        <section>
            <p>
                <strong>What to Do:</strong> Apply the pattern or relationship from the first row to the second row.
                Check if the same logic holds.
            </p>
            <p>
                <strong>Why It Helps:</strong> Verifying consistency ensures the identified pattern is correct and can
                be applied to find the missing figure.
            </p>
            <p>
                <strong>Tip:</strong> In the second row (just like in the first row), each figure adds one yellow square
                – first to the upper-left corner, then to the upper-right corner, without changing any other elements in
                the original figure (black square in the lower-right corner).
            </p>
        </section>

        <h3>💡 3. Apply the Pattern to the Missing Spot</h3>
        <p class="p-4">
            <img src="../../assets/images/extended-series/image-3.png" alt="" />
        </p>
        <section>
            <p>
                <strong>What to Do:</strong> Use the identified pattern to predict the missing figure.
            </p>
            <p>
                <strong>Guide:</strong> The missing figure should continue the established sequence logically.
            </p>
            <p>
                <strong>Tip:</strong> If you follow the rules from the previous rows, the missing figure in the third
                row must have two yellow squares at the top and must retain its original figure (two black squares at
                the bottom). This will lead us to the correct answer, which is choice (D).
            </p>
        </section>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ul {
    @apply space-y-1.5;
}

p {
    @apply text-sm;
}
img {
    @apply mx-auto my-2
}
</style>