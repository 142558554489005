<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            When tackling exams that focus on tools and their applications, here are some useful strategies to help you
            ace the test.
        </p>

        <h3>💡 1. Understand Tool Functions</h3>
        <section>
            <p>
                Each tool is designed for a specific purpose. Familiarize yourself with their primary functions, as many
                questions are framed around what each tool is best used for. For example:
            </p>
            <ul class="list-disc pl-4">
                <li><strong>Duck Bill Pliers:</strong> Used for twisting safety wires.</li>
                <li><strong>Diagonal Cutting Pliers:</strong> Excellent for cutting small fasteners like wires and
                    pins.
                </li>
            </ul>
        </section>

        <h3>💡 2. Recognize Unique Features</h3>
        <section>
            <p>
                Some questions emphasize the specific characteristics of a tool. For instance:
            </p>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Reed and Prince Screwdriver:</strong> Has 45-degree flukes with a sharper tip. By associating
                    unique traits with tool names, you can eliminate incorrect options quickly.
                </li>
            </ul>
        </section>

        <h3>💡 3. Avoid Common Pitfalls</h3>
        <section>
            <p>
                Certain tools might look similar but serve different purposes. Be cautious with questions like:
            </p>
            <p class="italic">
                "Which spanner should NOT be used for aircraft maintenance?"
            </p>
            <p>
                Focus on the application to avoid choosing an incorrect tool like the Adjustable Spanner.
            </p>
        </section>

        <h3>💡 4. Practice Logical Elimination</h3>
        <section>
            <p>
                Use the process of elimination if you’re unsure of the answer. Cross out choices that are clearly
                unrelated, leaving fewer options to consider. For example:
            </p>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Ball Peen Hammer:</strong> Unlikely to be used for shaping soft metals like aluminum; a
                    Mallet Hammer is a better choice.
                </li>
            </ul>
        </section>

        <h3>💡 5. Leverage Visual Memory</h3>
        <section>
            <p>
                Visualize tools and their applications to solidify your understanding. Recall how tools like Allen Keys
                or Crowfoot Sockets appear and are used in practical scenarios.
            </p>
        </section>

        <h3>💡 6. Use Context Clues</h3>
        <section>
            <p>
                If a question references "hard-to-reach fasteners" or "headless setscrews," think about tools designed
                for such purposes, like the Universal Joint or Allen Keys.
            </p>
        </section>

        <h3>💡 7. Practice Application-Based Scenarios</h3>
        <section>
            <p>
                Many questions simulate real-life tasks, such as using a Pin Punch to fully drive out a pin. Review
                examples of these tasks in maintenance manuals or videos to boost comprehension.
            </p>
        </section>

        <h3>💡 8. Avoid Overthinking</h3>
        <section>
            <p>
                Trust your preparation. Don’t overanalyze straightforward questions. Often, the simplest explanation
                aligns with the tool's function.
            </p>
        </section>

        <p class="mt-6">
            By applying these strategies, you’ll enhance your ability to confidently answer tool-related questions
            during exams!
        </p>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ul {
    @apply space-y-1.5;
}

p {
    @apply text-sm;
}

/* p.italic {
    @apply italic;
} */
</style>