<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            This section will cover effective, concise notes to help you quickly familiarize yourself with the
            Philippine Civil Aviation Regulations (PCAR) and Aircraft Maintenance Publications.
        </p>

        <h3>💡 PCAR (Philippine Civil Aviation Regulations)</h3>
        <section>
            <p>Here’s a quick breakdown of the PCAR parts you need to focus on:</p>
            <ul class="list-disc pl-4">
                <li><strong>PCAR Part 1:</strong> General Policies, Procedures, and Definitions</li>
                <li><strong>PCAR Part 2:</strong> Personnel Licensing</li>
                <li><strong>PCAR Part 3:</strong> Approved Training Organization</li>
                <li><strong>PCAR Part 4:</strong> Aircraft Registration and Markings</li>
                <li><strong>PCAR Part 5:</strong> Airworthiness</li>
                <li><strong>PCAR Part 6:</strong> Approved Maintenance Organization</li>
                <li><strong>PCAR Part 7:</strong> Instruments and Equipment</li>
                <li><strong>PCAR Part 8:</strong> Operations</li>
            </ul>
        </section>

        <h3>💡 Aircraft Maintenance Publications</h3>
        <section>
            <p>
                In aviation maintenance, there are various manuals that provide guidance on specific tasks and
                procedures. Below is an expanded list of essential manuals to focus on:
            </p>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Aircraft Maintenance Manual (AMM):</strong> Describes in detail how to perform maintenance
                    tasks on the aircraft, including routine servicing, component replacement, and troubleshooting.
                </li>
                <li>
                    <strong>Illustrated Parts Catalog (IPC):</strong> Contains drawings and part numbers for every
                    component on the aircraft, making it easier to identify and order replacement parts.
                </li>
                <li>
                    <strong>Structural Repair Manual (SRM):</strong> Provides instructions on how to assess, classify,
                    and repair damage to the aircraft’s structure.
                </li>
                <li>
                    <strong>Wiring Diagram Manual (WDM):</strong> Outlines the wiring connections, electrical paths, and
                    physical layout of the aircraft’s electrical systems.
                </li>
                <li>
                    <strong>System Schematic Manual (SSM):</strong> Contains simple diagrams that illustrate how systems
                    like hydraulics, fuel, or pneumatics function and interact with each other.
                </li>
                <li>
                    <strong>Component Maintenance Manual (CMM):</strong> Details how to repair, overhaul, and return
                    components to serviceable condition, focusing on off-aircraft maintenance tasks.
                </li>
                <li>
                    <strong>Fault Isolation Manual (FIM):</strong> A troubleshooting guide that helps mechanics
                    systematically identify and resolve system faults, usually through a process of elimination.
                </li>
                <li>
                    <strong>Minimum Equipment List (MEL):</strong> Identifies which equipment on an aircraft can be
                    inoperative without affecting safety or airworthiness.
                </li>
                <li>
                    <strong>Master Minimum Equipment List (MMEL):</strong> A manufacturer-prepared list of systems and
                    equipment that may be inoperative on an aircraft with minimal impact on its safe operation.
                </li>
                <li>
                    <strong>Configuration Deviation List (CDL):</strong> Lists external non-structural parts that may be
                    missing or inoperative, without compromising airworthiness.
                </li>
                <li>
                    <strong>Service Bulletin (SB):</strong> Issued by manufacturers to notify operators of
                    modifications, inspections, or repairs needed to improve aircraft safety or performance.
                </li>
                <li>
                    <strong>Airworthiness Directives (AD):</strong> Mandatory instructions issued by civil aviation
                    authorities, requiring specific actions to correct unsafe conditions.
                </li>
                <li>
                    <strong>Advisory Circular (AC):</strong> Provides non-mandatory guidance on acceptable ways to
                    comply with regulations.
                </li>
                <li>
                    <strong>Supplemental Type Certificate (STC):</strong> Certifies modifications or improvements to an
                    aircraft, engine, or component that meet regulatory standards.
                </li>
                <li>
                    <strong>Type Certificate Data Sheet (TCDS):</strong> Provides essential data about the design and
                    operational limits of a specific aircraft or engine model.
                </li>
                <li>
                    <strong>Task Card (TC):</strong> A document detailing step-by-step procedures for completing
                    specific maintenance tasks, customized for each aircraft model.
                </li>
                <li>
                    <strong>Maintenance Planning Document (MPD):</strong> Provides a schedule for routine inspections,
                    system checks, and component replacements based on flight hours, cycles, or calendar time.
                </li>
                <li>
                    <strong>Service Information Letter (SIL):</strong> Contains information on maintenance
                    improvements, part updates, or procedures that enhance a part’s longevity.
                </li>
                <li>
                    <strong>Service Instruction (SI):</strong> Describes modifications, processes, or other
                    maintenance-related tasks relevant to aircraft systems or components.
                </li>
                <li>
                    <strong>Engineering Order (EO):</strong> A document used to plan and manage maintenance tasks, such
                    as repairs or modifications, in accordance with technical publications.
                </li>
            </ul>
        </section>

        <p class="mt-6">
            These manuals are essential for maintaining aircraft airworthiness and ensuring safety in operations.
            Familiarizing yourself with these documents and their purpose is key for any aviation maintenance exam.
        </p>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ul {
    @apply space-y-1.5;
}

p {
    @apply text-sm;
}
</style>
