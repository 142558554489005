import moment from "moment";


function convertTimestampToDate(t) {

    if (!t) return 'NA'
    // Returned Format: 2023-11-29 08:53 PM
    // const timestamp = new Timestamp(t.seconds, t.nanoseconds)
    // const date = timestamp.toDate()

    // Convert Timestamp to Date
    const date = t.toDate();

    const year = date.getFullYear()
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const month = monthNames[date.getMonth()] // Use this for month names
    // const month = String(date.getMonth() + 1).padStart(2, '0'); // Use this if months will be 0-based formatted
    const day = String(date.getDate()).padStart(2, '0')
    const hours = date.getHours()
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')

    let formattedHours = hours % 12 || 12 // Convert to 12-hour format
    formattedHours = String(formattedHours).padStart(2, '0') // Add leading zero if needed
    const ampm = hours >= 12 ? 'PM' : 'AM'

    return `${year}-${month}-${day} ${formattedHours}:${minutes}:${seconds} ${ampm}`
}

function checkIfExpired(expiryDate) {
    const currentDate = new Date(); // <---- this need to be changed, user can manipulate this!!!
    // const currentDate = new Date('2024-06-30T14:00:38.984Z');
    console.log('current date:', currentDate); 
    const expirationDate = new Date(expiryDate);
    console.log(currentDate > expirationDate);
    return currentDate > expirationDate; // Compare Date objects directly
}


function expirationGuard(user, router){

    // Commenting out the code block below to address a bug in Firefox where it redirects to the dashboard even if the user subscription has not expired yet.
    // Additionally, this check is redundant as expiration is already being handled elsewhere.

    // console.log(`%c🎫 ----------- expirationGuard 🏁 -----------`, 'background: #222; color: #bada55');
    // console.log("Checking if the user has an expiration date...")
    
    // if(user.caap_data && typeof user.caap_data.expiration_date != 'undefined'){    
    //     let expirationTs = user.caap_data.expiration_date
    //     console.log("User has an expiration date! Reviewer will expire on:")
    //     console.log(convertTimestampToDate(expirationTs))
    //     if(checkIfExpired(expirationTs)){
    //         console.log("User's CAAP access already expired. Redirecting to dashboard.");
    //         router.push({ path: '/dashboard' });
    //     }else{
    //         console.log("User's CAAP access is not yet expired. All good.")
    //         return
    //     }
    // }else{
    //     console.log("User does not have an expiration date. Probably a legacy user.")
    //     return
    // }
}

async function caapExpirationGuard(userClaims, userInfo){

    console.log(`%c🎫 ----------- caapExpirationGuard 🏁 -----------`, 'background: #222; color: #bada55');
    console.log("Checking if the user still have access to caap reviewer...")

    let nextRoutePath = null;

    // check user claims if it has caapExpAt field
    if (userClaims.caapExpAt !== undefined) {
        // check the caapExptDate value is less than current data / already expired
        // if less than then check if user claim has the isCaapBlocked field
        // else just continue to caap reviewer
        console.log("User has an expiration date! Reviewer will expire on:", userClaims.caapExpAt)

        if(checkIfExpired(userClaims.caapExpAt)){

            console.log("User's CAAP access already expired. Blocking caap reviewer...");

            // check if isCaapBlocked field exist already
            // if (userClaims.isCaapBlocked !== undefined) {
                
            //     // check if it is true / false
            //     //  true = user is still blocked route to assessment page
            //     //  false = user is already approved or unblocked, route to mock history page
            //     // nextRoutePath = userClaims.isCaapBlocked ? { path: '/assessment' } : { path: '/caap/history' }
            //     nextRoutePath = { path: '/reviewers' }

            // }else{
                await Promise.all([
                    // update user claim isCaapBlocked to true
                    updateUserClaimBlockUser(userInfo.email, 'caap'),
                    // add user to blocked user collection
                    addUserToBlock(userInfo),
                    // update user collection add field isCaapBlocked
                    updateUserInfoSetBlock(userInfo, 'caap'),
                ]);
                // route to assessment page
                nextRoutePath = { path: '/reviewers' }
            // }
        }

    }else{

        // check user type
        let userType = checkUserType(userInfo)

        console.log('%c 👥 USER TYPE ====> ' + userType, 'background: #222; color: #bada55');

        switch (userType) {
            case 'legacy':
                await Promise.all([
                    // update legacy user claim
                    // caapExpAt = Jan 1, 2024
                    // isCaapBlocked = true
                    setLegacyUserClaim(userInfo),
                    // add legacy user to blocked user collection
                    addUserToBlock(userInfo),
                    // update user collection add field isCaapBlocked and reviewers.caap == 'expired'
                    updateUserInfoSetBlock(userInfo, 'caap'),
                ]);
                // route to assessment page
                nextRoutePath = { path: '/assessment' }
                break;
            case 'legacy-ext-activated':
                await Promise.all([
                    // caapExpAt = July 1, 2024
                    setLegacyExtActUserClaim(userInfo),
                    // update user info in users collection, change the expiration date to July 1, 2024
                    updateLegacyExtActUserInfoExtExpDate(userInfo)
                ]);
                // continue to caap reviewer
                break;
            case 'legacy-ext-inactive':
                // Nothing to do here...
                // because once the user activated the reviewer
                // the code/function there will also update claims caapExpAt and update the user collection data
                break;

            default:
                // for 
                break;
        }


    }

    return nextRoutePath;
}

async function plusExpirationGuard(userClaims, userInfo){

    console.log(`%c🎫 ----------- plusExpirationGuard 🏁 -----------`, 'background: #222; color: #bada55');
    console.log("Checking if the user still have access to caap plus reviewer...")

    let nextRoutePath = null;

    // check the plusExptDate value is less than current data / already expired
    // if less than then check if user claim has the isPlusBlocked field
    // else just continue to caap plus reviewer
    console.log("User has an expiration date for caap plus! Reviewer will expire on:", userClaims.plusExpAt)

    if(checkIfExpired(userClaims.plusExpAt)){

        console.log("User's CAAP PLUS access already expired. Blocking CAAP PLUS reviewer...");

        // if (userClaims.isPlusBlocked == undefined) {
            await Promise.all([
                // update user claim isCaapBlocked to true
                updateUserClaimBlockUser(userInfo.email, 'caap_plus'),
                // add user to blocked user collection
                // addUserToBlock(userInfo),
                // update user collection add field isCaapBlocked
                updateUserInfoSetBlock(userInfo, 'caap_plus'),
            ]);
        // }
        
        nextRoutePath = { path: '/reviewers' }

    }

    return nextRoutePath;
}

async function mtpExpirationGuard(userClaims, userInfo){

    console.log(`%c🎫 ----------- mtpExpirationGuard 🏁 -----------`, 'background: #222; color: #bada55');
    console.log("Checking if the user still have access to mtp reviewer...")

    let nextRoutePath = null;

    // check the mtpExptDate value is less than current data / already expired
    // if less than then check if user claim has the isMtpBlocked field
    // else just continue to mtp reviewer
    console.log("User has an expiration date for mtp! Reviewer will expire on:", userClaims.mtpExpAt)

    if(checkIfExpired(userClaims.mtpExpAt)){

        console.log("User's MTP access already expired. Blocking mtp reviewer...");

        // if (userClaims.isMtpBlocked == undefined) {
            await Promise.all([
                // update user claim isCaapBlocked to true
                updateUserClaimBlockUser(userInfo.email, 'mtp'),
                // add user to blocked user collection
                // addUserToBlock(userInfo),
                // update user collection add field isCaapBlocked
                updateUserInfoSetBlock(userInfo, 'mtp'),
            ]);
        // }
        
        nextRoutePath = { path: '/reviewers' }

    }

    return nextRoutePath;
}


// @user : user info from user collection
function checkUserType(user){

    // check if user has purchase date
    if (user.caap_data && user.caap_data.purchase_date) {


        let cutOffDate = moment('2023-07-01').valueOf()
        // user purchased before July 1, 2023
        if (cutOffDate > parseInt(user.caap_data.purchase_date)) {
            return 'legacy'
        }

        // check if user purchase date is between Jul 1, 2023 and Jan 1, 2024
        let extUserStartDate = moment('2023-06-30').valueOf()
        let extUserEndDate = moment('2024-01-08').valueOf() // making this Jan 8 after the blocking feature implemented
        if (extUserStartDate < parseInt(user.caap_data.purchase_date) && extUserEndDate > parseInt(user.caap_data.purchase_date)) {

          // check if the user has the activateLater data
          // if undefined meaning it was the legacy users before BNAL
          // if activation_date is not null then the user already activated the reviewer
          if (typeof user.caap_data.activation_date === 'undefined' || user.caap_data.activation_date != null) {
            return 'legacy-ext-activated';
          }else{
            // meaning the users did not activate still the reviewer
            return 'legacy-ext-inactive';
          }
          
        }

    }else{
        // if no purchase date then user is a legacy user
        return 'legacy';
    }

}

// this need to refactor!!!!
// this should not be here!!!
// functions below to update user claim and user collection data
// support the caap blocking feature
import firebase from "@/firebase/config";
import "firebase/firestore";
const db = firebase.firestore();

export async function updateUserClaimBlockUser(email, reviewer){

    console.log('Updating user claim to block caap...')

    return new Promise(async (resolve, reject) => {
      try {
        const blockUserFbFunc = firebase.functions().httpsCallable("blockUser");
        let blockUserResponse = await blockUserFbFunc({ email: email, reviewer: reviewer })
        console.log(`Successfully updating user claim to blocked ${reviewer}.`, blockUserResponse)
        resolve(blockUserResponse)

      } catch (error) {
        console.error('Error happened during updating user claim to block caap.')
        reject(error)
      }
    })
    
}

export async function addUserToBlock(user){

    return new Promise(async (resolve, reject) => {

        console.log('Adding user to caap blocked db...')

        let data = {
            userId: user.id,
            userEmail: user.email,
            status: 'blocked',
            postExamSurveyStatus: 'waiting',
            achievementBoxStatus: 'waiting',
            metadata: {
                blockedOn: moment().valueOf().toString(),
                blockedBy: null,
                updatedOn: moment().valueOf().toString(),
                updatedBy: null,
                rejectedCounter: 0
            },
        }
        
        // push the user to add caap_blocked collection
        db.collection("caap_blocks").doc(user.id).set(data)
        .then(async () => { 
            console.log('Successfully added user to caap blocked db.')
            resolve(true)
        })
        .catch((error) => { 
          console.error("Error adding user data to caap blocked db: ", error)  
          reject(error)
        });
        
    })

}

export async function updateUserInfoSetBlock(user, reviewer){

    return new Promise(async (resolve, reject) => {

        console.log('Updating user info in db, setting caap block...')

        let data = null;

        //refactor switch to if statement
        if (reviewer === 'caap') {
            data = {
                'reviewers.caap': 'inactive',
                isCaapBlocked: true
            };
            
        }else if (reviewer === 'caap_plus') {
            data = {
                'reviewers.caap_plus': 'inactive',
                isPlusBlocked: true
            };
        }else if (reviewer === 'mtp') {
            data = {
                'reviewers.mtp': 'inactive',
                isMtpBlocked: true
            };
        }else{
            throw new Error(`Reviewer ${reviewer} is not supported`);
        }
        
        // push the user to add caap_blocked collection
        db.collection("users").doc(user.id).update(data)
        .then(async () => { 
            console.log('Successfully setting user info to caap block.');
            resolve(true);
        })
        .catch((error) => { 
          console.error("Error setting user info to caap block: ", error);
          reject(error);
        });
        
    })

}

async function setLegacyUserClaim(user){

    console.log('Setting legacy user claim to block caap...')
    
    return new Promise(async (resolve, reject) => {
      try {
        // update user claim
        // caapExpAt = Jan 1, 2024
        // isCaapBlocked = true
        const expDate = new Date('2023-12-31T14:00:38.984Z')
        const blockLegacyUserFbFunc = firebase.functions().httpsCallable("blockLegacyExtActUser");
        let blockLegacyUserResponse = await blockLegacyUserFbFunc({ email: user.email, caapExpAt: expDate })

        console.log('Successfully setting legacy user claim to block caap.', blockLegacyUserResponse)
        resolve(blockLegacyUserResponse)

      } catch (error) {
        console.error('Error happened during setting ext activated user claim to block caap.')
        reject(error)
      }
    })
    
}

async function setLegacyExtActUserClaim(user){

    console.log('Setting caap expiration date for legacy ext activated user claim...')
    
    return new Promise(async (resolve, reject) => {
      try {
        // update user claim
        // caapExpAt = Jul 01, 2024
        const expDate = new Date('2024-06-30T23:00:00')
        const blockLegacyExtActUserFbFunc = firebase.functions().httpsCallable("blockLegacyExtActUser");
        let blockLegacyExtActUserResponse = await blockLegacyExtActUserFbFunc({ email: user.email, caapExpAt: expDate })

        console.log('Successfully setting caap expiration date for legacy ext activated user claim .', blockLegacyExtActUserResponse)
        resolve(blockLegacyExtActUserResponse)
      } catch (error) {
        console.error('Error happened during setting caap expiration date for legacy ext activated user claim.')
        reject(error)
      }
    })
    
}

async function updateLegacyExtActUserInfoExtExpDate(user){

    return new Promise(async (resolve, reject) => {

        console.log('Updating legacy ext active user info in db, extending expiration date to Jun 30, 2024...')

        let data = {
            caap_data: {
                expiration_date: new Date('2024-06-30T23:00:00')
            }
        }
        
        // push the user to add caap_blocked collection
        db.collection("users").doc(user.id).set(data, { merge: true })
        .then(async () => { 
            console.log('Successfully extending user expiration date for caap.')
            resolve(true)
        })
        .catch((error) => { 
          console.error("Error extending user expiration date for caap: ", error)  
          reject(error)
        });
        
    })

}





export {
    checkIfExpired,
    convertTimestampToDate,
    expirationGuard,
    caapExpirationGuard,
    mtpExpirationGuard,
    plusExpirationGuard,
}
