<script setup>
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue"
import { LightningBoltIcon, FireIcon, PaperClipIcon } from "@heroicons/vue/outline";
import ROUTER from "@/router"

const { isOpen, subject } = defineProps(['isOpen', 'subject'])
const emit = defineEmits(['closeModal'])

const handleCloseClick = () => { emit('closeModal') }

</script>
<template>
    <!--  Popup: Subject -->
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleCloseClick()">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
            </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div class="inline-block w-full max-w-3xl p-8 my-8 overflow-hidden text-left align-middle transition-all transform dark:bg-true-gray-800 bg-white shadow-xl rounded-2xl">
              <div class="flex text-true-gray-200 justify-center">
                <!-- <component :is="subject.icon" class="w-6 h-6" /> -->
                <DialogTitle as="h3" class="ml-1 text-lg font-medium text-true-gray-200">
                  {{ subject.name }}
                </DialogTitle>
                </div>
                <div class="flex dark:text-true-gray-400 text-warm-gray-800 justify-center">
                  <span class="rounded-full bg-purple-500 text-purple-50 text-xs ml-1 mt-1 font-normal py-0.5 px-1.5">
                    {{ subject.exam.items.reduce( (a, b) => a + b, 0)}} Items
                  </span>
                </div>
                <button type="button" @click="handleCloseClick()" class="absolute top-0 right-0 mr-4 mt-4">
                  <svg class="w-6 h-6 dark:text-true-gray-400 text-warm-gray-400 hover:text-warm-gray-500 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                </button>
                <div class="mt-2">

                  <!-- More Info -->
                  <p class="text-sm font-bold dark:text-true-gray-400 text-warm-gray-700 text-left mt-6">
                    Before you begin, please take note:
                  </p>
                  <p class="text-sm dark:text-true-gray-400 text-warm-gray-700 text-left mt-4">
                    <ol class="list-decimal ml-4 space-y-2 mt-2">
                      <li class="pl-1.5">Passing score is {{ subject.exam.limits.passer}}% and the time limit is {{ subject.exam.limits.time/60}} minutes.</li>
                      <li class="pl-1.5">This Mock Exam will end once time limit has been reached.</li>
                      <li class="pl-1.5">You have 4 skips. Skipped items will be looped at the last part.</li>
                      <li class="pl-1.5">Click 'Next' to confirm your answer.</li>
                      <li class="pl-1.5">Click 'Quit' icon to cancel the exam. All results will be voided.</li>
                    </ol>
                  </p>
                  <p class="text-sm dark:text-true-gray-400 text-warm-gray-700 text-left mt-8">
                    Reach the goals to unlock these milestones:
                  </p>
                    
                  <!-- Milestone Tip Section -->
                  <div class="rounded-md dark:bg-true-gray-700 dark:bg-opacity-25 bg-warm-gray-100 overflow-hidden text-sm  text-left mt-2 w-full">
                    <div class="px-4 py-5 sm:p-5 sm:space-x-4 grid grid-cols-1 sm:grid-cols-3 text-center items-start">
                      <div class="mt-4">
                        <div class="mx-auto bg-blue-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                          <PaperClipIcon class="text-blue-500 w-6 h-6" />
                        </div>
                        <h3 class="dark:text-true-gray-300 text-warm-gray-800 text-base font-bold my-1">
                          Passer
                        </h3>
                        <!-- <div class="mt-2 w-40 mx-auto bg-yellow-300 text-yellow-900 font-bold rounded-full text-xs p-1">
                          +{{ subject.exam.points.passer }} Skillpoints
                        </div> -->
                        <p class="mt-2 mb-4 dark:text-true-gray-400 text-warm-gray-600 text-sm">
                          Pass the exam within the time limit.
                        </p>
                      </div>
                      <div class="mt-4">
                        <div class="mx-auto bg-purple-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                          <LightningBoltIcon class="text-purple-500 w-6 h-6" />
                        </div>
                        <h3 class="dark:text-true-gray-300 text-warm-gray-800 text-base font-bold my-1">
                          Speedrunner
                        </h3>
                        <!-- <div class="mt-2 w-40 mx-auto bg-yellow-400 text-yellow-900 font-bold rounded-full text-xs p-1">
                          +{{ subject.exam.points.speedrunner }} Skillpoints
                        </div> -->
                        <p class="mt-2 mb-4 dark:text-true-gray-400 text-warm-gray-600 text-sm">
                          Pass the exam before it reaches {{(Math.floor( subject.exam.limits.speedrunner/60 * 100) / 100) }} minutes.
                        </p>
                      </div>
                      <div class="mt-4">
                          <div class="mx-auto bg-yellow-300 flex-grow-0 rounded-full w-12 h-12 p-3">
                            <FireIcon class="text-yellow-500 w-6 h-6" />
                          </div>
                          <h3 class="dark:text-true-gray-300 text-warm-gray-800 text-base font-bold my-1">
                            Master
                          </h3>
                          <!-- <div class="mt-2 w-40 mx-auto bg-yellow-500 text-yellow-900 font-bold rounded-full text-xs p-1">
                            +{{ subject.exam.points.master }} Skillpoints
                          </div> -->
                          <p class="mt-2 mb-4 dark:text-true-gray-400 text-warm-gray-600 text-sm">
                            Score {{ subject.exam.limits.master}}% or more before it reaches {{(Math.floor( subject.exam.limits.speedrunner/60 * 100) / 100) }} minutes.
                          </p>
                      </div>
                    </div>
                  </div>
                    
                  <!-- Action Buttons -->
                  <div class="mt-8 sm:mt-6 w-full">
                    <button @click="ROUTER.push({ path: `/mtp-entrance/exam`, query: { subjectId: subject.id } })" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                      Begin Mock Exam
                    </button>
                  </div>
                </div>
              </div>
          </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
</template>