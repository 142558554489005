<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <h3>💡 Quick Tips: Key Areas to Familiarize for Aviation Sciences</h3>
        <p>
            Understanding the basics of aviation involves grasping foundational concepts about the atmosphere, flight principles, and aircraft mechanics. Here are some essential groups of topics to focus on:
        </p>

        <h3>1. Layers of the Earth's Atmosphere</h3>
        <section>
            <p>
                The atmosphere is divided into distinct layers, each with unique characteristics affecting flight dynamics:
            </p>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Troposphere:</strong> Extends from Earth's surface up to about 8-15 km (5-9 miles). This layer contains approximately 75% of the atmosphere's mass and is where all weather phenomena occur. Temperature decreases with altitude.
                </li>
                <li>
                    <strong>Stratosphere:</strong> Ranging from about 15 km to 50 km (9 to 31 miles) above the surface, the stratosphere houses the ozone layer, which absorbs and scatters ultraviolet solar radiation. In this layer, temperature increases with altitude.
                </li>
                <li>
                    <strong>Mesosphere:</strong> Extends from 50 km to 85 km (31 to 53 miles) above Earth. It's the coldest atmospheric layer, with temperatures dropping as low as -90°C (-130°F). This layer is where most meteors burn up upon entry.
                </li>
                <li>
                    <strong>Thermosphere:</strong> Spanning from about 85 km to 600 km (53 to 373 miles), the thermosphere experiences a significant temperature increase with altitude, reaching up to 2,500°C (4,500°F). Despite high temperatures, the low air density means it wouldn't feel hot to human skin. The auroras occur in this layer.
                </li>
                <li>
                    <strong>Exosphere:</strong> The outermost layer, extending from around 600 km (373 miles) to 10,000 km (6,200 miles). It's where atmospheric particles gradually escape into space, and satellites orbit within this layer.
                </li>
            </ul>
            <p>
                <strong>Note:</strong> The ionosphere overlaps the mesosphere, thermosphere, and parts of the exosphere. It's a region filled with charged particles and is crucial for radio communication as it reflects and modifies radio waves transmitted from Earth.
            </p>
        </section>

        <h3>2. Types of Aerodynamic Drag</h3>
        <section>
            <p>Understanding the different forms of drag is essential for optimizing aircraft performance:</p>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Parasite Drag:</strong> This is the resistance experienced by an aircraft as it moves through the air, encompassing:
                    <ul class="list-disc pl-6 mt-2">
                        <li><strong>Form Drag:</strong> Caused by the shape and frontal area of the aircraft components, leading to airflow separation and wake formation.</li>
                        <li><strong>Skin Friction Drag:</strong> Results from the friction between the aircraft's surface and the air molecules in immediate contact with it.</li>
                        <li><strong>Interference Drag:</strong> Occurs where different aircraft components meet, such as the wing and fuselage junction, causing complex airflow interactions.</li>
                    </ul>
                </li>
                <li><strong>Induced Drag:</strong> A byproduct of lift, induced drag arises due to the creation of wingtip vortices, which generate downwash and increase the angle of attack required for a given lift coefficient.</li>
                <li><strong>Wave Drag:</strong> Associated with transonic and supersonic flight, wave drag results from shock waves forming on the aircraft, leading to energy loss.</li>
            </ul>
        </section>

        <h3>3. International Standard Atmosphere (ISA) Conditions</h3>
        <section>
            <p>The ISA assumes the following baseline conditions at sea level:</p>
            <ul class="list-disc pl-4">
                <li><strong>Temperature:</strong> 15°C (59°F)</li>
                <li><strong>Pressure:</strong> 1013.25 millibars (mb) or 29.92 inches of mercury (inHg)</li>
                <li><strong>Density:</strong> 1.225 kg/m³</li>
                <li><strong>Speed of Sound:</strong> 340.3 m/s (661 knots)</li>
                <li><strong>Gravity Acceleration:</strong> 9.80665 m/s²</li>
            </ul>
        </section>

        <h3>4. Newton's Laws of Motion</h3>
        <section>
            <ul class="list-disc pl-4">
                <li><strong>First Law (Law of Inertia):</strong> An object at rest stays at rest, and an object in motion stays in motion at a constant velocity unless acted upon by an external force.</li>
                <li><strong>Second Law (Force and Acceleration):</strong> The force acting on an object is equal to its mass times its acceleration: F = ma</li>
                <li><strong>Third Law (Action and Reaction):</strong> For every action, there is an equal and opposite reaction.</li>
            </ul>
            <p>
                These laws form the foundation of classical mechanics and are essential for understanding motion and forces in aviation.
            </p>
        </section>

        <h3>5. Atmospheric Metrics</h3>
        <section>
            <p>Understanding atmospheric properties and their impact on flight performance requires familiarity with these key metrics:</p>
            <ul class="list-disc pl-4">
                <li><strong>Pressure:</strong> Decreases exponentially with altitude. Standard sea-level pressure is 1013.2 mb or 29.92 inHg.</li>
                <li><strong>Temperature:</strong> Decreases in the troposphere at a lapse rate of ~2°C per 1,000 feet, stabilizing at the tropopause.</li>
                <li><strong>Density:</strong> Greater at low altitudes, providing more air for lift and engine performance. Density decreases as altitude increases.</li>
                <li><strong>Humidity:</strong> The amount of water vapor in the air. Higher humidity decreases air density, reducing lift and thrust efficiency.</li>
                <li><strong>Speed of Sound:</strong> Decreases with temperature, impacting sonic flight conditions. At sea level, it’s 340.3 m/s (661 knots).</li>
                <li><strong>Altitude:</strong> Measured as pressure altitude or density altitude, directly influencing engine power, lift, and drag.</li>
            </ul>
            <p>These metrics collectively define the atmospheric conditions critical for aircraft performance and navigation.</p>
        </section>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ul {
    @apply space-y-1.5;
}

p {
    @apply text-sm;
}
</style>
