<template>

  <div class="max-w-full mx-auto py-6 px-3">
    <div v-if="!isLoading" class="mock">

      <!-- Breadcrumbs -->
      <nav class="flex px-1 lg:px-8 justify-between items-center" aria-label="Breadcrumb">
        <div class="flex items-center">
          <div @click="this.openOnExitModal()" class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center cursor-pointer" aria-current="page">
            <LogoutIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
          </div>
        </div>
        <div class="text-sm font-medium text-warm-gray-500">
          <!-- <span>Mock Exam for {{ subject.name}}</span> -->
          <span>Mock Exam</span>
        </div>
        <!-- FAQ Menu -->
        <Menu as="div" class="ml-3 relative inline-block text-left">
          <MenuButton class="p-1 rounded-full flex items-center text-warm-gray-500 hover:text-warm-gray-600 focus:outline-none focus:ring-2 focus:ring-yellow-600">
            <span class="sr-only">Open options</span>
            <QuestionMarkCircleIcon class="h-5 w-5" aria-hidden="true" />
          </MenuButton>
          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="z-50 origin-top-right absolute right-0 mt-3 w-72 -mr-2 rounded-md shadow-lg dark:bg-true-gray-700 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <button type="button" :class="[ active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                    <span>What am I seeing in this page?</span>
                  </button>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <button :class="[active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                    <span>What is a Practice/Mock Exam?</span>
                  </button>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <button :class="[active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                    <span>What are Achievements?</span>
                  </button>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </nav>

      <!-- Main Content -->
      <main class="max-w-full mt-6 mx-auto px-1 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8 space-y-4">
        
        <!-- Stats -->
        <div id="stats" class="dark:border-true-gray-700 min-h-60 rounded-md mb-4 flex justify-between">
          <div class="flex dark:text-warm-gray-500 text-warm-gray-800 text-md items-center">
            <FlagIcon class="w-5 h-5 mr-1" />
            <p class="hidden sm:block ml-2 font-medium dark:text-warm-gray-500 text-warm-gray-800">
              Question {{ exam.index + 1 }} out of {{ this.exam.questionnaire.length }}
            </p>
            <p class="block sm:hidden ml-2 font-medium dark:text-warm-gray-500 text-warm-gray-800">
              {{ this.exam.index + 1 }} of {{ this.exam.questionnaire.length }}
            </p>
          </div>
          <div class="flex text-warm-gray-800 text-md items-center">
            <div class="ml-2 font-medium text-warm-gray-800 flex items-center">
              <div class="mr-3 flex items-center dark:text-warm-gray-500 text-warm-gray-700">
                <ClockIcon @click="showCheatSheet()" class="ml-1 w-5 h-5 mr-1" />
                {{ this.formatTime(exam.time.running) }}
              </div>
              <div class="flex items-center dark:text-warm-gray-500 text-warm-gray-700">
                <ChartSquareBarIcon class="ml-1 w-5 h-5 mr-1" />
                {{ exam.score }}%
              </div>
            </div>
          </div>
        </div>

        <template v-for="(q,i) in exam.questionnaire">
          <div v-show="i == exam.index">
               <!-- Question -->
              <div class="select-none text-left text-warm-gray-900 dark:text-warm-gray-300 rounded-md -space-y-px p-6 border-2 border-dashed border-warm-gray-300 dark:border-true-gray-700 whitespace-pre-line">
                <div v-html="exam.questionnaire[exam.index].questionHtml" class="quill-content w-full"></div>
                <img v-if="questionnaire[exam.index].hasImage" :src="questionnaire[exam.index].imageUrl" class="lg:max-w-24 rounded-xl mx-auto my-2 mt-4">
              </div>

              <!-- Choices -->
              <div id="choices" class="select-none mt-4">
                <ul class="text-warm-gray-800 dark:text-warm-gray-400 text-left rounded-md shadow-sm -space-y-px">
                  <li v-for="(choice, idX) in exam.questionnaire[exam.index].choices" :key="idX">
                    <button
                      @click="exam.answers[exam.index].answer = exam.questionnaire[exam.index].choices[idX].id"
                      :class="[ showSelected(idX), setBorder(idX), 'flex justify-between border border-warm-gray-300 dark:border-true-gray-700 relative shadow-sm px-5 py-4 cursor-pointer text-left focus:outline-none w-full disabled:cursor-auto']"
                    >
                      <div v-html="choice.choiceHtml" class="w-full"></div>
                    </button>
                  </li>
                </ul>
              </div>
          </div>
        </template>

       

        <!-- Action Buttons -->
        <nav class="mt-6 flex justify-between space-x-4">
          <button
            :disabled="!this.isSkippable()"
            @click="this.skipQuestion()"
            class="disabled:opacity-50 w-full sm:w-auto space-x-4 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5 border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Skip
            <span class="inline-flex items-center justify-center ml-1 px-2 py-1 text-xs font-bold leading-none text-white bg-blue-400 rounded-full">
              {{ exam.skips }}
            </span>
          </button>

          <button
            v-if="this.countUnanswered() > 0"
            :disabled="exam.answers[exam.index].answer == null"
            @click="transitionToNextQuestion()"
            class="disabled:opacity-50 w-full sm:w-auto space-x-4 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5 border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Next
            <ChevronDoubleRightIcon class="h-5 w-5 dark:text-warm-gray-400 text-warm-gray-500 ml-1"/>
          </button>

          <button
            v-else
            :disabled="exam.answers[exam.index].answer == null"
            v-on:click="this.completeMock()"
            class="disabled:opacity-50 w-full sm:w-auto space-x-4 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5 border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Complete Mock Exam
          </button>
        </nav>

        <div v-if="isShowCheatSheet" class="text-red-600">
          <div v-html="correctAnswer"></div>
        </div>

      </main>

      <!-- Popup: On Exit -->
      <TransitionRoot as="template" :show="isOnExitOpen">
        <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="isOnExitOpen = false">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
              <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
            </TransitionChild>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div class="inline-block align-bottom dark:bg-true-gray-800 bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4"></div>
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <DialogTitle as="h3" class="text-lg leading-6 font-medium dark:text-gray-200 text-warm-gray-900">
                      Before you go...
                    </DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-warm-gray-500 dark:text-warm-gray-400">
                        Please note that your progress in this Mock Exam will
                        <strong>not</strong> be saved. You can always come back later to start a new Mock Exam session.
                      </p>
                      <p class="text-sm text-gray-500 dark:text-warm-gray-400 max-w-sm mt-4">
                        Are you sure you want to proceed?
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    @click="this.quitMock()"
                    type="button"
                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Quit
                  </button>
                  <button
                    @click="(isOnExitOpen = false), (exam.status = 'started')"
                    type="button"
                    class="mt-3 w-full inline-flex justify-center text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-700 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 rounded-md border dark:border-warm-gray-600 border-warm-gray-300 shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>

      <!-- Popup: On Complete -->
      <TransitionRoot appear :show="isOnCompleteOpen" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
              </TransitionChild>
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
                <div class="text-center inline-block w-full max-w-xl p-8 my-8 overflow-hidden align-middle transition-all transform bg-white dark:bg-true-gray-800 shadow-xl rounded-2xl">
                  <DialogTitle as="h3" class="text-sm uppercase font-semibold leading-6 text-warm-gray-500 dark:text-warm-gray-200 text-center">
                    Mock Exam Results
                  </DialogTitle>
                  <div class="mt-2">
                    <div class="text-3xl font-bold text-warm-gray-700 dark:text-warm-gray-200 mt-8">
                      <p v-if="this.exam.milestones.includes('master')">You’ve mastered it! 🥳🔥</p>
                      <p v-else-if="this.exam.milestones.includes('speedrunner')">Fast like a rocket! 😲🚀</p>
                      <p v-else-if="this.exam.milestones.includes('passer')">You passed! 😉👍🏼</p>
                      <p v-else>Try harder next time. 🙁👎</p>
                    </div>
                    <p class="text-lg dark:text-warm-gray-200 text-warm-gray-700 mt-1 mb-2">
                      You got {{ this.countCorrectAnswers() }} correct answers out
                      of {{ this.exam.questionnaire.length }} items.
                    </p>
                    <div class="flex flex-col justify-center space-y-2 my-2 flex-grow-0 dark:text-warm-gray-400 text-warm-gray-700">
                      <div>Score: {{ this.exam.score}}% <span v-if="isRecordBreakScore" class="ml-1 text-yellow-400 font-bold text-xs">NEW RECORD!</span></div>
                      <div>Time: {{ this.formatTime(this.exam.time.running) }} <span v-if="isRecordBreakTime" class="ml-1 text-yellow-400 font-bold text-xs">NEW RECORD!</span></div>
                    </div>
                    <div v-if="this.exam.result == 'passed'" class="text-sm dark:text-warm-gray-400 text-warm-gray-500 max-w-sm mx-auto">
                      <p class=" text-lg dark:text-warm-gray-200 text-warm-gray-700 mt-8">
                        Your reward:
                      </p>
                      <div class="rounded-md dark:bg-true-gray-700 dark:bg-opacity-25 bg-warm-gray-100 overflow-hidden text-sm  text-left mt-2 w-full">
                        <div class="my-4 flex flex-col sm:flex-row space-y-8 sm:space-y-0 sm:space-x-4 text-center items-center justify-center p-2">
                          <div v-if="this.exam.milestones.includes('passer')" class="w-28">
                            <div class="mx-auto bg-blue-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                              <PaperClipIcon class="text-blue-500 w-6 h-6" />
                            </div>
                            <h3 class="dark:text-true-gray-300 text-warm-gray-800 text-base font-bold my-1">Passer</h3>
                            <!-- <p class="dark:text-true-gray-400 text-warm-gray-600 text-sm">+{{ this.subject.exam.points.passer }} SP</p> -->
                          </div>
                          <div v-if="this.exam.milestones.includes('speedrunner')" class="w-28">
                            <div class="mx-auto bg-purple-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                              <LightningBoltIcon class="text-purple-500 w-6 h-6" />
                            </div>
                            <h3 class="dark:text-true-gray-300 text-warm-gray-800 text-base font-bold my-1">Speedrunner</h3>
                            <!-- <p class="dark:text-true-gray-400 text-warm-gray-600 text-sm">+{{ this.subject.exam.points.speedrunner }} SP</p> -->
                          </div>
                          <div v-if="this.exam.milestones.includes('master')" class="w-28">
                            <div class="mx-auto bg-yellow-300 flex-grow-0 rounded-full w-12 h-12 p-3">
                              <FireIcon class="text-yellow-500 w-6 h-6" />
                            </div>
                            <h3 class="dark:text-true-gray-300 text-warm-gray-800 text-base font-bold my-1">Master</h3>
                            <!-- <p class="dark:text-true-gray-400 text-warm-gray-600 text-sm">+{{ this.subject.exam.points.master }} SP</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="this.exam.result == 'failed'" class="bg-gray-50 overflow-hidden rounded-lg text-sm text-warm-gray-500 text-left mt-8 max-w-lg">
                      <div class="px-4 py-5 sm:p-5 flex space-x-2 items-start">
                        <div>
                          <LightBulbIcon class="text-yellow-400 w-5 h-5" />
                        </div>
                        <p class="text-yellow-700">
                          <strong>Tip:</strong> {{tips[Math.floor(Math.random() * this.tips.length)]}}
                        </p>
                      </div>
                    </div>
                    <div class="mt-8 sm:mt-6 sm:flex sm:space-x-4">
                      <button @click="this.exitMock()" type="button" class="my-1.5 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                        Exit
                      </button>
                      <!-- <button @click="this.reviewExamResults()" type="button" class="my-1.5 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                        Review Exam Results
                      </button> -->
                    </div>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>

      <!-- Popup: On Achievement -->
      <TransitionRoot appear :show="isOnAchievementOpen" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
              </TransitionChild>
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
                <div class="text-center inline-block w-full max-w-xl p-8 my-8 overflow-hidden align-middle transition-all transform bg-white dark:bg-true-gray-800 shadow-xl rounded-2xl">
                  <DialogTitle as="h3" class="text-sm uppercase font-semibold leading-6 text-warm-gray-500 dark:text-warm-gray-200 text-center">
                    New Achievement
                  </DialogTitle>
                  <div class="mt-2">
                    <div class="text-3xl font-bold text-warm-gray-700 dark:text-warm-gray-200 mt-8">
                      <p>New Achievement Unlocked! 🏆 </p>
                    </div>
                    <div class="relative rounded-lg overflow-hidden dark:bg-true-gray-800 bg-white shadow-sm dark:focus-within:ring-true-gray-800 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-yellow-500 my-0">
                    
                    <!-- Achievement Things! -->
                    <div v-for="achievement in achievements" :key="achievement.id">

                      <!-- All Passer AVT -->
                      <div v-if="achievement.isAchieved && achievement.id == 'full-passer-avt'" class="flex flex-col justify-center content-center">
                        <div class="bg-yellow-200 text-yellow-500 dark:bg-yellow-300 dark:text-yellow-500 rounded-full w-40 h-40 p-6 my-4 mx-auto">
                          <AllPasserLogoHeli />
                        </div>
                        <!-- Subject Data -->
                        <div class="focus:outline-none text-left">
                          <div class="flex dark:text-warm-gray-400 text-warm-gray-800">
                            <h1 class="text-lg w-full text-center mt-2 font-medium dark:text-warm-gray-400 text-warm-gray-800">
                                {{achievement.name}}
                            </h1>
                          </div>
                          <p class="text-sm w-full text-center text-warm-gray-500 mt-2">
                            {{achievement.description}}
                          </p>
                          <div class="relative pt-1 mt-6 mx-auto">
                            <div class="flex justify-left">
                              <span class="text-warm-gray-500 mr-2">Congratulations! You will receive </span>
                              <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-yellow-600 bg-yellow-200 flex flex-inline items-center justify-center space-x-1">
                              <!-- <span class="bg-yellow-200 px-2 py-1 uppercase rounded-fulltext-warm-gray-800 dark:text-warm-gray-200 text-xs font-medium text-center flex flex-inline items-center justify-center space-x-1"> -->
                                <Logo class="w-4 h-4" />
                                <span>+{{ achievement.skillpoints }}</span>
                              </span>
                              <span class="ml-2 text-warm-gray-500 mr-2">Skillpoints.</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- All Passer AMT -->
                      <div v-if="achievement.isAchieved && achievement.id == 'full-passer-amt'" class="flex flex-col justify-center content-center">
                        <div class="bg-yellow-200 text-yellow-500 dark:bg-yellow-300 dark:text-yellow-500 rounded-full w-40 h-40 p-6 my-4 mx-auto">
                          <AllPasserLogo />
                        </div>
                        <!-- Subject Data -->
                        <div class="focus:outline-none text-left">
                          <div class="flex dark:text-warm-gray-400 text-warm-gray-800">
                            <h1 class="text-lg w-full text-center mt-2 font-medium dark:text-warm-gray-400 text-warm-gray-800">
                                {{achievement.name}}
                            </h1>
                          </div>
                          <div class="relative pt-1 mt-6 mx-auto">
                            <div class="flex justify-center">
                              <span class="text-sm text-warm-gray-500 mr-2">Congratulations! You will receive </span>
                              <span class="animate-bounce text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-yellow-600 bg-yellow-200 flex flex-inline items-center justify-center space-x-1">
                                <Logo class="w-4 h-4" />
                                <span>+{{ achievement.skillpoints }}</span>
                              </span>
                              <span class="text-sm ml-2 text-warm-gray-500 mr-2">Skillpoints.</span>
                            </div>
                          </div>
                          <p class="text-sm w-full text-center text-warm-gray-500 mt-2">
                            {{achievement.description}}
                          </p>
                        </div>
                      </div>
                      
                      <!-- All Speedrunner AVT -->
                      <div v-if="achievement.isAchieved && achievement.id == 'full-speedrunner-avt'" class="flex flex-col justify-center content-center">
                        <div class="bg-yellow-200 text-yellow-500 dark:bg-yellow-300 dark:text-yellow-500 rounded-full w-40 h-40 p-6 my-4 mx-auto">
                          <AllSpeedrunnerLogoHeli />
                        </div>
                        <!-- Subject Data -->
                        <div class="focus:outline-none text-left">
                          <div class="flex dark:text-warm-gray-400 text-warm-gray-800">
                            <h1 class="text-lg w-full text-center mt-2 font-medium dark:text-warm-gray-400 text-warm-gray-800">
                                {{achievement.name}}
                            </h1>
                          </div>
                          <div class="relative pt-1 mt-6 mx-auto">
                            <div class="flex justify-center">
                              <span class="text-sm text-warm-gray-500 mr-2">Congratulations! You will receive </span>
                              <span class="animate-bounce text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-yellow-600 bg-yellow-200 flex flex-inline items-center justify-center space-x-1">
                                <Logo class="w-4 h-4" />
                                <span>+{{ achievement.skillpoints }}</span>
                              </span>
                              <span class="text-sm ml-2 text-warm-gray-500 mr-2">Skillpoints.</span>
                            </div>
                          </div>
                          <p class="text-sm w-full text-center text-warm-gray-500 mt-2">
                            {{achievement.description}}
                          </p>
                        </div>
                      </div>

                      <!-- All Speedrunner AMT -->
                      <div v-if="achievement.isAchieved && achievement.id == 'full-speedrunner-amt'" class="flex flex-col justify-center content-center">
                        <div class="bg-yellow-200 text-yellow-500 dark:bg-yellow-300 dark:text-yellow-500 rounded-full w-40 h-40 p-6 my-4 mx-auto">
                          <AllSpeedrunnerLogo />
                        </div>
                        <!-- Subject Data -->
                        <div class="focus:outline-none text-left">
                          <div class="flex dark:text-warm-gray-400 text-warm-gray-800">
                            <h1 class="text-lg w-full text-center mt-2 font-medium dark:text-warm-gray-400 text-warm-gray-800">
                                {{achievement.name}}
                            </h1>
                          </div>
                          <div class="relative pt-1 mt-6 mx-auto">
                            <div class="flex justify-center">
                              <span class="text-sm text-warm-gray-500 mr-2">Congratulations! You will receive </span>
                              <span class="animate-bounce text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-yellow-600 bg-yellow-200 flex flex-inline items-center justify-center space-x-1">
                                <Logo class="w-4 h-4" />
                                <span>+{{ achievement.skillpoints }}</span>
                              </span>
                              <span class="text-sm ml-2 text-warm-gray-500 mr-2">Skillpoints.</span>
                            </div>
                          </div>
                          <p class="text-sm w-full text-center text-warm-gray-500 mt-2">
                            {{achievement.description}}
                          </p>
                        </div>
                      </div>

                      <!-- All Master AVT -->
                      <div v-if="achievement.isAchieved && achievement.id == 'full-master-avt'" class="flex flex-col justify-center content-center">
                        <div class="bg-yellow-200 text-yellow-500 dark:bg-yellow-300 dark:text-yellow-500 rounded-full w-40 h-40 p-6 my-4 mx-auto">
                          <AllMasterLogoHeli />
                        </div>
                        <!-- Subject Data -->
                        <div class="focus:outline-none text-left">
                          <div class="flex dark:text-warm-gray-400 text-warm-gray-800">
                            <h1 class="text-lg w-full text-center mt-2 font-medium dark:text-warm-gray-400 text-warm-gray-800">
                                {{achievement.name}}
                            </h1>
                          </div>
                          <div class="relative pt-1 mt-6 mx-auto">
                            <div class="flex justify-center">
                              <span class="text-sm text-warm-gray-500 mr-2">Congratulations! You will receive </span>
                              <span class="animate-bounce text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-yellow-600 bg-yellow-200 flex flex-inline items-center justify-center space-x-1">
                                <Logo class="w-4 h-4" />
                                <span>+{{ achievement.skillpoints }}</span>
                              </span>
                              <span class="text-sm ml-2 text-warm-gray-500 mr-2">Skillpoints.</span>
                            </div>
                          </div>
                          <p class="text-sm w-full text-center text-warm-gray-500 mt-2">
                            {{achievement.description}}
                          </p>
                        </div>
                      </div>

                      <!-- All Master AMT -->
                      <div v-if="achievement.isAchieved && achievement.id == 'full-master-amt'" class="flex flex-col justify-center content-center">
                        <div class="bg-yellow-200 text-yellow-500 dark:bg-yellow-300 dark:text-yellow-500 rounded-full w-40 h-40 p-6 my-4 mx-auto">
                          <AllMasterLogo />
                        </div>
                        <!-- Subject Data -->
                        <div class="focus:outline-none text-left">
                          <div class="flex dark:text-warm-gray-400 text-warm-gray-800">
                            <h1 class="text-lg w-full text-center mt-2 font-medium dark:text-warm-gray-400 text-warm-gray-800">
                                {{achievement.name}}
                            </h1>
                          </div>
                          <div class="relative pt-1 mt-6 mx-auto">
                            <div class="flex justify-center">
                              <span class="text-sm text-warm-gray-500 mr-2">Congratulations! You will receive </span>
                              <span class="animate-bounce text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-yellow-600 bg-yellow-200 flex flex-inline items-center justify-center space-x-1">
                                <Logo class="w-4 h-4" />
                                <span>+{{ achievement.skillpoints }}</span>
                              </span>
                              <span class="text-sm ml-2 text-warm-gray-500 mr-2">Skillpoints.</span>
                            </div>
                          </div>
                          <p class="text-sm w-full text-center text-warm-gray-500 mt-2">
                            {{achievement.description}}
                          </p>
                        </div>
                      </div>

                    </div>
                  </div>
                    <div class="mt-8 sm:mt-6">
                      <button @click="this.exitAchievement()" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                        View Achievements
                      </button>
                    </div>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>

      <!-- Popup: On Timed Out -->
      <TransitionRoot appear :show="isOnTimedOutOpen" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75"/>
              </TransitionChild>
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
                <div class="text-center inline-block w-full max-w-xl p-8 my-8 overflow-hidden align-middle transition-all transform bg-white dark:bg-true-gray-800 shadow-xl rounded-2xl">
                  <DialogTitle as="h3" class="text-sm uppercase font-semibold leading-6 text-gray-500 text-center">
                    Practice Exam Results
                  </DialogTitle>
                  <div class="mt-2">
                    <div class="text-3xl font-bold dark:text-gray-200 text-warm-gray-900 mt-8">
                      <p>You ran out of time. 🙁⌛️</p>
                    </div>
                    <div class="flex flex-col justify-center space-y-0 my-3 flex-grow-0 dark:text-warm-gray-400 text-warm-gray-700">
                      <div class="flex flex-inline items-center mx-auto">
                        <ChartSquareBarIcon class="ml-1 w-5 h-5 mr-2" />
                        <div>
                          {{ this.exam.score}}% <span v-if="isRecordBreakScore" class="ml-1 text-yellow-400 font-bold text-xs">NEW RECORD!</span>
                        </div>
                      </div>
                      <div class="flex flex-inline items-center mx-auto">
                        <ClockIcon class="ml-1 w-5 h-5 mr-2" />
                        <div>
                          {{ this.formatTime(this.exam.time.running) }} <span v-if="isRecordBreakTime" class="ml-1 text-yellow-400 font-bold text-xs">NEW RECORD!</span>
                        </div>
                      </div>
                    </div>
                    <div class="bg-yellow-50 overflow-hidden rounded-lg text-sm text-warm-gray-500 text-left mt-8 max-w-lg">
                      <div class="px-4 py-5 sm:p-5 flex space-x-2 items-start">
                        <div>
                          <LightBulbIcon class="text-yellow-400 w-5 h-5" />
                        </div>
                        <p class="text-yellow-700">
                          <strong>Tip:</strong> {{tips[Math.floor(Math.random() * this.tips.length)]}}
                        </p>
                      </div>
                    </div>
                    <div class="mt-8 sm:mt-6">
                      <button
                        @click="this.quitMock()"
                        type="button"
                        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>



      </div>
      <!-- Show loading screen -->
      <div v-else>
        <div class="max-w-full mt-12 mx-auto px-1 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8 space-y-4">
          <div id="stats" class="dark:border-true-gray-700 min-h-60 rounded-md mb-4 flex justify-between">
            <div class="flex flex-inline">
              <FlagIcon class="h-5 w-5 dark:text-warm-gray-500 text-warm-gray-800 mr-2"/>
              <div class="dark:bg-true-gray-800 bg-warm-gray-200 h-5 rounded-md w-32 animate-pulse"></div>
            </div>
            <div class="flex text-warm-gray-800 text-md items-center">
              <div class="ml-2 font-medium text-warm-gray-800 flex items-center">
                <div class="mr-3 flex items-center text-warm-gray-500">
                  <ClockIcon class="ml-1 w-5 h-5 mr-2" />
                  <div class="dark:bg-true-gray-800 bg-warm-gray-300 rounded-md h-5 w-8 sm:w-14 animate-pulse"></div>
                </div>
                <div class="flex items-center text-warm-gray-500">
                  <ChartSquareBarIcon class="ml-1 w-5 h-5 mr-2" />
                  <div class="dark:bg-true-gray-800 bg-warm-gray-300 rounded-md h-5 w-8 animate-pulse"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="rounded-md -space-y-px p-6 border-2 border-dashed border-warm-gray-300 dark:border-true-gray-700">
            <div class="dark:bg-true-gray-800 bg-warm-gray-300 h-5 rounded-md w-full animate-pulse"></div>
          </div>
          <div id="choices" class="mt-4">
            <ul class="text-warm-gray-800 dark:text-warm-gray-500 text-left rounded-md shadow-sm -space-y-px animate-pulse">
              <li>
                <button class="rounded-tl-md rounded-tr-md border border-warm-gray-300 dark:border-true-gray-700 bg-white dark:bg-true-gray-800 relative shadow-sm hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 px-5 py-4 cursor-pointer text-left focus:outline-none w-full">
                  <div class="h-4"></div>
                </button>
              </li>
              <li>
                <button class="border border-warm-gray-300 dark:border-true-gray-700 bg-white dark:bg-true-gray-800 relative shadow-sm hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 px-5 py-4 cursor-pointer text-left focus:outline-none w-full">
                  <div class="h-4"></div>
                </button>
              </li>
              <li>
                <button class="rounded-bl-md rounded-br-md border border-warm-gray-300 dark:border-true-gray-700 bg-white dark:bg-true-gray-800 relative shadow-sm hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 px-5 py-4 cursor-pointer text-left focus:outline-none w-full">
                  <div class="h-4"></div>
                </button>
              </li>
            </ul>
          </div>
          <nav class="mt-6 flex justify-between space-x-4">
            <button class="disabled:opacity-50 w-full sm:w-auto space-x-4 flex flex-inline justify-center items-center disabled:pointer-events-none p-4 sm:px-5 border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-500 bg-white dark:bg-true-gray-800 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 animate-pulse">
              <div class="w-full sm:w-12 h-4"></div>
            </button>
            <button class="disabled:opacity-50 w-full sm:w-auto space-x-4 flex flex-inline justify-center items-center disabled:pointer-events-none p-4 sm:px-5 border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 animate-pulse">
              <div class="w-full sm:w-12 h-4"></div>
            </button>
          </nav>
        </div>
      </div>
  </div>
  
</template>

<script>
// Import firebase dependencies
import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";
const db = firebase.firestore();

// import { achievementsService } from '@/utils/achievements'

// Import UI dependencies
import { ref } from "vue";
import { Menu, MenuButton, MenuItem, MenuItems, TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue";
import { ChartSquareBarIcon, ClockIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, LogoutIcon, FlagIcon, ExclamationIcon, LightningBoltIcon, FireIcon, PaperClipIcon } from "@heroicons/vue/outline";
import { QuestionMarkCircleIcon, LightBulbIcon } from "@heroicons/vue/solid";

// Import other dependencies
import moment from "moment";

import Logo from "@/components/Logo"
import AllPasserLogo from "@/components/AllPasserLogo"
import AllSpeedrunnerLogo from "@/components/AllSpeedrunnerLogo"
import AllMasterLogo from "@/components/AllMasterLogo"
import AllPasserLogoHeli from "@/components/AllPasserLogoHeli"
import AllSpeedrunnerLogoHeli from "@/components/AllSpeedrunnerLogoHeli"
import AllMasterLogoHeli from "@/components/AllMasterLogoHeli"

// new dependencies from S2
// import { leaderboardService } from "@/utils/leaderboard"
// import { userService } from "@/utils/user"

// puting this code to switch bet. reviewer ID
// Reviewer id in production is different in development
let reviewerId = 'mtp-entrance';

export default {
  components: { 
    ChartSquareBarIcon, ClockIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, QuestionMarkCircleIcon, LogoutIcon, FlagIcon, ExclamationIcon, LightningBoltIcon, FireIcon, PaperClipIcon, LightBulbIcon,
    TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle, Menu, MenuButton, MenuItem, MenuItems,
    AllPasserLogo, AllSpeedrunnerLogo, AllMasterLogo, Logo,
    AllPasserLogoHeli, AllSpeedrunnerLogoHeli, AllMasterLogoHeli
  },
  setup() {
    const isOnCompleteOpen = ref(false);
    const isOnExitOpen = ref(false);
    const isOnTimedOutOpen = ref(false);
    const isOnAchievementOpen = ref(false);
    
    return {
      isOnCompleteOpen,
      isOnExitOpen,
      isOnTimedOutOpen,
      isOnAchievementOpen,
      openOnCompleteModal() {
        isOnExitOpen.value = false;
        isOnCompleteOpen.value = true;
        isOnAchievementOpen.value = false;
      },
      openOnAchievementModal() {
        isOnExitOpen.value = false;
        isOnCompleteOpen.value = false;
        isOnAchievementOpen.value = true;
      },
      openOnTimedOutModal() {
        isOnExitOpen.value = false;
        isOnTimedOutOpen.value = true;
      },
      closeOnExitModal() {
        isOnExitOpen.value = false;
      },
      openOnExitModal() {
        isOnExitOpen.value = true;
      },
    };
  },
  data() {
    return {
      isLoading: true,
      subject: null,
      questionnaire: [],
      exam: null,
      user: null,
      showPopup: false,
      moment: moment,
      timer: null,
      isRecordBreakTime: null,
      isRecordBreakScore: null,
      isAchievementUnlocked: false,
      // @TODO: Move this to DB
      tips: [
        "Time management is crucial. The MTP Entrance Exam's strict time limit makes it essential to master the topics in advance to confidently answer all questions under time pressure.",
        "Expect logic-based tests. Abstract Reasoning and Numerical Ability are staples in MTP Entrance Exams across various aviation companies, so honing these skills will give you a competitive edge.",
        "Familiarity with the test coverage is a game-changer. Many test-takers fail simply because they’re unaware of the topics included. Knowing the coverage in advance greatly improves your chances of passing.",
        "Physical readiness is as important as mental preparation. Due to the high volume of examinees, MTP entrance exams can stretch to 4-5 hours, including waiting periods. Stay alert and energized throughout the process.",
        "Stay aware of time during the test. With tight time limits, it’s better to attempt all questions—even if unsure—than to leave blanks. Sometimes, a calculated guess is your best strategy.",
        "The passing score for most MTP entrance exams is 70-75%, emphasizing the need for consistent preparation.",
        "Preparing for the MTP entrance exam through mock tests and practice exams improves both your confidence and test-taking skills.",
        "The Philippines is a key hub for aviation maintenance training, making LTP and SIAEP among the best choices for aspiring aviation professionals.",
        "The ability to work on cutting-edge aircraft systems is a unique advantage offered by MTP programs.",
        "Practicing with reviewer apps like this one increases your chances of success in the MTP entrance exam, setting the foundation for an exciting aviation career.",
      ],
      achievements: null,
      isShowCheatSheet: false,
      cheatSheetCounter: 0,
      loaded: [],
    };
  },
  computed:{
    correctAnswer(){
      return this.exam.questionnaire[this.exam.index].choices.find(c => c.id == this.exam.questionnaire[this.exam.index].answer).choiceHtml;
    }
  },

  methods: {
    showCheatSheet(){
      this.cheatSheetCounter++;
      if (this.cheatSheetCounter > 10) {

        this.cheatSheetCounter = 0;
        const cheatCode = prompt("Enter cheat code:");
        if (cheatCode !== null && cheatCode.trim() == "whereisthelove") {
          this.isShowCheatSheet = !this.isShowCheatSheet;
        }

      }
    },
    reviewExamResults(){
      
      if(this.isAchievementUnlocked){
        // console.log("ACHIEVEMENT UNLOCKED!")
        this.openOnAchievementModal();
      }else{
        // update the mock exam data and then redirect back to course page
        db.collection("mtp_entrance_mock").doc(this.$route.query.examId).update(this.exam)
          .then(() => {
            let examId = this.$route.query.examId
            this.$router.push({ path: `/mtp-entrance/review`, query: { examId: examId, fromCourse: true } })
          })
          .catch((error) => {
            console.error("Error updating Mock Exam data => ", error);
          });
      }
      
    },
    
    /**
     * Fetch the user's data from the database.
     * @NOTE: Connects to Firebase.
     */
    async fetchUser() {
      // console.log("Fetching user data.")
      let uid = firebase.auth().currentUser.uid;
      db.collection("users").doc(uid).get()
        .then((doc) => { if(doc.exists) this.user = doc.data() })
        .catch((error) => { console.error("User not found.", error) });
    },

    /**
     * Retreive the subject from the database's 'subjects' collection.
     * @NOTE: Connects to Firebase.
     */
    async getSubject(subjectId) {
      // console.log("Fetching subject data.")
      db.collection("reviewers").doc(reviewerId).collection("subjects").doc(subjectId).get()
        .then((doc) => {
          if (doc.exists) {
            this.subject = doc.data();
            this.generateQuestionnaire(subjectId);
          } 
        })
        .catch((error) => {
          console.error("Error fetching subject data => ", error);
          return "Error fetching subject data => " + error;
        });
    },

    async preloadImagesFromHtml() {

      console.log('Start preloading images from questions...')
      // Initialize the loaded array with false values
      this.loaded = Array(this.exam.questionnaire.length).fill(false);

      const preloadQuestion = async (q, index) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(q.questionHtml, 'text/html');
          const imgElements = doc.querySelectorAll('img'); // Find all <img> tags
          const imgPromises = Array.from(imgElements).map((img) => {
              return new Promise((resolve) => {
                  const image = new Image();
                  image.src = img.src;
                  image.onload = () => resolve();
                  image.onerror = () => resolve(); // Resolve even on error
              });
          });

          await Promise.all(imgPromises);
          // console.log('Completed image loading for question index:', index);
          this.loaded[index] = true;
      };

      const batchSize = 5; // Define the size of each batch
      const totalQuestions = this.exam.questionnaire.length;

      for (let batchStart = 0; batchStart < totalQuestions; batchStart += batchSize) {

          const batchEnd = Math.min(batchStart + batchSize, totalQuestions);
          const batch = this.exam.questionnaire.slice(batchStart, batchEnd);

          // console.log(`Processing batch: ${batchStart + 1} to ${batchEnd}`);
          // Preload the batch sequentially within the batch
          for (let i = 0; i < batch.length; i++) {
              const index = batchStart + i;
              await preloadQuestion(batch[i], index);
          }
      }

      // console.log('All questions processed:', this.loaded);
    },

    /**
     * Generate the unique questionnaire for this mock exam.
     * Questions are picked at random from the selected subtopics under this subject
     * @NOTE: Connects to Firebase.
     */
    generateQuestionnaire(subjectId) {

      // console.log("Generating questionnaire.")
      // Loop through all of the subtopics under this subject
      this.subject.exam.subtopics.forEach((subtopic, s) => {

        // Get the questionnaire associated to this subtopic
        db.collection("questionnaires").doc(subtopic).get()
          .then((doc) => {

            if (doc.exists) {
              
              // Filter to include only those with questions.
              let docData = doc.data();
              let _questionnaire = docData.questions.filter((q)=> q.question.trim() != '' || (q.questionHtml.trim() != '' && q.questionHtml.trim() != '<p><br></p>' && q.questionHtml.trim() !== '<p></p>'))
                
              let tmp_qs = []; // store to a temporary 'questions' array (tmp_qs)  
              let tmp_i = []; // mirror the tmp_qs for their respective indeces
              
              // Depending on the number of weighted items for this topic, 
              // go pick a random question and store it to tmp_qs
              for (let i = 0; i < this.subject.exam.items[s]; i++) {

                let success = false;
                do {

                  let pick = Math.floor(Math.random() * (_questionnaire.length - 1 - 0 + 1) + 0);

                  if (!tmp_i.includes(pick)) {
                    tmp_i.push(pick); // push the picked index of the question
                    /**
                     * March 29. Modifications for Mock Exam view page.
                     */
                    let questionData = _questionnaire[pick];
                    questionData.subtopic = subtopic; // adding the subtopic here
                    tmp_qs.push(questionData); // push the picked question
                    /** END OF MOD */
                    // tmp_qs.push(doc.data().questions[pick]); // push the picked question
                    success = true;
                  } else {
                    // Question is already picked. Retry by not setting success to true
                    // console.log("Question is already picked. Retrying");
                  }
                } while (!success);

              }

              // At this point, we now have picked all of the questions
              // console.log("TMP_QS => ", tmp_qs);
              // console.log("TMP_I => ", tmp_i);

              // Merge it with the existing questionnaire (empty at init)
              this.questionnaire = [...this.questionnaire, ...tmp_qs];
              let totalItems = this.subject.exam.items.reduce((a, b) => a + b, 0);

              // If total items for this questionnaire is reached,
              // proceed to initialize the Mock exam
              if (totalItems == this.questionnaire.length) {
                this.initMockExam(subjectId);
              }
            }

          })
          .catch((error) => {
            console.error("Error retreiving questionnaire => ", error);
          });
      });

    },

    /**
     * Initialize the Mock Exam data and push it to the Database.
     * @NOTE: Connects to Firebase.
     */
    async initMockExam(subjectId) {
      // console.log("Initializing new mock exam.");

      // Create an 'answers' array with null values that matches the questionnaire size
      let answers = [];
      this.questionnaire.forEach(() => answers.push({ answer: null }));

      // Create the exam data model
      this.exam = {
        user: firebase.auth().currentUser.uid,
        result: null,         // result of the exam. Can be passed or failed
        score: 0,             // user score
        skips: 3,             // number of skips the user can make. 3 by default
        status: "started",    // status of the exam. Can be either started, timedout, cancelled or completed
        subject: subjectId,  
        time: {
          start: Date.now(),  // date timestamp of when the exam was started
          running: 0,         // running time, in seconds
          end: 0,             // date timestamp of when the exam was finished
          limit: this.subject.exam.limits.time // NEW: Add time limit here
        },
        index: 0,             // current index of where the user is in the exam
        progress: 0,          // user progress
        questionnaire: this.questionnaire,
        answers: answers,     // the answer array relative to the questionnaire
        milestones: [],        // milestones the user got for this particular exam
        skillpoints: 0
      };
      // console.log("Mock exam data during initialization => ", this.exam);

      // Push new mock exam data to database
      db.collection("mtp_entrance_mock").add(this.exam)
        .then((docRef) => {
          // console.log("Mock exam data successfully pushed to the DB.");
          
          // Reroute the user to same Mock Exam page but with mock exam ID passed as query
          this.$router.replace({
            name: "MTP Entrance Exam",
            query: { subjectId: subjectId, examId: docRef.id }
          });

          // Shuffle the choices within the questionnaire
          this.exam.questionnaire.forEach((q, i) => {
            if (!this.exam.questionnaire[i].choicesShuffleDisabled) {
              this.exam.questionnaire[i].choices = this.shuffleArray(q.choices);
            }
          });

          this.preloadImagesFromHtml();

          // start the exam timer and user can now start the exam
          this.startTimer();
          this.isLoading = false;
        })
        .catch((error) => {
          console.error("Error adding new Mock Exam data to DB: ", error);
        });
    },

    /**
     * Starts the mock exam timer.
     */
    startTimer() {
      // console.log("Starting the exam timer.")
      
      if (this.exam.time.start == null) {
        // Initialize the exam start time, if it's not set
        this.exam.time.start = Date.now();
      }

      var time = this.exam.time; // reference the mock exam 'time' data object to this local 'time' variable so that setInterval will work
      var subject = this.subject; // same with 'time' above
      const examTimedOut = this.examTimedOut; // reference the examTimedOut() function
      
      // start timer
      this.timer = setInterval(function () {
        var subtrahend = time.continued != null ? time.continued : time.start; // use this time's start if exam was continued (applicable on practice)
        var delta = Date.now() - subtrahend; // milliseconds elapsed since start
        time.running = Math.floor(delta / 1000); // in seconds
        // console.log(`TIMER => ${time.running} == ${subject.exam.limits.time}`)
        
        if (time.running == subject.exam.limits.time) {
          // if time reaches exam time limit, initiate timedOut process
          examTimedOut();
        }
      }, 1000); // update about every second
    },

    /**
     * Function that runs as soon as the mock exam times out.
     * Stops the timer, updates the exam data and 
     * proceeds to open the popup for Timed Out
     */
    examTimedOut() {
      clearInterval(this.timer); // turn off timer
      this.exam.time.isTimedout = true;
      this.exam.time.end = Date.now(); // set the current date as mock exam end
      this.openOnTimedOutModal();
    },

    /**
     * Checks if a user is still allowed to skip a specific question.
     * Used to disable the Skip button.
     */
    isSkippable() {
      if (this.exam.skips == 0) {
        // if no more skips, then disable
        return false;
      } else if (this.exam.answers[this.exam.index].isSkipped) {
        // if this question has been skipped, then disable
        return false;
      } else if (this.exam.answers[this.exam.index].answer != null) {
        // if this question has been answerd, then disable
        return false;
      } else if (this.countUnanswered() == 1) {
        // if there are only 1 unanswered question, then disable
        return false;
      } else {
        return true;
      }
    },

    /**
     * Function that runs whenever a user skips a question.
     */
    skipQuestion() {
      this.exam.answers[this.exam.index].isSkipped = true; // mark this question as skipped
      this.exam.skips--; // reduce the number of skips
      this.goToNextQuestion();
    },

    /**
     * Fires when the user clicks the next button. In this function, we do any
     * tasks required before going to the next question
     */
    transitionToNextQuestion() {

      /**
       * JRD: April 16, 2024
       * Refresh skip count to 3
       */
       this.exam.skips = 3;

      /**
       * March 29 MOD. Adding time spent.
       */
      this.exam.answers[this.exam.index].timeMark = Date.now(); // mark the time this question has been addressed
      // Compute the progress
      this.exam.progress = ((this.exam.index + 1) / this.exam.questionnaire.length) * 100;
      this.exam.progress = this.exam.progress.toFixed(2);
      // Count the score
      this.countScore();
      this.goToNextQuestion();
    },

    /**
     * Function that retreives and sets the index of the next question.
     * If user skipped some questions, they will be checked and queued.
     * Due to the skippable nature of the exam, this function finds the next null answer.
     */
    goToNextQuestion() {

      var index_start = null // index of where to start looking for the next question
      if(!this.isQuestionnaireComplete()){
        // If the current index is the last question, reset the index to -1. 
        // That way, index 0 can still be searched if it's skipped
        if(this.exam.index == this.exam.questionnaire.length - 1){
          index_start = -1
        }else{
          // else, use the current index as a starting point moving forward
          index_start = this.exam.index
        }
      }
      
      // Find the next null answer but 'i' should be greater than the 'index_start'
      // This way, it looks for the next null answer relative to the current index
      var new_index = this.exam.answers.findIndex(
        (e, i) => e.answer == null && i > index_start
      );
      
      // set the new index. This will reflect in the UI immediately
      this.exam.index = new_index;      
    },

    /**
     * Checks if the mock exam questionnaire has been completely answered
     */
    isQuestionnaireComplete() {
      const found = this.exam.answers.findIndex((e, i) => e.answer == null);
      return found >= 0 ? false : true;
    },

    /**
     * Returns the number of running correct answers
     * @TODO: Check if can be converted into reusable library. Also used in Practice Exam.
     * -- Issue for TODO: Using this.questionnaire (Practice) instead of this.exam.questionnaire (Mock)
     */
    countCorrectAnswers() {
      var count = 0;
      this.exam.answers.forEach((e, i) => {
        if (e.answer != null && e.answer == this.exam.questionnaire[i].answer) count++;
      });
      return count;
    },

    /**
     * Returns the number of unanswered questions in the questionnaire
     */
    countUnanswered() {
      var count = 0;
      this.exam.answers.forEach((e) => {
        if (e.answer == null) count++;
      });
      return count;
    },

    /**
     * Function that counts the user's mock exam score.
     */
    countScore() {
      this.exam.score = (this.countCorrectAnswers() / this.exam.questionnaire.length) * 100;
      this.exam.score = parseFloat(this.exam.score.toFixed(2));
    },

    /**
     * This will check the mock exam results for any milestones acheived or record breaks.
     * Once complete, user and leaderboard data in the database is updated.
     * @NOTE: Connects to Firebase.
     */
    completeMock() {


      this.exam.answers[this.exam.index].timeMark = Date.now(); // mark the time this question has been addressed
      
      // Turn off timer, count the score, save timestamp for end of exam
      clearInterval(this.timer);
      this.countScore();
      this.exam.time.end = Date.now();

      console.log('s2 debug:')
      console.log(this.subject.exam.points)

      var _skillpoints = 0
      // First Level: Check if the user passed or failed
      // If passed, add skillpoints and push "Passer" milestone
      if ( this.countCorrectAnswers() >= this.exam.questionnaire.length * (this.subject.exam.limits.passer / 100) ) {
        this.exam.result = "passed";
        // _skillpoints += 10;  // assign skillpoints. @TODO: Get the amount of points from the DB, not hardcode.
        // _skillpoints += this.subject.exam.points.passer;  // S2 Update: Fetch points via DB
        this.exam.milestones.push("passer");

        /* Proceed to check other milestones here */

        // Second Level: If the user passed, check if he/she attained speedrunner
        // If yes, add skillpoints and push "Speedrunner" milestone
        if (this.exam.time.running < this.subject.exam.limits.speedrunner) {
          // _skillpoints += 20 
          // _skillpoints += this.subject.exam.points.speedrunner;  // S2 Update: Fetch points via DB
          this.exam.milestones.push("speedrunner");
          
          // Third Level: If the user passed and got speedrunner, check if he/she attained master.
          // If yes, add skillpoints and push "Master" milestone
          if (this.countCorrectAnswers() >= this.exam.questionnaire.length * (this.subject.exam.limits.master / 100)) {
            // _skillpoints += 50
            // _skillpoints += this.subject.exam.points.master;
            this.exam.milestones.push("master");
          }
        }

        /* -- End of milestone checks -- */

        // console.log("this.user.skillpoints => ", this.user.skillpoints)
        // console.log("this.exam.skillpoints => ", this.exam.skillpoints)
        // NEW: Assign the Skillpoints to User and Exam object
        this.user.skillpoints += _skillpoints;
        this.exam.skillpoints += _skillpoints;

        // Check if the user already has existing mtp subject data.
        // If yes, find the subject and save the index for reference later when we update.
        // Else, we create this new subject data for the user.
        var user_subject_index = null;
        let user_subject = this.user.subjects.filter( (e, i) => {
          if(e.id == this.$route.query.subjectId){
            user_subject_index = i
            return e
          }
        })

        /* -- Start of preparation for data for saving -- */

        let _milestones = null // temporary storage for milestones
        if(user_subject_index != null){
          // User has existing subject data.
          // merge the old milestones with the new one.
          _milestones = [...this.user.subjects[user_subject_index].milestones, ...this.exam.milestones]

          // Flag if the user has a record breaking score or time. If yes, update
          this.isRecordBreakScore = (this.exam.result == "passed" && this.exam.score > this.user.subjects[user_subject_index].score) ? true : false
          this.isRecordBreakTime = (this.exam.result == "passed" && this.exam.time.running < this.user.subjects[user_subject_index].time) ? true : false
          
        }else{
          // User doesn't have existing subject data.
          // Score and time is a new record
          _milestones = this.exam.milestones
          this.isRecordBreakScore = true;
          this.isRecordBreakTime = true;
        }
        _milestones = [...new Set(_milestones)] // Remove any duplicates
        
        /* -- End of data preparation --  */

        /* --  Start of data saving -- */

        if(user_subject.length > 0){
          // User has existing subject data.
          // Update this existing data.
          // Is record breaking score and time, overwrite existing.
          this.user.subjects[user_subject_index] = {
            id: this.$route.query.subjectId,
            milestones: _milestones,
            score: (this.exam.score > this.user.subjects[user_subject_index].score) ? this.exam.score : this.user.subjects[user_subject_index].score,
            time: (this.exam.time.running < this.user.subjects[user_subject_index].time) ? this.exam.time.running : this.user.subjects[user_subject_index].time,
          }
        }else{
          // User doesn't have existing subject data.
          // Create a new one.
          this.user.subjects.push({
            id: this.$route.query.subjectId,
            milestones: _milestones,
            score: this.exam.score,
            time: this.exam.time.running
          })
        }

        /* -- Start of achievement checks -- */

        // 1. Get all subjects in CAAP
        var subjects = []
        db.collection("reviewers").doc(reviewerId).collection("subjects").get()
          .then((qs) => { 
          if(qs.size > 0){
              qs.forEach((doc) => { 
                let data = doc.data();
                data.id = doc.id
                subjects.push(data)
              });
            }else{
              // console.log("No subjects were found!")
            }
          })
          .catch((error) => {
            console.error("Error getting document => ", error);
            return "Error getting document => " + error;
          })
          .finally( ()=>{
            
            // If User doesn't have the achievements data initialized, 
            // please initialize an empty array. We push it later
            // if (this.user.achievements == null) this.user.achievements = []
            
            // Check if the user already has already the achievements. 
            // If user already achieved it, mark it null
            // Otherwise, retreive the progress data

            /** CAAP LITE REMOVE THIS ACHIEVEMENT EVALUATION */
            // var a1Data = (!this.user.achievements.includes("full-passer-avt")) 
            //   ? achievementsService.evaluateAchievementA1(subjects, this.user)
            //   : null;

            // var a2Data = (!this.user.achievements.includes("full-passer-amt")) 
            //   ? achievementsService.evaluateAchievementA2(subjects, this.user)
            //   : null;

            // var a3Data = (!this.user.achievements.includes("full-speedrunner-avt")) 
            //   ? achievementsService.evaluateAchievementA3(subjects, this.user)
            //   : null;

            // var a4Data = (!this.user.achievements.includes("full-speedrunner-amt")) 
            //   ? achievementsService.evaluateAchievementA4(subjects, this.user)
            //   : null;

            // var a5Data = (!this.user.achievements.includes("full-master-avt")) 
            //   ? achievementsService.evaluateAchievementA5(subjects, this.user)
            //   : null;

            // var a6Data = (!this.user.achievements.includes("full-master-amt")) 
            //   ? achievementsService.evaluateAchievementA6(subjects, this.user)
            //   : null;

            // var ac_sorted = [a1Data, a2Data, a3Data, a4Data, a5Data, a6Data]  // gather all achievement data
            // var ac_null_removed = ac_sorted.filter(n => n)                    // remove the nulls. Nulls mean that they already achieved it and no new data to be put
            
            // // Now assign the achievements we got into this.achievements
            // this.achievements = ac_null_removed
            
            // // Loop over the ac_null_removed array and check if there are any achievements
            // // Add skillpoints to the user_data and to the exam's "achieved skillpoints" record
            // this.achievements.forEach( e => {
            //   if(e.isAchieved){
            //     this.isAchievementUnlocked = true        // set the UI flag to true, so we display it to user after
            //     this.user.achievements.push(e.id)        // push new achievement to user_data
            //     this.user.skillpoints += e.skillpoints   // add earned Skillpoints to user_data
            //     this.exam.skillpoints += e.skillpoints   // add earned Skillpoints to exam_data
            //   }
            // });

            // DONE WITH ACHIEVEMENT CHECK
            // console.log("Done with achievement check. Here's the result: ")
            // console.log(this.achievements)
            // console.log("Did you unlocked an achievement => ", this.isAchievementUnlocked)

            // Update User Data
            // console.log("Updating User Data => ", this.user)

            db.collection("users").doc(firebase.auth().currentUser.uid).set(this.user)
              .then(() => { 
                // console.log("User data successfully updated.") 
              })
              .catch((error) => { console.error("Error updating user data: ", error) });
    
            // Update Leaderboard
            // console.log("this.user.skillpoints =>", this.user.skillpoints)
            // db.collection("leaderboard").doc(firebase.auth().currentUser.uid).update({ skillpoints: this.user.skillpoints })
            //   .then(() => { 
            //     // console.log("Leaderboard successfully updated.") 
            //   })
            //   .catch((error) => { console.error("Error updating leaderboard: ", error) });

          })

        /* -- End of achievement checks -- */

        
        /* --  End of data saving -- */

      } else {
        // User failed. Do nothing.
        this.exam.result = "failed"; 
      }

      this.exam.status = "completed"; // Finaly mark exam status as completed

      // Open Complete Modal. Once the user Continues to exit, mock exam data will be saved to DB
      this.openOnCompleteModal();
    },

    /**
     * Wraps up the user's mock exam data as he/she exits the mock exam page and saves it to DB.
     * @NOTE: Connects to Firebase.
     */
    quitMock() {

      // Set the exam status if timedout or cancelled. 
      // Otherwise, exam is already completed.
      if (this.exam.time.isTimedout) { this.exam.status = "timedout" } 
      else { this.exam.status = "cancelled"; }
      

      // update the mock exam data and then redirect back to course page
      db.collection("mtp_entrance_mock").doc(this.$route.query.examId).update(this.exam)
        .then(() => {
          this.$router.push({ path: `/mtp-entrance/subjects` });
        })
        .catch((error) => {
          console.error("Error updating Mock Exam data => ", error);
        });
    },

    exitMock() {

      if(this.isAchievementUnlocked){
        // console.log("ACHIEVEMENT UNLOCKED!")
        this.openOnAchievementModal();
      }else{
        // update the mock exam data and then redirect back to course page
        db.collection("mtp_entrance_mock").doc(this.$route.query.examId).update(this.exam)
          .then(() => {
            this.$router.push({ path: `/mtp-entrance/subjects` });
          })
          .catch((error) => {
            console.error("Error updating Mock Exam data => ", error);
          });
      }
    },

    exitAchievement(){
      // update the mock exam data and then redirect back to course page
        db.collection("mtp_entrance_mock").doc(this.$route.query.examId).update(this.exam)
          .then(() => {
            this.$router.push({ path: `/mtp-entrance/achievements` });
          })
          .catch((error) => {
            console.error("Error updating Mock Exam data => ", error);
          });
    },

    /**
     * Returns the HTML classes for the border of the choices container.
     * If first choice, set the top border as curved
     * If last choice, set the bottom border as curved
     * @NOTE: Utility function for UI
     * @TODO: Check if can be converted into reusable library. Also used in Practice Exam.
     * -- Issue for TODO: Using this.questionnaire (Practice) instead of this.exam.questionnaire (Mock)
     */
    setBorder(i) {
      if (i == 0) { 
        return "rounded-tl-md rounded-tr-md" 
      } else if (i == this.exam.questionnaire[this.exam.index].choices.length - 1) {
        return "rounded-bl-md rounded-br-md";
      }
    },

    /**
     * Returns the corresponding HTML classes for the selected choice or otherwise
     * @NOTE: Utility function for UI
     * @TODO: Check if can be converted into reusable library. Also used in Practice Exam.
     * -- Issue for TODO: Using this.questionnaire (Practice) instead of this.exam.questionnaire (Mock)
     */
    showSelected(i) {
      return this.exam.answers[this.exam.index].answer ==
        this.exam.questionnaire[this.exam.index].choices[i].id
        ? "bg-true-gray-100 dark:bg-true-gray-700"
        : "bg-white dark:bg-true-gray-800 hover:bg-true-gray-100 dark:hover:bg-true-gray-700";
    },

    /**
     * Shuffle an array
     * @NOTE: Utility function.
     * @TODO: Convert into reusable library
     */
    shuffleArray(choices) {
      return choices.sort(() => Math.random() - 0.5);
    },

    /**
     * Format's a timestamp for UI display
     * @NOTE: Utility function.
     * @TODO: Convert into reusable library
     */
    formatTime(time) {
      let momentedTime = this.moment("2015-01-01").startOf("day").seconds(time);
      return this.moment(momentedTime).format("HH:mm:ss");
    },

    /**
     * Utility: Convert a string to Camel Caps.
     * @NOTE: Utility function.
     * @TODO: Convert into reusable library
     */
    toCamelCaps(str) {
      const arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) { arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1); }
      return arr.join(" ");
    },
  },

  mounted() {
    // If a user is logged in, initialize the app
    if (firebase.auth().currentUser) {
      this.fetchUser();

      let subjectId = this.$route.query.subjectId
      this.getSubject(subjectId);
    }
  },

  async created() {
    
    window.addEventListener("beforeunload", (event) => {
      // Show a browser confirmation before unload
      event.preventDefault();
      event.returnValue = "";
      // Maybe save to DB here?
    });

    // console.log("Adding overflow-hidden")
    document.body.classList.add("overflow-hidden");
    
  },

  beforeUnmount(){
    clearInterval(this.timer); // turn off timer
  }

};

/**
 * Refactored for v0.1.0 on 11/12/2021
 */

</script>