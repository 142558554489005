<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            This section will cover effective, concise notes to help you quickly familiarize yourself with The Dirty
            Dozen in Aviation.
        </p>

        <h3>💡 The Dirty Dozen in Aviation</h3>
        <section>
            <p>Here’s a quick refresher on the Dirty Dozen human factors that contribute to errors in aviation
                maintenance:</p>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Lack of Communication:</strong> Misunderstanding between team members due to incomplete or
                    unclear instructions.
                </li>
                <li>
                    <strong>Complacency:</strong> The assumption that because things have gone well before, they will
                    continue to go well without double-checking.
                </li>
                <li>
                    <strong>Lack of Knowledge:</strong> Not having enough information or training to properly complete a
                    task.
                </li>
                <li>
                    <strong>Distraction:</strong> Interruptions or unrelated tasks that lead to forgetting critical
                    steps.
                </li>
                <li>
                    <strong>Lack of Teamwork:</strong> Poor coordination among team members, leading to missed tasks or
                    conflicting actions.
                </li>
                <li>
                    <strong>Fatigue:</strong> Being physically or mentally tired, resulting in decreased performance and
                    attention.
                </li>
                <li>
                    <strong>Lack of Resources:</strong> Not having the necessary tools, parts, or information to
                    complete a task properly.
                </li>
                <li>
                    <strong>Pressure:</strong> Feeling rushed to complete a task quickly, often compromising safety or
                    quality.
                </li>
                <li>
                    <strong>Lack of Assertiveness:</strong> Not speaking up when you notice something is wrong or when
                    you are unsure about the task.
                </li>
                <li>
                    <strong>Stress:</strong> High levels of stress, either from personal or work-related issues,
                    affecting concentration and decision-making.
                </li>
                <li>
                    <strong>Lack of Awareness:</strong> Failing to see or recognize an issue because of inattention or
                    lack of focus.
                </li>
                <li>
                    <strong>Norms:</strong> Doing things the way they’ve always been done, even when the method may not
                    be the safest or most effective.
                </li>
            </ul>
            <p>
                Understanding and recognizing these factors in the workplace is essential for preventing errors and
                maintaining a high standard of safety.
            </p>
        </section>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ul {
    @apply space-y-1.5;
}

p {
    @apply text-sm;
}
</style>
