<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            Preparing for math exams can feel overwhelming, but with the right strategies, you can tackle the content
            confidently. Whether you're working on algebra problems, unit conversions, or ratio and proportion,
            following some simple, yet effective, study techniques will help you get the best results.
        </p>

        <h3>💡 1. College Algebra Study Tips</h3>
        <section>
            <p>College Algebra can seem tricky, but it’s all about understanding the basics and applying them step by
                step. Here’s how to make the most of your study time:</p>
            <ul class="list-disc pl-4">
                <li class="pb-4">
                    <strong>Master the Fundamentals:</strong> Don’t just memorize formulas—understand why they work. For
                    example, when factoring equations like <b>x² - 9</b>, remember that it’s simply applying the difference of
                    squares formula:
                    <p class="text-lg text-center my-6">(x−3)(x+3)</p>
                    Practice breaking down these formulas until they feel natural.
                </li>
                <li class="pb-4">
                    <strong>Work Through Example Problems:</strong> Go through each problem step by step, especially
                    ones that involve solving for x. For instance for a problem like <b>2x + 5 = 15</b>, it helps to:
                    <ul class="list-disc pl-6 my-4">
                        <li>Subtract 5 from both sides: <b>2x = 10</b></li>
                        <li>Then divide by 2: <b>x = 5</b></li>
                    </ul>
                    By going through these types of problems slowly, you reinforce the process in your mind.
                </li>
                <li>
                    <strong>Understand Graphing:</strong> Visualizing equations can really help solidify concepts. Try
                    graphing lines, especially when dealing with slope-intercept form <b>y = mx + b</b>. Knowing how to plot
                    and read graphs makes questions about functions easier to answer.
                </li>
            </ul>
        </section>

        <h3>💡 2. Conversion of Units Study Tips</h3>
        <section>
            <p>Unit conversions are more about memorization and applying simple ratios. But with a few tips, you can make this process easier:</p>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Memorize the Key Conversions:</strong> The first step to mastering unit conversions is knowing the most common ones. For instance:
                    <ul class="list-disc pl-6 my-4">
                        <li>1 foot = 12 inches</li>
                        <li>1 inch = 2.54 cm</li>
                        <li>1 yard = 3 feet</li>
                        <li>1 mile = 5280 feet</li>
                        <li>1 centimeter = 10 millimeters</li>
                        <li>1 meter = 100 centimeters</li>
                        <li>1 kilometer = 1000 meters</li>
                        <li>1 kilogram = 1000 grams</li>
                        <li>1 pound = 16 ounces</li>
                        <li>1 kilogram = 2.20462 pounds</li>
                        <li>1 liter = 1000 milliliters</li>
                        <li>1 gallon = 3.78541 liters</li>
                    </ul>
                </li>
                <li class="pb-4">
                    <strong>Use Real-World Examples:</strong> Make conversions more practical by relating them to
                    everyday situations. If you know your height is 6 feet, then you can practice converting that to
                    inches (72 inches) or centimeters (about 183 cm). Similarly, when baking, converting cups to
                    milliliters (1 cup = 240 mL) is a useful way to practice volume conversions.
                </li>
                <li>
                    <strong>Set Up Conversion Ratios:</strong> Always write down the conversion factor as a ratio to
                    avoid mistakes. For example, to convert 2 feet to inches (where 1 foot = 12 inches):
                    <ul class="my-4">
                        <li>= 2 feet × (12 inches / 1 foot)</li>
                        <li>= 2 <strike>feet</strike> × (12 inches / 1 <strike>foot</strike> )</li>
                        <li>= 2 x 12 inches</li>
                        <li>= 24 inches</li>
                    </ul>
                </li>
            </ul>
        </section>

        <h3>💡 3. Ratio and Proportion Study Tips</h3>
        <section>
            <p>Ratios and proportions are all about relationships between numbers. With a few tips, you’ll find these
                types of questions straightforward:</p>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Understand the Ratio Relationship:</strong> Think of ratios as fractions. For example, the
                    ratio 3:4 (3 is to 4) is simply 3/4. Knowing this, you can easily solve proportion problems like:
                    <div class=" my-4">
                        <p class="font-semibold mb-2">If 6 is to 18 as x is to 3, what is x?</p>
                        <p class="mb-2">6/18 = x/3</p>
                        <p class="mb-2 font-semibold">Cross-multiply to get: </p>
                        <p class="mb-2">18x = 18</p>
                        <p class="mb-2">x = 1</p>
                    </div>

                </li>
                <li class=" pb-4">
                    <strong>Simplify Ratios:</strong> Whenever possible, simplify ratios to make calculations easier. If
                    the ratio is 5:15, simplify it to 1:3. This cuts down on unnecessary computation during the exam.
                </li>
                <li>
                    <strong>Practice with Everyday Situations:</strong> Ratios appear in everyday life, from recipes to
                    maps. For example, if a recipe calls for 2 cups of flour to 1 cup of sugar, you can practice scaling
                    it up or down. If you want to double the recipe, you now need 4 cups of flour to 2 cups of sugar,
                    maintaining the 2:1 ratio.
                </li>
            </ul>
        </section>

        <p class="mt-6">
            With these strategies, you’ll be able to tackle your <b>College Algebra, Conversion of Units</b>, and <b>Ratio and
                Proportion</b> exams with confidence. Just remember: math is a skill, and like any skill, it gets better with
            practice!
        </p>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ul {
    @apply space-y-1.5;
}

p {
    @apply text-sm;
}
</style>