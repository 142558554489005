<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            Mastering problem-solving in math requires understanding the problem, breaking it into smaller steps, and
            applying logical reasoning. Below, we'll demonstrate how to tackle three problem-solving examples step by
            step.
        </p>

        <h3>💡 Tip #1: Break Down the Ratios</h3>
        <section>
            <p>
                <strong>Problem:</strong> If 123 miles uses 60 gallons, how much fuel will be used to travel 246 miles?
            </p>
            <p>
                <strong>Solution:</strong>
            </p>
            <ol class="list-decimal pl-4">
                <li>
                    <strong>Understand the problem:</strong>
                    <ul class=" list-disc p-4">
                        <li>For every 123 miles, the car uses 60 gallons of fuel.</li>
                        <li>We need to find how much fuel is required for 246 miles.</li>
                    </ul> 
                </li>
                <li>
                    <strong>Set up the proportion:</strong>
                    <div class=" py-4 w-full lg:w-80">
                        <img src="../../assets/images/problem-solving-tips/image-1.png" alt="">
                    </div>
                </li>
                <li>
                    <strong>Cross multiply:</strong>
                    <div class=" py-4 w-full lg:w-80">
                        <img src="../../assets/images/problem-solving-tips/image-2.png" alt="">
                    </div>
                </li>
                <li>
                    <strong>Simplify:</strong>
                    <div class=" py-4 w-full lg:w-80">
                        <img src="../../assets/images/problem-solving-tips/image-3.png" alt="">
                    </div>
                </li>
                <li>
                    <strong>Solve for x:</strong>
                    <div class=" py-4 w-full lg:w-80">
                        <img src="../../assets/images/problem-solving-tips/image-4.png" alt="">
                    </div>
                </li>
            </ol>
            <p>
                <strong>Answer:</strong> The car will need <b>120 gallons</b> of fuel to travel 246 miles.
            </p>
        </section>

        <h3>💡 Tip #2: Use Unit Rates</h3>
        <section>
            <p>
                <strong>Problem:</strong>
                A car travels 150 miles on 12 gallons of fuel. If the car’s fuel efficiency
                remains the same, how many gallons will it need to travel 375 miles?
            </p>
            <p>
                <strong>Solution:</strong>
            </p>
            <ol class="list-decimal pl-4">
                <li>
                    <strong>Understand the problem:</strong>
                    <ul class=" list-disc p-4">
                        <li>The car travels 150 miles on 12 gallons of fuel.</li>
                        <li>We need to find how much fuel is required for 375 miles.</li>
                    </ul> 
                </li>
                <li>
                    <strong>Find the unit rate (miles per gallon):</strong>
                    <div class=" py-4 w-full lg:w-80">
                        <img src="../../assets/images/problem-solving-tips/image-5.png" alt="">
                    </div>
                </li>
                <li>
                    <strong>Calculate the gallons for 375 miles:</strong>
                    <div class=" py-4 w-full lg:w-80">
                        <img src="../../assets/images/problem-solving-tips/image-6.png" alt="">
                    </div>
                </li>
                <li>
                    <strong>Simplify:</strong>
                    <div class=" py-4 w-full lg:w-80">
                        <img src="../../assets/images/problem-solving-tips/image-7.png" alt="">
                    </div>
                </li>
            </ol>
            <p>
                <strong>Answer:</strong> The car will need <b>30 gallons</b> of fuel for 375 miles.
            </p>
        </section>

        <h3>💡 Tip #3: Think About Proportional Time</h3>
        <section>
            <p>
                <strong>Problem:</strong> A train travels 240 kilometers in 3 hours. If it continues at the same speed,
                how long will it take to travel 1,000 kilometers?
            </p>
            <p>
                <strong>Solution:</strong>
            </p>
            <ol class="list-decimal pl-4">
                <li>
                    <strong>Understand the problem:</strong>
                    <ul class=" list-disc p-4">
                        <li>The train travels 240 kilometers in 3 hours.</li>
                        <li>We need to find the time it will take to travel 1,000 kilometers.</li>
                    </ul> 
                </li>
                <li>
                    <strong>Find the speed (kilometers per hour):</strong>
                    <div class=" py-4 w-full lg:w-80">
                        <img src="../../assets/images/problem-solving-tips/image-8.png" alt="">
                    </div>
                </li>
                <li>
                    <strong>Calculate the time for 1,000 kilometers:</strong>
                    <div class=" py-4 w-full lg:w-80">
                        <img src="../../assets/images/problem-solving-tips/image-9.png" alt="">
                    </div>
                </li>
                <li>
                    <strong>Simplify:</strong>
                    <div class=" py-4 w-full lg:w-80">
                        <img src="../../assets/images/problem-solving-tips/image-10.png" alt="">
                    </div>
                </li>
            </ol>
            <p>
                <strong>Answer:</strong> It will take <b>12.5 hours</b> for the train to travel 1,000 kilometers.
            </p>
        </section>

        <h3>🔎 Quick Recap</h3>
        <section>
            <ul class="list-disc pl-4">
                <li><strong>Proportions:</strong> Use them when you know two related quantities and need to find the
                    unknown in a similar situation.
                </li>
                <li><strong>Unit Rates:</strong> Break the problem into smaller, manageable parts by calculating the
                    "per unit" value.
                </li>
                <li><strong>Proportional Time:</strong> Apply speed or rates consistently across different distances or
                    tasks.
                </li>
            </ul>
            <p>
                With these tips and examples, you'll be better equipped to handle any problem-solving questions that
                come your way!
            </p>
        </section>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ol {
    @apply space-y-2;
}

p {
    @apply text-sm;
}

ul {
    @apply space-y-1.5;
}
</style>
