<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            Here are several strategies to solve this type of problem. For this specific case, we’ll focus on the division into four groups, while also exploring other potential approaches.
        </p>

        <h3>💡 Approach 1: Divide the Grid into Four Groups of Four Squares</h3>
        <p class="p-4">
            <img src="../../assets/images/grid-completion/image-1.png" alt="" />
        </p>
        <section>
            <p>
                <strong>What to Do:</strong> Mentally divide the grid into smaller 2x2 blocks (four groups in total). Analyze the patterns within each block and compare them.
            </p>
            <p><strong>How to Apply:</strong></p>
            <ul class="list-disc pl-4">
                <li>Group the squares in the top-left, top-right, bottom-left, and bottom-right corners.</li>
                <li>Check for consistent features within each 2x2 block. Are all shapes unique? Is there a repeating element (e.g., moons, diamonds)?</li>
                <li>Ensure the missing block fits the same pattern as the others.</li>
            </ul>
            <p><strong>Tips for this grid:</strong></p>
            <ul class="list-disc pl-4">
                <li>In each group of four squares, all shapes (circle, diamond, moon, square) are present exactly once.</li>
                <li>Bottom-right block: The missing piece must therefore include a <b>diamond</b> and a <b>circle</b>.</li>
                <li>Bottom-left block: The missing piece must therefore include a <b>diamond</b>.</li>
                <li>Only <b>Choice (A) and Choice (D)</b> fits this requirement – helps you eliminate choices (B) and (C) much quicker.</li>
            </ul>
        </section>

        <h3>💡 Approach 2: Examine Rows and Columns for Unique Shapes</h3>
        <p class="p-4">
            <img src="../../assets/images/grid-completion/image-2.png" alt="" />
        </p>
        <section>
            <p>
                <strong>What to Do:</strong> Check whether every row and column contains all shapes (circle, diamond, moon, square) exactly once.
            </p>
            <p><strong>How to Apply:</strong></p>
            <ul class="list-disc pl-4">
                <li>Look at each row and column in isolation.</li>
                <li>Identify which shape is missing in the incomplete row or column.</li>
                <li>Verify that adding the missing shape doesn’t break the existing pattern.</li>
            </ul>
            <p><strong>Tips for This Grid:</strong></p>
            <ul class="list-disc pl-4">
                <li>Each <b>column</b> contains all four shapes (circle, diamond, moon, square) exactly once.</li>
                <li>The <b>rows</b> do not follow the same pattern, so analyzing rows is not effective for solving this problem.</li>
                <li>Use column-based reasoning as a quick method for <b>double-checking</b> or <b>eliminating obvious incorrect answers</b>.</li>
                <li>Only <b>Choice (A)</b> and <b>Choice (D)</b> satisfy the requirement of unique shapes in each column – helps you eliminate choices (B) and (C) much quicker.</li>
            </ul>
        </section>

        <h3>💡 Approach 3: Check for Rotational or Reflective Symmetry</h3>
        <p class="p-4">
            <img src="../../assets/images/grid-completion/image-3.png" alt="" />
        </p>
        <section>
            <p>
                <strong>What to Do:</strong> Look for symmetry across rows, columns, or blocks of the grid.
            </p>
            <p><strong>How to Apply:</strong></p>
            <ul class="list-disc pl-4">
                <li>Check if the grid maintains rotational (e.g., clockwise or counterclockwise patterns) or mirror symmetry (left-right or top-bottom).</li>
                <li>Identify how the missing piece fits into the overall symmetry.</li>
            </ul>
            <p><strong>Tips for this grid:</strong></p>
            <ul class="list-disc pl-4">
                <li>
                    <p>If we start with the shape “circle” from any 2x2 blocks, and if we move in a clockwise direction, the sequence repeats the same exact order:</p>
                    <div class=" text-center p-4 italic text-base">circle > square > moon > diamond</div>
                </li>
                <li>Bottom-right group: The missing piece must therefore include a diamond and a circle (in this particular order when moving clockwise).</li>
                <li>Bottom-left group: The missing piece must therefore include a diamond (since the last shape is the “moon”).</li>
                <li>Only <b>Choice (A)</b> fits this requirement. Therefore, this is the correct answer.</li>
            </ul>
        </section>

        <h3>💡 Approach 4: Compare Groupings Horizontally and Vertically</h3>
        <p class="p-4">
            <img src="../../assets/images/grid-completion/image-4.png" alt="" />
        </p>
        <section>
            <p>
                <strong>What to Do:</strong> Treat rows and columns as pairs and analyze their relationship. Does one row complement another? Are rows or columns inversely related or mirror images?
            </p>
            <p><strong>How to Apply:</strong></p>
            <ul class="list-disc pl-4">
                <li>Compare adjacent rows for similarities or inverse relationships (e.g., swapped positions, opposite colors).</li>
                <li>Do the same for columns.</li>
                <li>Verify if the missing piece continues the relationship.</li>
            </ul>
            <p><strong>Tips for This Grid:</strong></p>
            <ul class="list-disc pl-4">
                <li>
                   <p> Adjacent columns form inverse relationships (use complete columns for reference):</p>
                    <ul class="list-disc p-4">
                        <li>
                            <p><b>C1 and C2</b> (first and second columns) follow an inverted pattern:</p>
                            <ul class="list-disc p-4">
                                <li><b>C1:</b> (circle, diamond, moon, square)</li>
                                <li><b>C2:</b> (square, moon, diamond, circle)</li>
                            </ul>
                        </li>

                        <li>
                            <p>C4 and C3 (fourth and third columns) also follow an inverted pattern:</p>
                            <ul class="list-disc p-4">
                                <li><b>C4:</b> (moon, diamond, circle, square)</li>
                                <li><b>C3:</b> (square, circle, diamond, moon)</li>
                            </ul>
                        </li>

                    </ul>
                </li>
                <li>This inverse pairing ensures each column complements the other while maintaining unique shapes per column.</li>
                <li><b>Only Choice (A)</b> fits this column-based inverse relationship.</li>
            </ul>
        </section>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ul {
    @apply space-y-1.5;
}

p {
    @apply text-sm;
}
img {
    @apply mx-auto my-2
}
</style>
