<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            This sample problem involves an alternating pattern based on the orientation of the letter "E." Let’s break it down step-by-step:
        </p>

        <p class="p-4">
            <img src="../../assets/images/symbol-sequence/image-1.png" alt="" />
        </p>

        <h3>1. Analyze the First Segment</h3>
        <section>
            <ul class="list-disc p-4">
                <li>
                    <p>
                        In the <b>first segment</b>, the letter "E" alternates its orientation:
                    </p>
                    <ul class="list-disc p-4">
                        <li>The first "E" faces <b>right</b>.</li>
                        <li>The second "E" faces <b>down</b>.</li>
                        <li>The third "E" faces <b>right</b> again.</li>
                    </ul>
                </li>
            </ul>
            
        </section>

        <h3>2. Analyze the Second Segment</h3>
        <section>
            <ul class=" list-disc p-4">
                <li>In the <b>second segment</b>, the letters all face <b>down</b> consistently, without alternating.</li>
            </ul>
        </section>

        <h3>3. Identify the Alternating Pattern</h3>
        <section>
            <ul class=" list-disc p-4">
                <li>
                    <p>The series alternates between:</p>
                    <ul class="list-disc p-4">
                        <li>A first segment where the orientation of middle "E" <b>changes</b>.</li>
                        <li>A second segment where all "E"s face the <b>same direction as the changed “E” in the first segment</b>.</li>
                    </ul>
                </li>
                <li>Based on this, the <b>third segment</b> must continue the alternating pattern from the first and feature a single consistent orientation.</li>
            </ul>
        </section>

        <h3>4. Apply the Pattern to the Missing Symbol of Fourth Segment</h3>
        <section>
            <ul class=" list-disc p-4">
                <li>
                    <p> The fourth segment should follow the orientation of the middle “E” from the third segment:</p>
                    <ul class="list-disc p-4">
                        <li>Since the third segment’s “E” is facing up, then all “E”s in the fourth segment should also face up.</li>
                    </ul>
                </li>
                <li>This aligns with the alternating pattern.</li>
                <li>Only <b>Choice (C)</b> shows an “E” facing <b>up</b>, which fits the established pattern.</li>
            </ul>

        </section>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ul {
    @apply space-y-1.5;
}

p {
    @apply text-sm;
}
img {
    @apply mx-auto my-2
}
</style>
