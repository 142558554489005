<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            If you're preparing for an aviation-related exam, it's essential to have a solid grasp of different topics
            like Aircraft Airframe Systems. Here are some quick key tips to help you master these topics effectively.
        </p>

        <h3>💡 Understanding Aircraft Airframe Systems: The Backbone of Aircraft Operations</h3>
        <section>
            <p>
                Aircraft Airframe Systems encompass everything from environmental controls to hydraulic systems. These
                systems work together to ensure safe and efficient flights. To excel in this area:
            </p>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Focus on Functional Roles:</strong> Start by identifying each system's primary purpose. For
                    instance, learn why the Environmental Control System (ECS) is vital for maintaining cabin comfort or
                    why landing gear shock struts absorb impact during landings. Understanding these roles provides
                    context and helps you retain information.
                </li>
                <li>
                    <strong>Connect the Systems:</strong> Visualize how these systems interact. For example, the bleed
                    air system not only pressurizes the cabin but also provides air for de-icing. Linking multiple
                    functions to a single system reinforces your understanding.
                </li>
                <li>
                    <strong>Use Illustrations:</strong> Diagrams and schematics are your best friends here. Seeing the
                    layout of a hydraulic power system or an anti-skid mechanism can help reinforce your knowledge
                    beyond just memorizing definitions.
                </li>
            </ul>
        </section>

        <h3>💡 Most Common Aircraft Airframe Systems</h3>
        <section>
            <ul class="list-disc pl-4">
                <li><strong>Hydraulic System:</strong> Used for operating flight controls, landing gear, brakes, and
                    other critical systems.
                </li>
                <li><strong>Fuel System:</strong> Manages the storage, supply, and transfer of fuel throughout the
                    aircraft.
                </li>
                <li><strong>Environmental Control System (ECS):</strong> Maintains cabin temperature, pressure, and air
                    quality.
                </li>
                <li><strong>Landing Gear System:</strong> Responsible for retracting, extending, and absorbing the
                    impact during landing and takeoff.
                </li>
                <li><strong>Flight Control System:</strong> Operates ailerons, elevators, rudders, and other control
                    surfaces to maneuver the aircraft.
                </li>
                <li><strong>Pneumatic System:</strong> Supplies compressed air for de-icing, pressurization, and
                    powering air-driven components.
                </li>
                <li><strong>Anti-Ice/De-Ice System:</strong> Prevents ice accumulation on critical surfaces like wings,
                    engines, and tail.
                </li>
                <li><strong>Pressurization System:</strong> Ensures the aircraft cabin remains at a comfortable pressure
                    during flight.
                </li>
                <li><strong>Electrical System:</strong> Provides power for navigation, communication, lighting, and
                    other electrical equipment on board.
                </li>
                <li><strong>Oxygen System:</strong> Supplies oxygen to crew and passengers in case of cabin
                    depressurization.
                </li>
                <li><strong>Fire Protection System:</strong> Detects and suppresses fires in engines, cargo bays, and
                    other critical areas.
                </li>
                <li><strong>Water and Waste System:</strong> Manages potable water and waste disposal for onboard
                    sanitation.
                </li>
                <li><strong>Structural Monitoring System:</strong> Monitors the health and integrity of the aircraft’s
                    structural components.
                </li>
                <li><strong>Ice and Rain Protection System:</strong> Prevents ice and clears rain from windshields and
                    sensors.
                </li>
                <li><strong>Auxiliary Power Unit (APU) System:</strong> Provides power for starting engines and powering
                    systems when the main engines are off.
                </li>
                <li><strong>Lighting System:</strong> Includes exterior lights for visibility and signaling and interior
                    lights for passenger and crew needs.
                </li>
                <li><strong>Emergency System:</strong> Provides emergency equipment such as escape slides, flotation
                    devices, and emergency lighting.
                </li>
                <li><strong>Vibration Monitoring System:</strong> Tracks and analyzes vibration levels in the aircraft
                    to prevent mechanical issues.
                </li>
                <li><strong>Cargo Handling System:</strong> Ensures safe and efficient loading, unloading, and securing
                    of cargo.
                </li>
            </ul>
        </section>

        <p class="mt-6">
            While it might seem overwhelming at first, a well-rounded preparation strategy is your key to success. Break
            each topic into digestible sections, focus on understanding rather than memorization, and supplement your
            learning with practice questions.
        </p>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ul {
    @apply space-y-1.5;
}

p {
    @apply text-sm;
}
</style>
