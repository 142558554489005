<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            Preparing for an aviation exam can be a daunting task, especially when you're faced with a topic like
            Aerodynamics. However, with the right strategies and focus, you can effectively navigate through these
            areas.
        </p>

        <h3>💡 Mastering the Basics of Aerodynamics</h3>
        <section>
            <p>
                Aerodynamics is significant to flight, making it crucial to familiarize yourself with the concepts:
            </p>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Focus on the Four Forces of Flight:</strong> Lift, weight, thrust, and drag are the
                    cornerstones of aerodynamics. Be clear about how each force interacts with the others, and study
                    examples that illustrate these interactions in different phases of flight.
                </li>
                <li>
                    <strong>Get Comfortable with Key Terminology:</strong> Aerodynamics can seem overwhelming due to the
                    technical terms used. Spend time building a glossary of terms like camber, chord, angle of
                    incidence, and center of gravity. Clear definitions and regular revision will boost your confidence
                    in tackling exam questions that involve these terms.
                </li>
            </ul>
        </section>

        <h3>💡 Basic Aerodynamics Terms and Their Meanings</h3>
        <section>
            <ul class="list-disc pl-4">
                <li><strong>Lift:</strong> The force that opposes the weight of an aircraft and keeps it in the air.</li>
                <li><strong>Thrust:</strong> The force generated by engines to push the aircraft forward.</li>
                <li><strong>Drag:</strong> The force that opposes the aircraft's motion through the air.</li>
                <li><strong>Weight:</strong> The force due to gravity pulling the aircraft down.</li>
                <li>
                    <strong>Angle of Attack:</strong> The angle between the oncoming airflow and the airfoil's chord
                    line.
                </li>
                <li>
                    <strong>Camber:</strong> The curve of an airfoil’s surface, affecting lift generation.
                </li>
                <li>
                    <strong>Center of Gravity:</strong> The point where the aircraft’s total weight is balanced.
                </li>
                <li>
                    <strong>Pitch:</strong> The movement of an aircraft around its lateral axis, controlling nose-up or
                    nose-down orientation.
                </li>
                <li>
                    <strong>Roll:</strong> The movement of an aircraft around its longitudinal axis, controlling wing up
                    or wing down motion.
                </li>
                <li>
                    <strong>Yaw:</strong> The movement of an aircraft around its vertical axis, controlling the left or
                    right direction of the nose.
                </li>
                <li>
                    <strong>Chord Line:</strong> The straight line connecting the leading and trailing edges of an
                    airfoil.
                </li>
                <li>
                    <strong>Span:</strong> The distance between one wingtip and the other, determining the aircraft’s
                    wingspan.
                </li>
            </ul>
        </section>

        <p class="mt-6">
            Understanding the principles of aerodynamics is essential for mastering aviation. Focus on the fundamentals,
            study key terms, and practice regularly to build confidence and ace your exams.
        </p>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ul {
    @apply space-y-1.5;
}

p {
    @apply text-sm;
}
</style>