<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            If you're preparing for an aviation-related exam, it's essential to have a solid grasp of different topics
            like Aircraft Technical Drawing. Here are some quick key tips to help you master these topics effectively.
        </p>

        <h3>💡 Navigating Technical Drawing: The Language of Precision</h3>
        <section>
            <p>
                Technical drawing is like learning a new language. It's essential for anyone aiming to understand
                aircraft design and functionality. Here’s how to get a good grasp of this area:
            </p>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Familiarize with Common Symbols and Lines:</strong> Learn the symbols for various features,
                    like dashed lines for hidden parts or chain lines for centerlines. Memorizing these will help you
                    interpret drawings faster and more accurately.
                </li>
                <li>
                    <strong>Understand Perspective and Views:</strong> Technical drawings use orthographic projections,
                    isometric views, and section views to represent parts. Know the purpose of each view. For instance,
                    isometric drawings provide a three-dimensional feel, while orthographic projections give precise
                    dimensions.
                </li>
            </ul>
        </section>

        <h3>💡 Common Symbols and Lines in Technical Drawing</h3>
        <section>
            <ul class="list-disc pl-4">
                <li><strong>Centerline:</strong> Represented by alternating long and short dashes, it marks the center of
                    a part, circle, or symmetrical feature.
                </li>
                <li><strong>Hidden Line:</strong> A dashed line that shows edges or parts not visible in the current
                    view.
                </li>
                <li><strong>Dimension Line:</strong> A solid line with arrowheads indicating the measured distance
                    between two points.
                </li>
                <li><strong>Extension Line:</strong> Solid lines extending from the object to the dimension line,
                    indicating the points being measured.
                </li>
                <li><strong>Leader Line:</strong> A line that connects a note or dimension to a specific part of the
                    drawing, often with an arrow or dot at one end.
                </li>
                <li><strong>Break Line:</strong> A jagged or wavy line indicating that a section of the drawing is
                    intentionally omitted for clarity.
                </li>
                <li><strong>Cutting Plane Line:</strong> A thick, dashed line with arrows at both ends, indicating the
                    location of a sectional cut in the drawing.
                </li>
                <li><strong>Section Line (Hatching):</strong> Parallel lines or patterns within a sectional view to
                    indicate the material of a cut surface.
                </li>
                <li><strong>Phantom Line:</strong> Alternating long and double short dashes, used to show alternate
                    positions of parts or motion paths.
                </li>
                <li><strong>Construction Line:</strong> Thin, light lines used as guides during the drafting process;
                    these are not part of the final drawing.
                </li>
                <li><strong>Object Line:</strong> A solid, thick line representing visible edges and outlines of the
                    object.
                </li>
                <li><strong>Boundary Line:</strong> A thicker line that defines the limits of a particular feature or
                    part of the drawing.
                </li>
                <li><strong>Symmetry Line:</strong> A thin line with small ticks at the ends, used to denote symmetrical
                    parts.
                </li>
                <li><strong>Bolt Circle:</strong> A series of dashed circles indicating the placement of bolt holes
                    around a central feature.
                </li>
            </ul>
        </section>

        <h3>💡 Perspective and Views in Technical Drawing</h3>
        <section>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Orthographic Projection:</strong> A 2D representation of an object from multiple views
                    (front, top, side).
                </li>
                <li>
                    <strong>Isometric View:</strong> A 3D representation showing all three axes at equal angles.
                </li>
                <li>
                    <strong>Oblique Projection:</strong> A 3D view with the front face to scale and depth angled.
                </li>
                <li>
                    <strong>Perspective View:</strong> A realistic 3D view where objects shrink with distance.
                </li>
                <li>
                    <strong>Section View:</strong> A cutaway view highlighting internal features.
                </li>
                <li>
                    <strong>Exploded View:</strong> A 3D diagram showing separated components in their positions.
                </li>
                <li>
                    <strong>Auxiliary View:</strong> A projection showing details on angled surfaces.
                </li>
                <li>
                    <strong>Detail View:</strong> An enlarged view for intricate or small features.
                </li>
                <li>
                    <strong>Plan View:</strong> A horizontal, top-down view of an object.
                </li>
                <li>
                    <strong>Elevation View:</strong> A vertical side view showing height and vertical details.
                </li>
            </ul>
        </section>

        <p class="mt-6">
            While it might seem overwhelming at first, a well-rounded preparation strategy is your key to success. Break
            each topic into digestible sections, focus on understanding rather than memorization, and supplement your
            learning with practice questions.
        </p>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ul {
    @apply space-y-1.5;
}

p {
    @apply text-sm;
}
</style>
