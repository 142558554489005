
<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            Preparing for an aviation exam can be a daunting task, especially when you're faced with a topic like
            Avionics Systems. However, with the right strategies and focus, you can effectively navigate through these
            areas.
        </p>

        <h3>💡 Navigating Avionics Systems with Confidence</h3>
        <section>
            <p>
                Avionics may seem intimidating due to the number of systems involved, but breaking it down into
                manageable sections can be a game-changer:
            </p>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Memorize the Purpose of Key Systems:</strong> Focus on the primary functions of systems like
                    the pitot-static system, autopilot, VHF communication system, and the Flight Management System
                    (FMS). Understanding each system’s core purpose will help you quickly recall their roles during the
                    exam.
                </li>
            </ul>
        </section>

        <h3>💡 Key Avionics Systems and Their Functions</h3>
        <section>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Pitot-Static System:</strong> Measures airspeed, altitude, and vertical speed using static
                    and dynamic air pressures.
                </li>
                <li>
                    <strong>Autopilot System:</strong> Maintains the aircraft's course and altitude automatically,
                    reducing pilot workload during long flights.
                </li>
                <li>
                    <strong>Flight Management System (FMS):</strong> Assists pilots in flight planning, navigation, and
                    fuel management.
                </li>
                <li>
                    <strong>Weather Radar System:</strong> Provides real-time weather updates to detect and avoid
                    adverse conditions.
                </li>
                <li>
                    <strong>Traffic Collision Avoidance System (TCAS):</strong> Alerts pilots of potential mid-air
                    collisions with nearby aircraft.
                </li>
                <li>
                    <strong>VHF Communication System:</strong> Manages communication between the pilot and Air Traffic
                    Control (ATC).
                </li>
                <li>
                    <strong>Global Positioning System (GPS):</strong> Provides accurate position and time information
                    for navigation.
                </li>
                <li>
                    <strong>Inertial Navigation System (INS):</strong> Determines aircraft position based on
                    accelerometers and gyroscopes without external signals.
                </li>
                <li>
                    <strong>Electronic Flight Instrument System (EFIS):</strong> Displays essential flight information
                    like attitude, altitude, and navigation on screens.
                </li>
                <li>
                    <strong>Ground Proximity Warning System (GPWS):</strong> Warns pilots of imminent ground collisions,
                    improving landing safety.
                </li>
            </ul>
        </section>

        <p class="mt-6">
            Understanding avionics systems is key to excelling in aviation exams and your future career. By breaking
            down complex systems into manageable concepts and focusing on their functions, you can approach this topic
            with confidence.
        </p>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ul {
    @apply space-y-1.5;
}

p {
    @apply text-sm;
}
</style>
