<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            Preparing for an aviation exam can be a daunting task, especially when you're faced with a topic like Gas
            Turbine Engines. However, with the right strategies and focus, you can effectively navigate through these
            areas.
        </p>

        <h3>💡 Understanding the Power and Purpose of Gas Turbine Engines</h3>
        <section>
            <p>
                Gas turbine engines are a fundamental part of modern aircraft, and understanding their components and
                functions is critical. Here are some pointers to keep in mind:
            </p>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Visualize the Engine Layout:</strong> When studying gas turbines, focus on visualizing the
                    layout of key components like the compressor, combustion chamber, turbine, and exhaust. Knowing how
                    air flows through these sections and their respective functions will make it easier to answer
                    questions that involve step-by-step processes.
                </li>
                <li>
                    <strong>Know the Types and Their Uses:</strong> Different gas turbine engines, such as turbofan,
                    turbojet, and turboshaft, have unique applications and characteristics. Familiarize yourself with the
                    purposes of each engine type, and try to connect this information to real-life scenarios like
                    commercial flights, helicopters, or even military aircraft.
                </li>
            </ul>
        </section>

        <h3>💡 Common Engine Sections and Their Functions</h3>
        <section>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Inlet:</strong> Guides the incoming air smoothly into the engine, preventing turbulence.
                </li>
                <li>
                    <strong>Fan:</strong> Increases the mass of air flowing through the engine, improving efficiency
                    (primarily in turbofan engines).
                </li>
                <li>
                    <strong>Compressor:</strong> Increases the pressure of incoming air for combustion, enhancing
                    efficiency.
                </li>
                <li>
                    <strong>Combustion Chamber:</strong> Burns the air-fuel mixture to generate high-energy gases.
                </li>
                <li>
                    <strong>Turbine:</strong> Converts the energy from combustion gases into mechanical energy to drive
                    the compressor and other components.
                </li>
                <li>
                    <strong>Exhaust:</strong> Directs the high-energy gases out of the engine, contributing to thrust
                    production.
                </li>
            </ul>
        </section>

        <h3>💡 Types of Gas Turbine Engines and Their Key Features</h3>
        <section>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Turbofan Engine:</strong> Uses a large fan to increase airflow, making it fuel-efficient and
                    quieter, ideal for long-distance commercial flights.
                </li>
                <li>
                    <strong>Turbojet Engine:</strong> Known for simplicity and speed; primarily used in military and
                    supersonic aircraft.
                </li>
                <li>
                    <strong>Turboprop Engine:</strong> Drives a propeller using the turbine, efficient at lower speeds
                    and ideal for regional and cargo planes.
                </li>
                <li>
                    <strong>Turboshaft Engine:</strong> Designed to produce mechanical power rather than thrust,
                    commonly used in helicopters.
                </li>
            </ul>
        </section>

        <p class="mt-6">
            By visualizing engine components, connecting them to real-world uses, and studying their unique
            characteristics, you’ll not only excel in exams but also develop a deeper appreciation for their role in
            aviation.
        </p>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ul {
    @apply space-y-1.5;
}

p {
    @apply text-sm;
}
</style>
