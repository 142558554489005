<script setup>
import ROUTER from "@/router"
import {ref, onMounted, computed, watch} from 'vue';
import firebase from "@/firebase/config";

import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue";

import { 
    BookOpenIcon,
    ChevronDoubleLeftIcon, ChevronDoubleRightIcon, 
    LogoutIcon,
    ExclamationIcon,
    UploadIcon,
    XIcon,
} from "@heroicons/vue/outline"
import { 
    QuestionMarkCircleIcon, LightBulbIcon 
} from "@heroicons/vue/solid"

const isWaitingToExit = ref(false);
const isWaiting = ref(false) // ui status
const items = ref([]);
const user = ref(null);
const currentItemIndex = ref(0);

const isOnQuitOpen = ref(false) // flag for the OnQuit modal. when user quits
const isOnCompleteOpen = ref(false) // flag for the onComplete modal

const isExplorerShown = ref(false);



/**
 * Fetch the media URL for a given file path in Firebase Storage.
 * 
 * This function dynamically retrieves the download URL for a file,
 * ensuring that even if the token is manually revoked in the Firebase Console,
 * the file remains accessible if permitted by Firebase Security Rules.
 *
 * @param {string} filePath - The path of the file in Firebase Storage (e.g., 'images/profile/user123.jpg').
 */
 const fetchMediaUrl = async (filePath) => {
  try {
    const storage = firebase.storage(); // Initialize Firebase Storage
    const storageRef = storage.ref(filePath); // Reference the file in Storage
    const url = await storageRef.getDownloadURL(); // Retrieve the download URL
    // console.log('Media URL fetched successfully:', url);
    return url; // Return the URL for use in your app
  } catch (error) {
    // console.error('Error fetching media URL:', error);
    throw error; // Rethrow the error for the caller to handle
  }
};




const fetchItems = async () => {

    const [ 
        // videos
        vid1,
        vid2,
        vid3,
        vid4,
        vid5,
        vid6,
        vid7,
        // images
        image1,
        image2a,
        image2b,
        image2c,
        image2d,
        image2e,
        image3,
    ] = await Promise.all([
        // videos
        fetchMediaUrl('mandex/videos/Video 1.mp4'),
        fetchMediaUrl('mandex/videos/Video 2.mp4'),
        fetchMediaUrl('mandex/videos/Video 3.mp4'),
        fetchMediaUrl('mandex/videos/Video 4.mp4'),
        fetchMediaUrl('mandex/videos/Video 5.mp4'),
        fetchMediaUrl('mandex/videos/Video 6.mp4'),
        fetchMediaUrl('mandex/videos/Video 7.mp4'),
        // images
        fetchMediaUrl('mandex/images/image-1.png'),
        fetchMediaUrl('mandex/images/image-2a.png'),
        fetchMediaUrl('mandex/images/image-2b.png'),
        fetchMediaUrl('mandex/images/image-2c.png'),
        fetchMediaUrl('mandex/images/image-2d.png'),
        fetchMediaUrl('mandex/images/image-2e.png'),
        fetchMediaUrl('mandex/images/image-3.png'),
    ]);

    return [
        {
            id: 1,
            study: `
                <h1 class="text-yellow-400">Disclaimer</h1>

                <p>Our MANDEX reviewer is designed as a reference and practice tool to help you familiarize yourself with the kind of manual dexterity tests commonly used in aviation company qualification processes. The sample exercises and mock-ups included here are derived from thorough research and interviews with recent exam takers.</p>
                <p>Please note that this is not an exact replication of the actual test you may encounter. Treat this reviewer as a guide to give you a general idea and improve your preparedness. It is not guaranteed to cover the precise concepts or tasks of your upcoming exam.</p>
                <p>Any similarities in concepts or designs to the original tests are purely coincidental and unintentional. This reviewer was created based on the information made available through the descriptions and recollections of past exam takers. It is not intended to replicate or infringe upon any intellectual property, copyrights, or proprietary designs of the companies conducting such exams.</p>
                <p>Approach this reviewer as a practice opportunity, and remember: it’s all about developing your skills and confidence.</p>

            `
        },
        {
            id: 2,
            study: `
                <img src="${image1}" data-align="center" style="display: block; margin: auto;" class="user-select-none">
                <h1 class="text-yellow-400">What is MANDEX?</h1>
                <p>If this is your first time hearing about this, the Mandex Test is a manual dexterity assessment commonly used in aviation maintenance and engineering fields to evaluate a candidate's proficiency in assembling mechanical components. This practical test involves the following steps:</p></p>
                    <ol>
                        <li><b>Presentation of a Pre-Assembled Structure:</b> Job applicants are shown a model consisting of multiple steel plates (usually 6 square plates) connected by various nuts, bolts, washers, and spacers.</li>
                        <li><b>Reassembly Task:</b> Using the provided set of plates and fasteners/fixings, candidates are required to replicate the pre-assembled structure without the aid of additional tools (assembly by hand).</li>
                        <li><b>Scoring Criteria:</b> Performance is assessed based on the correct selection and assembly of components, as well as the number of fittings accurately assembled within a specified time limit.</li>
                    </ol>
                <p>This test serves as a practical simulation of tasks encountered in aviation maintenance, ensuring that individuals possess the necessary hand-eye coordination and mechanical aptitude for the role.</p>               
            `
        },
        {
            id: 3,
            study: `
                <h1 class="text-yellow-400">Familiarizing Yourself with MANDEX Parts</h1>
                <p>A standard MANDEX exam structure consists of the following components:</p>
                    <ol>
                        <li><p><b>Plates:</b> These are the main parts of the assembly, typically six square pieces made of aluminum alloy or clear acrylic/plastic. Each plate is unique, with differently positioned attachment holes, but they share a common reference point (often a bigger circular hole) to ensure proper alignment in the final assembly.</p>
                            <img src="${image2a}" data-align="center" style="display: block; margin: auto;" class="user-select-none">
                        </li>
                        <li><p><b>Bolts:</b> Standard industrial bolts are used, usually in 2-3 different diameters and lengths. They play a key role in securing the plates together.</p>
                            <img src="${image2b}" data-align="center" style="display: block; margin: auto;" class="user-select-none">
                        </li>
                        <li><p><b>Spacers:</b> Open-ended metallic cylindrical tubes placed between plates to maintain proper spacing.</p>
                            <img src="${image2c}" data-align="center" style="display: block; margin: auto;" class="user-select-none">
                        </li>
                        <li><p><b>Washers:</b> Thin circular discs used to protect the plates from damage, positioned on both the bolt head and nut side (two washers per bolt).</p>
                            <img src="${image2d}" data-align="center" style="display: block; margin: auto;" class="user-select-none">
                        </li>
                        <li><p><b>Nuts:</b> Standard industrial nuts are used, usually in 2-3 different diameters. This will be the matching part of the bolt in holding the plates together.</p>
                            <img src="${image2e}" data-align="center" style="display: block; margin: auto;" class="user-select-none">
                        </li>
                    </ol>
                <p><b>Tip:</b> When connecting parts in the assembly, always follow this standard sequence:</p>
                <p class="text-center text-yellow-400"><i>Bolt (Head) > Washer > 1st Plate > Spacer > 2nd Plate > Washer > Nut</i></p>
            `
        },
        {
            id: 4,
            study: `
                <img src="${image3}" data-align="center" style="display: block; margin: auto;" class="user-select-none">
                <h1 class="text-yellow-400">What to Expect Before the Exam Starts</h1>
                <p>When you enter the exam room, you won’t begin immediately. The examiner or proctor will provide essential information about the test and present a mock-up. This is a crucial time to focus on the following:</p>
                    <ol>
                        <li><p><b>Listen to Instructions Carefully:</b> The examiner will explain the test process, including how it will be scored. Pay close attention to details, such as specific guidelines like:</p>
                            <ul class="decoration-desc">
                                <li>“If you make a mistake during assembly, avoid disassembling everything to ensure you finish on time.” (small tips like this can make a big difference)</li>
                            </ul>
                        </li>
                         <li><p><b>Observe the Pre-Assembled Structure:</b> A replica of the final structure (your target result) will be displayed, either before you start or as the timer begins. Take a mental snapshot of:</p>
                            <ul class="decoration-desc">
                                <li>The sequence and placement of the plates.</li>
                                <li>The orientation of bolts, noting which side has the head and which has the nut.</li>
                            </ul>
                        </li>
                        <li><p><b>Keep the Time Limit in Mind:</b> One of the biggest challenges is completing the assembly within the time limit, which is usually around 10-15 minutes. Make sure to pace yourself and plan your moves efficiently.</p></li>
                    </ol>
                <p><b>Tip:</b> If you miss any details or want to confirm something, don’t hesitate to politely ask the examiner for clarification. However, remember that this is part of the qualification process. Ask only relevant and appropriate questions, and maintain a formal and professional demeanor—just as you would in a job interview.</p>
            `
        },
        {
            id: 5,
            video: vid1,
            study: `
                <h1 class="text-yellow-400">Start of the Exam: Sequence the Plates</h1>
                <p>The first two minutes of the exam are critical—your initial actions can greatly influence whether you finish on time. Follow these steps for an efficient start:</p>
                <p><b>Sequence the Plates:</b> The plates must be arranged in a specific sequence from bottom to top. This sequence can usually be identified by observing the replica for key details, such as bolt positions and the reference hole. Arrange the plates correctly before starting to ensure a smoother assembly process.</p>
                <p><i>For training purposes, we’ll assign a sequence from <b>#1 (bottom plate)</b> to <b>#6 (top plate)</b> to help you practice.</i></p>
                <p><b>Tip:</b> After sequencing the plates, place it all upside-down–positioning Plate #1 at the top of the stack for easier assembly later on.</p>
                <p><b>Target Speed:</b> Done arranging the plates in less than 1 minute.</p>
            `
        },
        {
            id: 6,
            video: vid2,
            study: `
                <h1 class="text-yellow-400">Sort the Small Parts:</h1>
                <p>If the smaller components (bolts, nuts, spacers, washers) are not pre-sorted—which is common in exams—it’s essential to organize them by size and length. This will minimize errors, such as using an oversized washer on a smaller bolt, and save you time during assembly.</p>
                <p>For training purposes and easier identification, we’ll group the bolts as follows:</p>
                <ul class="decoration-desc">
                    <li>Small Bolt (●)</li> 
                    <li>Medium Bolt (▲)</li>
                    <li>Large Bolt (■)</li>
                    <li>Long Bolt (⬥)</li>
                </ul>
                <p><b>Tip:</b> Properly arranging and sorting the parts before assembly may also positively influence how the examiner scores your test, based on our research.</p>
                <p><b>Tip:</b> Save time and improve convenience during assembly by pre-inserting one washer onto each bolt as you are sorting them into groups.</p>
                <p><b>Target Speed:</b> Done sorting in less than 1 minute.</p>
            `
        },
        {
            id: 7,
            video: vid3,
            study: `
                <h1 class="text-yellow-400">Section A: Connecting Plates #1 and #2</h1>
                <ol>
                    <li>Align <b>Plate #2</b> on top of <b>Plate #1</b>.</li>
                    <li>Secure the plates by inserting <b>2 Large Bolts (■)</b> into <b>Plate #2</b> (head side) through <b>Plate #1</b> (nut side).</li>
                    <li>Set this assembly aside temporarily while you move on to the next section.</li>
                </ol>
                <p><b>Tip:</b> Avoid fully tightening the nuts until the entire assembly is complete. Leaving them slightly loose allows for easier adjustments and provides more flexibility when inserting and tightening the remaining bolts.</p>
                <p><b>Target Speed:</b> Done with this section in less than 1 minute.</p>
            `
        },
        {
            id: 8,
            video: vid4,
            study: `
                <h1 class="text-yellow-400">Section B: Connecting Plates #3 to #5</h1>
                <ol>
                    <li>Insert <b>2 Long Bolts (⬥)</b> starting from the bottom of <b>Plate #3</b> (head side), passing through <b>Plate #4</b>, and ending at the top of <b>Plate #5</b> (nut side).</li>
                    <li>Shift <b>Plate #4</b> closer to <b>Plate #5</b> to create space for inserting <b>2 Large Bolts (■)</b> into <b>Plate #3</b> (head side). This prepares the assembly for a later combination with Section A.</li>
                    <li>Shift <b>Plate #4</b> closer to <b>Plate #3</b> to create space for inserting <b>2 Medium Bolts (▲)</b> into <b>Plate #5</b> (head side). After placing spacers between the plates, then insert the bolts through <b>Plate #4</b> (nut side).</li>
                </ol>
                <p><b>Target Speed:</b> Done with this section in less than 3 minutes.</p>
            `
        },
        {
            id: 9,
            video: vid5,
            study: `
                <h1 class="text-yellow-400">Combining Sections A and B (Plates #1 to #5)</h1>
                <ol>
                    <li>Before combining the sections, <b>permanently tighten the 2 Long Bolts (⬥)</b>. This prevents accessibility issues later, as these bolts are located in the inner area of the assembly.</li>
                    <li>Combine the sections by pushing the pre-installed <b>2 Large Bolts (■)</b> from <b>Plate #3</b> (head side) through <b>Plate #2</b> (nut side).</li>
                </ol>
                <p><b>Target Speed:</b> Done combining the sections in less than 2 minutes.</p>
            `
        },
        {
            id: 10,
            video: vid6,
            study: `
                <h1 class="text-yellow-400">Connecting the last Plate #6</h1>
                <ol>
                    <li>Attach the last plate by inserting the remaining <b>2 Small Bolts (●)</b> from <b>Plate #6</b> (head side) through <b>Plate #5</b> (nut side).</li>
                </ol>
                <p><b>Target Speed:</b> Done connecting the last plate in less than 1 minute.</p>
            `
        },
        {
            id: 11,
            video: vid7,
            study: `
                <h1 class="text-yellow-400">Finishing Touches: Tighten Everything</h1>
                <p>Perform a <b>360° check</b> by rotating the entire assembly to ensure all bolts and nuts are securely tightened, leaving no loose components.</p>
                <p><b>Tip:</b> Use a systematic approach to make your check thorough. For example, you can follow a “corner-by-corner” method, inspecting the nearest corner as you rotate the assembly. This helps maintain consistency and prevents missed spots.</p>
                <p><b>Target Speed:</b> Done checking and tightening everything in less than 1 minute.</p>
            `
        },
    ];

}

const fetchUser = () => {
    return new Promise(async (resolve, reject) => {
        let id = firebase.auth().currentUser.uid
        firebase.firestore().collection("users").doc(id).get()
            .then(doc => {
                let data = doc.data()
                data.id = doc.id
                resolve(data)
            })
            .catch((error) => {
                console.error("Error getting user document:", error)
                reject(error)
            });
    })
}

const extractH1Text = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const h1Tags = doc.querySelectorAll('h1');
    const h1Texts = Array.from(h1Tags).map(h1 => h1.textContent).join(', ');
    return h1Texts || '---';
}

const jump = (toIndex) => {
    currentItemIndex.value = toIndex;
    isExplorerShown.value = false;
    scrollBackToTop();
}

const handleNextItemClick = () => {
    currentItemIndex.value++;
    scrollBackToTop();
}

const handlePrevItemClick = () => {
    if (currentItemIndex.value > 0) {
        currentItemIndex.value--;
        scrollBackToTop();
    }
}


// Watch for changes in currentItemIndex
watch(currentItemIndex, (newIndex, oldIndex) => {
    // Pause the video of the previously visible item
    const previousVideo = document.getElementById(`video-${oldIndex}`);
    if (previousVideo && !previousVideo.paused) {
        previousVideo.pause();
    }
});

const scrollBackToTop = () => {
    const element = document.getElementById('target-div');
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}

const exitStudy = async () => {

    isWaitingToExit.value = true;
    ROUTER.push({ path: `/mtp-entrance/subjects` })
}

const completeStudy = async () => {
    exitStudy();
}

onMounted(async ()=>{

    isWaiting.value = true;
    [ items.value, user.value ] = await Promise.all([
        fetchItems(),
        fetchUser()
    ]);

    preloadImagesFromHtml();
    preloadVideos();
   
    const parent = document.body;
    parent.addEventListener('contextmenu', (event) => {
        if (event.target.classList.contains('user-select-none')) {
            event.preventDefault(); // Disable right-click context menu
        }
    });

    isWaiting.value = false;

})


const loadedVid = ref([]);
const preloadVideos = async () => {

    console.log('Start preloading videos from items...');

    // Initialize the loadedVid array with false values
    loadedVid.value = Array(items.value.length).fill(false);

    const preloadVideo = async (videoUrl, index) => {
        return new Promise((resolve) => {
            const video = document.createElement('video');
            video.src = videoUrl;
            video.onloadeddata = () => {
                // console.log(`Video at index ${index} loaded successfully.`);
                resolve();
            };
            video.onerror = (error) => {
                // console.error(`Failed to load video at index ${index}:`, error);
                resolve(); // Resolve even if there is an error
            };
        });
    };

    const batchSize = 5; // Define the size of each batch
    const totalItems = items.value.length;

    for (let batchStart = 0; batchStart < totalItems; batchStart += batchSize) {
        const batchEnd = Math.min(batchStart + batchSize, totalItems);
        const batch = items.value.slice(batchStart, batchEnd);

        // console.log(`Processing batch: ${batchStart + 1} to ${batchEnd}`);
        // Preload the batch sequentially within the batch
        for (let i = 0; i < batch.length; i++) {
            const index = batchStart + i;
            await preloadVideo(batch[i].video, index);
            loadedVid.value[index] = true;
        }
    }

    console.log('All videos processed:', loadedVid.value);
};


const loadedImg = ref([]);
const preloadImagesFromHtml = async () =>  {

    console.log('Start preloading images from questions...')

    // Initialize the loaded array with false values
    loadedImg.value = Array(items.value.length).fill(false);

    const preloadQuestion = async (item, index) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(item.study, 'text/html');
        const imgElements = doc.querySelectorAll('img'); // Find all <img> tags
        const imgPromises = Array.from(imgElements).map((img) => {
            return new Promise((resolve) => {
                const image = new Image();
                image.src = img.src;
                image.onload = () => resolve();
                image.onerror = () => resolve(); // Resolve even on error
            });
        });

        await Promise.all(imgPromises);
        // console.log('Completed image loading for question index:', index);
        loadedImg.value[index] = true;
    };

    const batchSize = 5; // Define the size of each batch
    const totalQuestions = items.value.length;

    for (let batchStart = 0; batchStart < totalQuestions; batchStart += batchSize) {

        const batchEnd = Math.min(batchStart + batchSize, totalQuestions);
        const batch = items.value.slice(batchStart, batchEnd);

        // console.log(`Processing batch: ${batchStart + 1} to ${batchEnd}`);
        // Preload the batch sequentially within the batch
        for (let i = 0; i < batch.length; i++) {
            const index = batchStart + i;
            await preloadQuestion(batch[i], index);
        }
    }

    console.log('All questions processed:', loadedImg.value);
}


</script>

<template>
    <div id="review-container" class="w-full mx-auto">
        <!-- The page has been initialized here -->
        <div v-if="!isWaiting">

            <!-- Breadcrumbs -->
            <nav id='practice-nav' class="flex px-1 lg:px-7 justify-between items-center" aria-label="Breadcrumb">
                <div class="flex items-center">
                <div @click=" items.length > 0 ? isOnQuitOpen = true : exitStudy()" class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center cursor-pointer" aria-current="page">
                    <LogoutIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
                </div>
                </div>
                <div class="text-sm font-medium text-warm-gray-500">
                    <span>Study for MANDEX</span>
                </div>
                <!-- FAQ Menu -->
                <button class="text-warm-gray-500">
                    <QuestionMarkCircleIcon class="h-5 w-5" aria-hidden="true" />
                </button>
            </nav>

            <section id="questionExplorer" v-if="isExplorerShown" class="flex flex-col items-end mt-8 mb-28">
                <button @click="isExplorerShown = false" class="my-2 flex hover:opacity-75 items-center text-indigo-500">
                    <XIcon class="h-5 w-5  mt-0.5 mr-1"/>
                    <span>Cancel and Close</span>
                    
                </button>
                <div class="w-full">
                    <ul>
                    <li v-for="(item, index) in items" :key="`question-explorer-${index}`" class="">
                        <button 
                        @click="jump(index)"
                        :class="['w-full my-2 space-x-2 flex flex-inline justify-between items-center disabled:cursor-auto p-4 sm:px-5 border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800']">
                        <div class="flex text-left space-x-2 items-center">
                            <span>{{ extractH1Text(item.study) }}</span>
                            <!-- <span class="text-left">{{ item.question }}</span> -->
                        </div>
                        <span class="ml-3">
                            <ChevronDoubleRightIcon class="h-5 w-5 dark:text-warm-gray-400 text-warm-gray-500 ml-1"/>
                        </span>
                        </button>
                        
                    </li>
                    </ul>
                </div>
            </section>
            <div v-else>
                <!-- Main Content. Shown if there are items available -->
                <main v-if="items.length > 0" class="max-w-full mt-6 mx-auto px-1 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8 space-y-4 mb-28">
                    
                    <!-- Stats -->
                    <div id="stats" class="dark:border-true-gray-700 min-h-60 rounded-md mb-4 flex justify-between">
                        <div id="target-div" class="flex dark:text-warm-gray-500 text-warm-gray-700  text-md items-center">
                            <BookOpenIcon class="w-5 h-5 mr-1" />
                            <p class="sm:block ml-2 font-medium dark:text-warm-gray-500 text-warm-gray-700">
                                Item {{ currentItemIndex + 1 }} of {{ items.length }}
                            </p>
                        </div>
                        <div class="flex text-warm-gray-800 text-md items-center">
                            <div class="ml-2 font-medium text-warm-gray-800 flex items-center">
                            </div>
                        </div>
                    </div>
    
                    <!-- Item Content -->
                     <template v-for="(item, i) in items">
                         <div v-show="i == currentItemIndex" class="select-none text-left text-warm-gray-900 dark:text-warm-gray-300 rounded-md -space-y-px p-6 border-2 border-dashed border-warm-gray-300 dark:border-true-gray-700 whitespace-pre-line" > 
                            <div v-if="item.video">
                                <video :id="`video-${i}`"
                                    controls
                                    controlslist="nodownload"
                                    disablepictureinpicture
                                    preload="auto"
                                    class="study-video user-select-none"
                                    :src="item.video"
                                ></video>
                             </div>
                             <div class="quill-content" v-html="item.study"></div>
                         </div>
                     </template>

                
    
                    <!-- Action Buttons -->
                    <nav class="mt-6 flex flex-col sm:flex-row justify-between">
                        <button
                            @click="handlePrevItemClick()"
                            :disabled="currentItemIndex === 0"
                            class="order-last sm:order-first my-2 disabled:opacity-50 w-full sm:w-auto space-x-4 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5 border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 focus:outline-0 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            <ChevronDoubleLeftIcon class="h-5 w-5 dark:text-warm-gray-400 text-warm-gray-500 mr-1"/>
                            Previous
                        </button>
    
                        <button
                        v-on:click="isExplorerShown = true"
                        class="my-2 disabled:opacity-50 w-full sm:w-auto space-x-2 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5  border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 focus:outline-0 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Jump<span class="ml-1 hidden sm:block">To</span>
                            <UploadIcon class="h-5 w-5 dark:text-warm-gray-400 text-warm-gray-500 ml-1"/>
                        </button>
    
                        <button
                            v-if="currentItemIndex < items.length - 1"
                            @click="handleNextItemClick()"
                            class="order-first sm:order-last my-2 disabled:opacity-50 w-full sm:w-auto space-x-4 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5 border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 focus:outline-0 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Next
                            <ChevronDoubleRightIcon class="h-5 w-5 dark:text-warm-gray-400 text-warm-gray-500 ml-1"/>
                        </button>
                        <button
                            v-else
                            @click="completeStudy()"
                            class="order-first sm:order-last my-2 disabled:opacity-50 w-full sm:w-auto space-x-4 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5 border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-white bg-purple-700 hover:bg-purple-800 focus:outline-0 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Complete Study
                        </button>
                    </nav>
    
    
    
                </main>
                <div v-else class="text-warm-gray-600 w-full text-center mt-14 italic text-sm">
                    Items seems to be empty. No Study material for this subtopic.
                </div>
            </div>


        </div>
        <!-- Otherwise, show the loading animation for now -->
        <div v-else class="flex justify-center mt-8">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-true-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <div class="text-true-gray-700">Preparing study session...</div>
        </div>
    </div>

    <!-- Popup: On Complete -->
    <TransitionRoot appear :show="isOnCompleteOpen" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
              </TransitionChild>
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
                <div class="text-center inline-block w-full max-w-xl p-8 my-8 overflow-hidden align-middle transition-all transform bg-white dark:bg-true-gray-800 shadow-xl rounded-2xl">
                  <DialogTitle as="h3" class=" text-sm uppercase font-semibold leading-6 text-warm-gray-500 dark:text-warm-gray-200 text-center">
                    Study Completed
                  </DialogTitle>
                  <div class="mt-2">
                    <div class="text-3xl font-bold text-warm-gray-700 dark:text-warm-gray-200 mt-8">
                      <p>Good job!</p>
                    </div>
                    <p class="text-lg dark:text-warm-gray-200 text-warm-gray-700 mt-1 mb-8">
                        You've completed studying this subtopic. 💪😎
                    </p>
                    <div class="bg-gray-50 overflow-hidden rounded-lg text-sm text-warm-gray-500 text-left mt-8 max-w-lg">
                      <div class="px-4 py-5 sm:p-5 flex space-x-2 items-start">
                        <div>
                          <LightBulbIcon class="text-yellow-400 w-5 h-5" />
                        </div>
                        <p class="text-yellow-700">
                          <strong>Did You Know?</strong> When reading, it's easy to get distracted by notifications, emails, or other apps. Consider turning off notifications while you study to maintain focus.
                        </p>
                      </div>
                    </div>
                    <div class="mt-8 sm:mt-6">
                      <button @click="exitStudy()" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-0 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
    </TransitionRoot>

    <!-- Popup: are you sure? -->
    <TransitionRoot appear :show="isOnQuitOpen" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
              </TransitionChild>
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
                <div class="text-center inline-block w-full max-w-lg p-8 my-8 overflow-hidden align-middle transition-all transform bg-white dark:bg-true-gray-800 shadow-xl rounded-2xl">
                  
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" class="text-base font-semibold leading-6 dark:text-warm-gray-400 text-gray-900">Quit Study</DialogTitle>
                    <div class="mt-2">
                        <p class="text-sm dark:text-warm-gray-500 text-gray-500">Are you sure you want to exit this study? You are currently at item {{ currentItemIndex + 1 }} of {{ items.length }}.</p>
                    </div>
                    </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto disabled:opacity-30" @click="exitStudy()" :disabled="isWaitingToExit">Quit</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto disabled:opacity-30" @click="isOnQuitOpen = false" ref="cancelButtonRef" :disabled="isWaitingToExit">Cancel</button>
                </div>
                  
                  
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
    </TransitionRoot>
    
</template>

<style scooped>
html {
  scroll-behavior: smooth;
}

button{
    @apply px-2 py-1 bg-true-gray-600
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}


.study-video {
    @apply relative max-w-lg mx-auto rounded-xl w-full h-auto lg:h-96 lg:w-auto ;
}

</style>