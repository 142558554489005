import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import title_mixin from '@/mixins/title';
import firebase from "@/firebase/config";
import './assets/css/tailwind.css';
import { limitedDeviceCheck } from './firebase/usersController'
import { userService } from "@/utils/user"

import { setPresence, infoConnected, updateOnAway } from '@/firebase/presenceController'

let app;

document.title = "Skilltech App | " + location.host
    // firebase.functions().useEmulator("localhost", 5001);
firebase.auth().onAuthStateChanged(async user => {

    // check user presence
    if (user) {

        // save user credentials and info to user store
        let userInfo = await userService.fetchUserData(firebase.firestore(), firebase.auth().currentUser.uid);
        let idTokenResult = await user.getIdTokenResult();
        await store.dispatch('setUser', { credentials: idTokenResult, info: userInfo });
        
        setPresence('online', user);
        infoConnected(user);
        updateOnAway(user);
    }

    if (!app) app = createApp(App).use(store).use(router).mixin(title_mixin).mount('#app');

    limitedDeviceCheck(user);
});

/**
 * This is to fix DNS issue.. Some User can't access the app 
 * Date: Nov 5, 2024
 * Attempt: 1
 * 
 * Test something
 */