<script setup>
import { computed, onMounted } from "vue"
import ROUTER from "@/router"
import { ChevronDoubleLeftIcon } from "@heroicons/vue/solid"


// Numerical Ability
import ComputationTips from "../components/tips/ComputationTips.vue"
import NumberSequencingTips from "../components/tips/NumberSequencingTips.vue"
import ProblemSolvingTips from "../components/tips/ProblemSolvingTips.vue"
import DataInterpretationTips from "../components/tips/DataInterpretationTips.vue"
// Verbal Reasoning
import SpellingTips from "../components/tips/SpellingTips.vue"
import WordMeaningTips from "../components/tips/WordMeaningTips.vue"
import WordRelationshipTips from "../components/tips/WordRelationshipTips.vue"
import ComprehensionTips from "../components/tips/ComprehensionTips.vue"
import CriticalThinkingTips from "../components/tips/CriticalThinkingTips.vue"
// General Knowledge
import AviationBasicsTips from "../components/tips/AviationBasicsTips.vue"
import SimpleMathematicsTips from "../components/tips/SimpleMathematicsTips.vue"
import TheDirtyDozenTips from "../components/tips/TheDirtyDozenTips.vue"
import AirframeSystemTips from "../components/tips/AirframeSystemTips.vue"
import AvionicsSystemTips from "../components/tips/AvionicsSystemTips.vue"
import AerodynamicsTips from "../components/tips/AerodynamicsTips.vue"
import MaterialsAndAssembliesTips from "../components/tips/MaterialsAndAssembliesTips.vue"
import PowerplantGasTurbineTips from "../components/tips/PowerplantGasTurbineTips.vue"
import TechnicalDrawingTips from "../components/tips/TechnicalDrawingTips.vue"
import BasicAmtToolTips from "../components/tips/BasicAmtToolTips.vue"
import PcarAndAircraftManualsTips from "../components/tips/PcarAndAircraftManualsTips.vue"
import ATASpec100Tips from "../components/tips/ATASpec100Tips.vue"
import AviationSciencesTips from "../components/tips/AviationSciencesTips.vue"
// Abstract Reasoning
import SingleSeriesTips from "../components/tips/SingleSeriesTips.vue"
import PatternRelationshipTips from "../components/tips/PatternRelationshipTips.vue"
import OldFiguresTips from "../components/tips/OldFiguresTips.vue"
import ExtendedSeriesTips from "../components/tips/ExtendedSeriesTips.vue"
import GridCompletionTips from "../components/tips/GridCompletionTips.vue"
import SymbolSequenceTips from "../components/tips/SymbolSequenceTips.vue"



const subjectId = computed( () => ROUTER.currentRoute.value.query.subjectId )
const subjectName = computed( () => ROUTER.currentRoute.value.query.subjectName )
const subtopicId = computed( () => ROUTER.currentRoute.value.query.subtopicId )
const subtopicName = computed( () => ROUTER.currentRoute.value.query.subtopicName )

const goBack = () => {
    ROUTER.push({ path: `/mtp-entrance/subtopics`, query: { subjectId: subjectId.value, subjectName: subjectName.value } })
}

onMounted(()=>{
  console.log('Load to the top you!!')
  scrollBackToTop('target-id')
})

const scrollBackToTop = (elementID) => {
    const element = document.getElementById(elementID);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}

</script>
<template>
  <div class="max-w-3xl mx-auto">

    <div id="target-id" class="space-y-6 mb-20 px-4 py-6">
      <nav class="flex justify-between items-center" aria-label="Breadcrumb">
          <div class="flex items-center">
            <button @click="goBack()" class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center" aria-current="page">
              <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true"/>
            </button>
          </div>
          <div class="text-sm font-medium text-warm-gray-500">
              <span class="hidden md:block">{{ subtopicName }}</span>
              <span class="block md:hidden">{{ subtopicName }}</span>
          </div>
          <a href="#">
              <!-- <QuestionMarkCircleIcon class="flex-shrink-0 h-6 w-6 text-warm-gray-500 hover:text-warm-gray-600" aria-hidden="true"/> -->
          </a>
      </nav>
      <div>
        <h1 class="ml-1 text-lg font-medium dark:text-true-gray-200 text-true-gray-800">
          Tips For Answering Subtopic: {{ subtopicName }}
        </h1>

        <!-- Numerical Ability -->
        <ComputationTips v-if="subtopicId == 'mtp2-computation'" />
        <NumberSequencingTips v-if="subtopicId == 'mtp2-number-sequencing'" />
        <ProblemSolvingTips v-if="subtopicId == 'mtp2-problem-solving'" />
        <DataInterpretationTips v-if="subtopicId == 'mtp2-data-interpretation'" />
        <!-- Verbal Reasoning -->
        <SpellingTips v-if="subtopicId == 'mtp2-spelling'" />
        <WordMeaningTips v-if="subtopicId == 'mtp2-word-meaning'" />
        <WordRelationshipTips v-if="subtopicId == 'mtp2-word-relationship'" />
        <ComprehensionTips v-if="subtopicId == 'mtp2-comprehension'" />
        <CriticalThinkingTips v-if="subtopicId == 'mtp2-critical-thinking'" />
        <!-- General Knowledge -->
        <AviationBasicsTips v-if="subtopicId == 'mtp2-aviation-basics-optimized'" /> 
        <SimpleMathematicsTips v-if="subtopicId == 'mtp2-simple-mathematics'" /> 
        <TheDirtyDozenTips v-if="subtopicId == 'mtp2-the-dirty-dozen'" /> 
        <AirframeSystemTips v-if="subtopicId == 'mtp2-airframe-system'" /> 
        <AvionicsSystemTips v-if="subtopicId == 'mtp2-avionics-system'" /> 
        <AerodynamicsTips v-if="subtopicId == 'mtp2-aerodynamics'" /> 
        <MaterialsAndAssembliesTips v-if="subtopicId == 'mtp2-materials--assemblies'" /> 
        <PowerplantGasTurbineTips v-if="subtopicId == 'mtp2-powerplant-gas-turbine'" /> 
        <TechnicalDrawingTips v-if="subtopicId == 'mtp2-technical-drawing'" /> 
        <BasicAmtToolTips v-if="subtopicId == 'mtp2-basic-amt-tools'" /> 
        <PcarAndAircraftManualsTips v-if="subtopicId == 'mtp2-pcar-and-aircraft-manuals'" /> 
        <ATASpec100Tips v-if="subtopicId == 'mtp2-ata-spec-100'" /> 
        <AviationSciencesTips v-if="subtopicId == 'mtp2-aviation-sciences'"/>
        <!-- Abstract Reasoning -->
        <SingleSeriesTips v-if="subtopicId == 'mtp2-single-series'" /> 
        <PatternRelationshipTips v-if="subtopicId == 'mtp2-pattern-relationship'" /> 
        <OldFiguresTips v-if="subtopicId == 'mtp2-odd-figures'" /> 
        <ExtendedSeriesTips v-if="subtopicId == 'mtp2-extended-series'" /> 
        <GridCompletionTips v-if="subtopicId == 'mtp2-grid-completion'" /> 
        <SymbolSequenceTips v-if="subtopicId == 'mtp2-symbol-sequence'" /> 


        
        <button @click="goBack()" class="btn">
          All good. Let's practice!
        </button>
      </div>
    </div>
  </div>
</template>
<style>
.btn{
  @apply mt-4 rounded-md bg-yellow-400 px-3 py-2 text-sm font-semibold text-warm-gray-900 shadow-sm hover:bg-yellow-500 w-full flex items-center justify-center
}
</style>