<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            "Odd one out" questions require you to identify the figure that doesn’t follow the same pattern or rules as
            the others. Here’s how to approach them effectively:
        </p>

        <h3>💡 1. Count the Elements in Each Figure</h3>
        <p class="p-4">
            <img src="../../assets/images/old-figures/image-1.png" alt="" />
        </p>
        <section>
            <p>
                <strong>What to Do:</strong> Start by checking if all figures contain the same number of elements (e.g.,
                squares, arrows, or shaded areas).
            </p>
            <p>
                <strong>Why It Helps:</strong> If one figure has a different number of elements, it’s likely the odd one
                out.
            </p>
            <p>
                <strong>Tip:</strong> In the given question, all figures have the same number of elements, so proceed to
                the next step.
            </p>
        </section>

        <h3>💡 2. Analyze Element Configurations</h3>
        <p class="p-4">
            <img src="../../assets/images/old-figures/image-2.png" alt="" />
        </p>
        <section>
            <p>
                <strong>What to Do:</strong> Look for consistent positioning, orientations, or relationships among the
                elements in each figure.
            </p>
            <p>
                <strong>How to Apply:</strong> Check if the placement or alignment of certain elements deviates from the
                rest.
            </p>
            <p>
                <strong>Tip:</strong> In the example, all figures except (A) have an arrow pointing directly at a small
                shaded square. Figure (A) breaks this configuration.
            </p>
        </section>

        <h3>💡 3. Check for Symmetry or Rotational Differences</h3>
        <p class="p-4">
            <img src="../../assets/images/old-figures/image-3.png" alt="" />
        </p>
        <section>
            <p>
                <strong>What to Do:</strong> Observe if the figures share a symmetry or rotational pattern and if one
                figure deviates from it.
            </p>
            <p>
                <strong>Why It Helps:</strong> Patterns of symmetry or rotation are common in these types of questions.
            </p>
            <p>
                <strong>Tip:</strong> If symmetry is consistent across all but one figure, you’ve likely found the odd one out. Only Figure (A) has a large yellow square at its upper-left and lower-right corner. The rest follows the same pattern/symmetry in this regard (black square at upper-left and lower-right corner) –<b> making Figure (A) the odd one out</b>.
            </p>
        </section>

    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ul {
    @apply space-y-1.5;
}

p {
    @apply text-sm;
}
img {
    @apply mx-auto my-2
}
</style>