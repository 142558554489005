<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            This section will cover effective, concise notes to help you quickly familiarize yourself with important ATA
            Chapters and the Job Card Number System Breakdown.
        </p>

        <h3>💡 1. ATA Chapters Overview</h3>
        <section>
            <p>
                The ATA chapter numbering system is used to standardize the presentation of technical data across all
                aircraft systems. Here’s a list of some of the important chapters you need to know:
            </p>
            <ul class="list-disc pl-4">
                <li><strong>ATA 05:</strong> Time Limits/Maintenance Checks</li>
                <li><strong>ATA 06:</strong> Dimensions and Areas</li>
                <li><strong>ATA 07:</strong> Lifting and Shoring</li>
                <li><strong>ATA 08:</strong> Leveling and Weighing</li>
                <li><strong>ATA 09:</strong> Towing and Taxiing</li>
                <li><strong>ATA 10:</strong> Parking, Mooring, Storage, and Return to Service</li>
                <li><strong>ATA 11:</strong> Placards and Markings</li>
                <li><strong>ATA 12:</strong> Servicing</li>
                <li><strong>ATA 20:</strong> Standard Practices - Airframe</li>
                <li><strong>ATA 21:</strong> Air Conditioning</li>
                <li><strong>ATA 22:</strong> Auto Flight</li>
                <li><strong>ATA 23:</strong> Communications</li>
                <li><strong>ATA 24:</strong> Electrical Power</li>
                <li><strong>ATA 25:</strong> Equipment/Furnishings</li>
                <li><strong>ATA 26:</strong> Fire Protection</li>
                <li><strong>ATA 27:</strong> Flight Controls</li>
                <li><strong>ATA 28:</strong> Fuel</li>
                <li><strong>ATA 29:</strong> Hydraulics</li>
                <li><strong>ATA 30:</strong> Ice and Rain Protection</li>
                <li><strong>ATA 31:</strong> Indicating/Recording Systems</li>
                <li><strong>ATA 32:</strong> Landing Gear</li>
                <li><strong>ATA 33:</strong> Lights</li>
                <li><strong>ATA 34:</strong> Navigation</li>
                <li><strong>ATA 35:</strong> Oxygen</li>
                <li><strong>ATA 36:</strong> Pneumatics</li>
                <li><strong>ATA 38:</strong> Water/Waste</li>
                <li><strong>ATA 49:</strong> Airborne Auxiliary Power</li>
                <li><strong>ATA 51:</strong> Standard Practices - Structures</li>
                <li><strong>ATA 52:</strong> Doors</li>
                <li><strong>ATA 53:</strong> Fuselage</li>
                <li><strong>ATA 54:</strong> Nacelles/Pylons</li>
                <li><strong>ATA 55:</strong> Stabilizers</li>
                <li><strong>ATA 56:</strong> Windows</li>
                <li><strong>ATA 57:</strong> Wings</li>
                <li><strong>ATA 70:</strong> Standard Practices - Engines</li>
                <li><strong>ATA 71:</strong> Power Plant</li>
                <li><strong>ATA 72:</strong> Engine</li>
                <li><strong>ATA 73:</strong> Engine Fuel and Control</li>
                <li><strong>ATA 74:</strong> Ignition</li>
                <li><strong>ATA 75:</strong> Air</li>
                <li><strong>ATA 76:</strong> Engine Controls</li>
                <li><strong>ATA 77:</strong> Engine Indicating</li>
                <li><strong>ATA 78:</strong> Exhaust</li>
                <li><strong>ATA 79:</strong> Oil</li>
                <li><strong>ATA 80:</strong> Starting</li>
                <li><strong>ATA 91:</strong> Charts</li>
            </ul>
        </section>

        <h3>💡 2. Job Card Number System Breakdown</h3>
        <section>
            <p>
                Each job card in aircraft maintenance is identified by a structured number format, like
                <strong>55-123-456-01-789</strong>. Here’s what each section of the number represents:
            </p>
            <ul class="list-disc pl-4">
                <li><strong>55:</strong> ATA Chapter – Identifies the system (e.g., ATA 55 refers to Stabilizers).</li>
                <li><strong>123:</strong> Section – Breaks the system down into more specific areas or components.</li>
                <li><strong>456:</strong> Subject – Refers to the task or job being performed.</li>
                <li><strong>01:</strong> Task Code – This defines the individual job step or sub-task.</li>
                <li><strong>789:</strong> Operation or Step – Specifies the operation or step number within the task.
                </li>
            </ul>
        </section>

        <p class="mt-6">
            In mastering ATA 100 specifications and job cards, consistency is key. By familiarizing yourself with the
            ATA chapters and understanding the job card numbering system, you’ll enhance your efficiency and accuracy
            in aircraft maintenance tasks. Keep these notes handy, and you’ll navigate technical documentation like a
            pro!
        </p>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ul {
    @apply space-y-1.5;
}

p {
    @apply text-sm;
}
</style>
