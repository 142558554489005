<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            Abstract reasoning questions test your ability to identify patterns, sequences, and relationships between
            shapes or figures. Here’s a step-by-step guide to tackling these questions efficiently:
        </p>

        <h3>💡 1. Identify the Most Obvious Pattern</h3>

        <p class="p-4">
            <img src="../../assets/images/single-series/image-1.png" alt="" />
        </p>

        <section>
            <p>
                <strong>What to Do:</strong> Start by observing the most noticeable feature of the series (e.g., shape
                movement, position, or color change).
            </p>
            <p>
                <strong>Why It Helps:</strong> Spotting the obvious pattern allows you to focus your analysis on the
                most relevant aspect of the sequence, saving time.
            </p>
            <p>
                <strong>Example:</strong> In the provided example, the black diamond (◆) moves to each corner of the
                square in a counterclockwise direction.
            </p>
        </section>

        <h3>💡 2. Immediately Eliminate Incorrect Choices</h3>
        <p class="p-4">
            <img src="../../assets/images/single-series/image-2.png" alt="" />
        </p>
        <section>
            <p>
                <strong>What to Do:</strong> Rule out options that clearly don’t follow the pattern.
            </p>
            <p>
                <strong>How to Apply:</strong> If a choice doesn’t match the expected position, size, or rotation in the
                sequence, eliminate it immediately.
            </p>
            <p>
                <strong>Tip:</strong> In the example, choice (B) is eliminated because the diamond is not in the
                expected upper-left corner.
            </p>
        </section>

        <h3>💡 3. Look for Secondary Patterns</h3>
        <p class="p-4">
            <img src="../../assets/images/single-series/image-3.png" alt="" />
        </p>
        <section>
            <p>
                <strong>What to Do:</strong> If the primary pattern doesn’t lead to a clear answer, examine other
                attributes like rotation, shading, or shape changes.
            </p>
            <p>
                <strong>Why It Helps:</strong> Sometimes, patterns involve multiple factors. Identifying secondary
                patterns ensures you don’t miss the right answer.
            </p>
            <p>
                <strong>Tip:</strong> In the previous example, the primary pattern provided by the black diamond (◆)
                doesn’t lead to a clear answer. However, the secondary pattern, which is the straight line (–), moves 5
                times in a counterclockwise direction, with each move stopping in every half-a-square and corner. This
                leads to the correct answer, which is choice (C).
            </p>
        </section>

        <h3>💡 4. Double-Check Your Answer</h3>
        <section>
            <p>
                <strong>What to Do:</strong> Once you select an answer, quickly review the sequence to confirm it aligns
                with the established pattern.
            </p>
            <p>
                <strong>Why It Helps:</strong> Double-checking prevents errors due to overconfidence or oversight.
            </p>
        </section>

        <h3>💡 5. Practice Efficient Time Management</h3>
        <section>
            <p>
                <strong>What to Do:</strong> Allocate a set amount of time for each question. If stuck, move on and come
                back later.
            </p>
            <p>
                <strong>Why It Helps:</strong> Avoid spending too much time on a single question, ensuring you answer as
                many as possible.
            </p>
        </section>

        <p class="mt-6">
            By following these steps, you’ll develop the skills to approach abstract reasoning questions with
            confidence and efficiency!
        </p>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

p {
    @apply text-sm;
}

ul {
    @apply space-y-1.5;
}
img {
    @apply mx-auto my-2
}
</style>
