<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            Data interpretation exams test your ability to analyze information presented in visual formats like bar
            graphs, pie charts, and line graphs. Here's a guide to help you ace these questions, using examples from our
            generated materials.
        </p>

        <h3>💡 1. Understand the Visual Representation</h3>
        <section>
            <p>Before diving into the questions, take a moment to understand the graph or chart:</p>
            <ul class="list-disc pl-4">
                <li>
                    <strong>Bar Graphs:</strong> Show comparisons over categories (e.g., "Skilltech Subscription Renewal"
                    bar chart shows renewals by quarter).
                </li>
                <li>
                    <strong>Pie Charts:</strong> Represent proportions (e.g., "Skilltech User Location" pie chart shows
                    the percentage of users in Luzon, Visayas, and Mindanao).
                </li>
                <li>
                    <strong>Line Graphs:</strong> Depict trends over time (e.g., "Skilltech Approval Rating" shows
                    monthly ratings).
                </li>
            </ul>
        </section>

        <h3>💡 2. Pay Attention to Key Details</h3>
        <section>
            <p>Always note the following:</p>
            <ul class="list-disc pl-4">
                <li><strong>Titles and labels:</strong> e.g., "Skilltech A-Box Deliveries".</li>
                <li><strong>Units of measurement:</strong> e.g., "Number of Boxes".</li>
                <li><strong>Scales on axes:</strong> For bar and line graphs (e.g., y-axis in "Skilltech Bug Reports"
                    ranges from 0–12).
                </li>
            </ul>
        </section>

        <h3>💡 3. Step-by-Step Process for Answering Questions</h3>
        <section>
            <h4>📎 Bar Graph Example</h4>
            <div class=" py-4 w-full flex justify-center">
                <img src="../../assets/images/data-interpretation/image-1.png" alt="">
            </div>
            <p><strong>Question:</strong> What is the total number of Skilltech Subscription Renewals for the entire year?</p>
            <ol class="list-decimal pl-4">
                <li>
                    <strong>Read the data:</strong> 
                    <p class="mb-1">Look at the bar heights or values:</p>
                    <ul class="list-disc pl-6">
                        <li>Jan-Mar: 300</li>
                        <li>Apr-Jun: 700</li>
                        <li>Jul-Sep: 500</li>
                        <li>Oct-Dec: 900</li>
                    </ul>
                </li>
                <li><strong>Add the values:</strong>
                    <p class="text-lg text-center">
                        300 + 700 + 500 + 900 = <b>2,400 renewals</b>
                    </p>
                </li>
            </ol>
            <hr />
            <h4>📎 Pie Chart Example</h4>
            <div class=" py-4 w-full flex justify-center">
                <img src="../../assets/images/data-interpretation/image-2.png" alt="">
            </div>
            <p><strong>Question:</strong> How many Skilltech users are in Visayas if the total is 15,000?</p>
            <ol class="list-decimal pl-4">
                <li><strong>Identify the proportion:</strong> 
                    <p class="mb-1">From the "Skilltech User Location" pie chart:</p>
                    <p class="text-lg text-center">Visayas = 50%</p>
                </li>
                <li><strong>Calculate the value:</strong>
                    <p class="text-lg text-center">
                        15,000 × 0.50 = <b>7,500 users in Visayas</b>
                    </p>
                </li>
            </ol>
            <p>
                <strong>Tip:</strong> Always double-check the percentage distribution to ensure the proportions add up to
                100%.
            </p>
            <hr />
            <h4>📎 Line Graph Example</h4>
            <div class=" py-4 w-full flex justify-center">
                <img src="../../assets/images/data-interpretation/image-3.png" alt="">
            </div>
            <p><strong>Question:</strong> Between which months did the largest decrease in Skilltech Approval Rating occur?</p>
            <ol class="list-decimal pl-4">
                <li><strong>Look for dips in the trend:</strong>
                    <p class="mb-1">From the "Skilltech Approval Rating" line graph:</p>
                    <ul class="list-disc pl-6">
                        <li>Jan: 6.5 → Feb: 7.5</li>
                        <li>Feb: 7.5 → Mar: 7.0</li>
                        <li>Mar: 7.0 → Apr: 8.0</li>
                    </ul>
                </li>
                <li><strong>Identify the data:</strong> The largest decrease is from <b>Feb to Mar</b>, a drop of <b>0.5</b> points.
                </li>
            </ol>
            <p>
                <strong>Tip:</strong> Focus on connecting lines to quickly identify upward or downward trends.
            </p>
        </section>

        <h3>💡 4. Manage Your Time</h3>
        <section>
            <ul class="list-disc pl-4">
                <li>Start with straightforward calculations (e.g., totals or averages).</li>
                <li>Leave more complex analysis (e.g., identifying trends) for last if you're short on time.</li>
            </ul>
        </section>

        <h3>💡 5. Practice Common Question Types</h3>
        <section>
            <p>Based on the materials above, here are some common types of questions to practice:</p>
            <ul class="list-disc pl-4">
                <li><strong>Totals:</strong> Add values across categories (e.g., total boxes shipped in "Skilltech A-Box
                    Deliveries").
                </li>
                <li><strong>Averages:</strong> Divide the total by the number of data points (e.g., average renewals in
                    "Skilltech Subscription Renewal").
                </li>
                <li><strong>Changes:</strong> Look for increases or decreases (e.g., dips in "Skilltech Bug Reports").
                </li>
                <li><strong>Proportions:</strong> Calculate percentages (e.g., percentage of users in Visayas from the
                    pie chart).
                </li>
            </ul>
        </section>

        <h3>💡 6. Avoid Common Mistakes</h3>
        <section>
            <ul class="list-disc pl-4">
                <li><strong>Misreading scales:</strong> Ensure you know the unit of measurement (e.g., "Skilltech Bug
                    Reports" y-axis).
                </li>
                <li><strong>Skipping key details:</strong> Titles often include critical context (e.g., "Skilltech
                    Approval Rating (1-10)").
                </li>
                <li><strong>Misinterpreting proportions:</strong> Double-check that percentages are based on the total.
                </li>
            </ul>
        </section>

        <p class=" mt-6">
            Data interpretation is about attention to detail and logical analysis. With consistent practice using the
            examples above, you’ll develop the skills to handle any data interpretation question confidently!
        </p>
    </div>
</template>

<style scoped>
h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 font-bold flex items-center space-x-2;
}

h4 {
    @apply mt-4 mb-2 text-sm font-bold text-warm-gray-800 dark:text-warm-gray-300 flex items-center space-x-2;
}

section {
    @apply ml-6 space-y-4;
}

ol {
    @apply space-y-2;
}

ul {
    @apply space-y-1.5;
}

p {
    @apply text-sm;
}

hr {
    @apply h-px my-6 bg-warm-gray-200 border-0 dark:bg-warm-gray-700
}
</style>
